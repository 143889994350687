import styled from 'styled-components'

import { grayscale, white } from '../../styles/colors'

export const Container = styled.div`
  color: ${white};
  font-family: Sora;
  font-size: 18px;
  margin: 0px auto 0px auto;
  width: 90%;
`

export const AuthorContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 60px 0 50px 0;
`

export const About = styled.p`
  color: ${grayscale[500]};
  line-height: 26px;
`
export const AuthorName = styled.p`
  color: ${grayscale[400]};
  line-height: 23px;
`
export const ProfilePicture = styled.img`
  align-self: flex-start;
  border-radius: 50%;
  height: 94px;
  width: 89px;
  min-height: 89px;
  min-width: 89px;
  margin: 0 40px;
`

export const Section = styled.div`
  border: 1px dashed ${grayscale[200]};
  margin: 0px auto 50px auto;
  width: 90%;
`

export const News = styled.div`
  display: flex;
  flex-direction: column;
`

export const NewsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin-bottom: 34px;
`

export const NewsImage = styled.img`
  radius: 12px;
  height: 200px;
  margin: 0 28px 0 40px;
  width: 264px;
`

export const NewsInfo = styled.div`
  color: ${grayscale[400]};
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 4px 0 24px 0;
`

export const NewsStatistic = styled.p`
  margin: 0 20px 0 10px;
`

export const NewsTitle = styled.p`
  color: ${grayscale[500]};
  line-height: 26px;
  width: 90%;
`
