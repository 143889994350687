import React from 'react'

import { useAuth } from '../../hooks'
import List from '../../components/List'
import { NEWS_CONFIG_URL, NEWS_URL } from '../../config/urls'
import { BASE, NEWS } from '../../config/routes'
import { DEFAULT_ALLOWED_ACTIONS } from '../../components/List/constants'

export default function NewsList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions.news.news.publish,
    }
  }
  return (
    <List
      pageTitle="Notícias"
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Notícias' }]}
      configUrl={NEWS_CONFIG_URL}
      baseUrl={NEWS_URL}
      baseRoute={NEWS}
      searchField="title"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        groupBy: ['uuid'],
        _limit: 5,
      }}
      permissions={getPermissions()}
      allowedActions={[...DEFAULT_ALLOWED_ACTIONS, 'UNPUBLISH']}
    />
  )
}
