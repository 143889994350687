import React from 'react'

import { useHistory } from 'react-router-dom'

import { Button } from '@inter/inter-ui'

import { request } from '../../helpers'
import Page from '../Page'
import ConfirmModal from '../ConfirmModal'
import * as S from './styles'

export default function Moderation({
  pageTitle,
  breadcrumbs,
  mainUrl,
  mainRoute,
  renderVisualization,
}) {
  const [itemToModerate, setItemToModerate] = React.useState()
  const [modalConfig, setModalConfig] = React.useState({
    isOpen: false,
    message: '',
    inputOptions: null,
  })
  const history = useHistory()

  React.useEffect(() => {
    setItemToModerate(history.location.state)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function onConfirm(path, id, body) {
    setModalConfig({ ...modalConfig, isOpen: false })

    request({
      method: 'POST',
      url: `${mainUrl}/${path}/${id}`,
      body,
    }).then(() => history.push(mainRoute))
  }

  return itemToModerate ? (
    <>
      <Page title={pageTitle} breadcrumbs={breadcrumbs}>
        <S.ModerationContainer>
          {renderVisualization(itemToModerate)}
          <S.ModerationActionButtons>
            <Button
              variant="secondary"
              onClick={() =>
                setModalConfig({
                  isOpen: true,
                  message: 'Confirmar reprovação?',
                  inputOptions: {
                    placeholder: 'Informe o motivo da reprovação',
                  },
                  onConfirm: (reprovalReason) =>
                    onConfirm('reprove', itemToModerate.id, { reprovalReason }),
                })
              }
            >
              Reprovar
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                setModalConfig({
                  isOpen: true,
                  message: 'Confirmar aprovação?',
                  inputOptions: null,
                  onConfirm: () => onConfirm('publish', itemToModerate.id),
                })
              }
            >
              Aprovar
            </Button>
          </S.ModerationActionButtons>
        </S.ModerationContainer>
      </Page>
      <ConfirmModal
        isOpen={modalConfig.isOpen}
        message={modalConfig.message}
        onConfirm={modalConfig.onConfirm}
        inputOptions={modalConfig.inputOptions}
        closeModal={() => setModalConfig({ ...modalConfig, isOpen: false })}
      />
    </>
  ) : null
}
