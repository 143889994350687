import styled from 'styled-components'

import { orange } from '../../../styles/colors'

export const VisualizationContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

export const TitleContainer = styled.div`
  align-items: center;
  display: inline-flex;
`

export const Icon = styled.div`
  position: relative;
  top: -8px;
`

export const FallbackIcon = styled.div`
  color: ${orange[500]};
  margin-right: 10px;
`

export const Title = styled.p`
  font-family: Sora;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`

export const Subtitle = styled.p`
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
`

export const ImagesContainer = styled.div`
  display: inline-flex;
  margin: 32px 0;
  width: 100%;
`

export const Image = styled.figure`
  margin: 0 1.5rem;

  img {
    border-radius: 12px;
    display: block;
    height: 172px;
    width: 264px;
  }
`

export const ImageTitle = styled.p`
  font-family: Sora;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 12px 0px;
`
