import React from 'react'

import * as S from './styles'

export default function QuoteVisualization({ quotation }) {
  const { name, image, description, recommendation, upside, variation, price } = quotation

  return (
    <S.VisualizationContainer>
      <S.QuotationContainer>
        <S.CardIdentify>
          <S.QuotationImageContainer>
            <img className="w-100" src={image.url} alt={image.alt} />
          </S.QuotationImageContainer>
          <div className="pr-2">
            <h3 className="f-16 fw-600">{name}</h3>
            <p>{description}</p>
          </div>
        </S.CardIdentify>
        <S.CardRecommendation>
          <p>{recommendation}</p>
        </S.CardRecommendation>
        <S.CardUpside>
          <p>{upside}</p>
        </S.CardUpside>
        <S.CardVariation>
          <p>{variation}</p>
        </S.CardVariation>
        <S.CardPrice>
          <p> R$ {price.toFixed(2)}</p>
        </S.CardPrice>
        <S.ButtonsContainer>
          <button type="button">Análise</button>
          <button type="button">Investir</button>
        </S.ButtonsContainer>
      </S.QuotationContainer>
    </S.VisualizationContainer>
  )
}
