import styled, { css } from 'styled-components'

import { gray, grayscale } from '../../../styles/colors'

export const OptionButton = styled.div`
  background: transparent;
  border: none;
  border-radius: 4px;
  color: ${grayscale[400]};
  font-family: Inter;
  font-size: 16px;
  height: 30px;
  margin-right: 4px;
  text-align: center;
  transition: all 0.3 ease 0s;
  user-select: none;
  width: 30px;

  &:hover {
    background: ${grayscale[100]};
  }

  ${({ active }) =>
    active &&
    css`
      background: ${gray[100]};

      &:hover {
        background: ${gray[100]};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      &:hover {
        background: none;
      }
    `}
`
