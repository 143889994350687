import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { SUBCATEGORY_URL } from '../../config/urls'
import { BASE, SUBCATEGORY } from '../../config/routes'

export default function SubcategoryModeration() {
  return (
    <Moderation
      pageTitle="Subcategorias"
      mainUrl={SUBCATEGORY_URL}
      mainRoute={SUBCATEGORY}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Subcategorias', route: SUBCATEGORY },
        { title: 'Moderação' },
      ]}
      renderVisualization={(subcategory) => <Visualization subcategory={subcategory} />}
    />
  )
}
