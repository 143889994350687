import axios from 'axios'

function request({ method, url, headers = {}, body }, withResponseHeaders) {
  const auth = sessionStorage.getItem('auth')
  const jwt = auth && JSON.parse(auth).jwt

  return axios({
    method,
    url,
    headers: {
      ...headers,
      ...(jwt && { Authorization: `Bearer ${jwt}` }),
    },
    data: body,
  }).then(({ data, headers: responseHeaders }) =>
    withResponseHeaders ? { data, headers: responseHeaders } : data,
  )
}

function capitalize(str) {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
}

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
    .replace(/[èÈéÉêÊëË]+/g, 'e')
    .replace(/[ìÌíÍîÎïÏ]+/g, 'i')
    .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
    .replace(/[ùÙúÚûÛüÜ]+/g, 'u')
    .replace(/[ýÝÿŸ]+/g, 'y')
    .replace(/[ñÑ]+/g, 'n')
    .replace(/[çÇ]+/g, 'c')
    .replace(/[ß]+/g, 'ss')
    .replace(/[Ææ]+/g, 'ae')
    .replace(/[Øøœ]+/g, 'oe')
    .replace(/[%]+/g, 'pct')
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

function isValidDate(value) {
  const regex = new RegExp(
    '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$',
  )

  return typeof value === 'string' && regex.test(value)
}

export { request, capitalize, slugify, isValidDate }
