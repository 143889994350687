import React from 'react'

import UploadIcon from 'inter-frontend-svgs/lib/layout/upload'

import 'react-image-crop/dist/ReactCrop.css'
import { Button } from '@inter/inter-ui'

import { request } from '../../helpers'
import { UPLOAD_URL } from '../../config/urls'
import * as S from './styles'

export default function Uploader({ folder, onUploadFile, type }) {
  const [uploading, setUploading] = React.useState(false)
  const [error, setError] = React.useState(null)
  function onChooseFile(file) {
    !uploading && setUploading(true)

    const formData = new window.FormData()

    formData.set('file', file)
    formData.set('folder', folder)

    request({
      method: 'POST',
      url: UPLOAD_URL,
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        onUploadFile(response)
        setUploading(false)
      })
      .catch((err) => {
        setUploading(false)
        setError(
          err.response.data.statusCode === 400
            ? err.response.data.message
            : 'Um erro inesperado ocorreu! Por favor tente novamente.',
        )
      })
  }

  return (
    <>
      <S.UploaderContainer>
        <UploadIcon height={54} color="gray" />
        <Button fixedWidth variant="secondary">
          <S.UploaderInput
            id="file"
            name="file"
            type="file"
            accept={type}
            multiple={false}
            onChange={(e) => {
              e.target.files && onChooseFile(e.target.files[0])
              return false
            }}
          />
          Upload
        </Button>
        <p>Selecione um arquivo</p>
        <S.UploadErrorMessage> {error} </S.UploadErrorMessage>
      </S.UploaderContainer>
    </>
  )
}
