import styled from 'styled-components'

import { grayscale, white } from '../../styles/colors'

export const Container = styled.div`
  color: ${white};
  display: flex;
  flex-direction: column;
  font-family: Sora;
  min-height: 50px;
  padding: 0 3rem;
  width: 100%;
`

export const Badge = styled.div`
  background: #d32f2f;
  border-radius: 4px;
  font-weight: 700;
  margin-right: 1rem;
  padding: 2px 4px;
  text-align: center;
  width: 60px;
`

export const InfoContainer = styled.div`
  color: ${grayscale[500]};
  margin-top: 1rem;
  width: 100%;
`

export const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
`

export const Description = styled.p`
  font-size: 18px;
  line-height: 20px;
  margin-top: 1rem;
`

export const VideoContainer = styled.div`
  width: 100%;
`

export const Video = styled.figure`
  padding: 1rem 0;

  iframe {
    min-height: 433px;
    width: 100%;
  }
`
