import React, { Suspense } from 'react'

import * as S from './styles'
import { slugify } from '../../../helpers'
import { orange } from '../../../styles/colors'

export default function TrailVisualization({ trail }) {
  const { icon, title, subtitle, news } = trail

  function loadIcon(name) {
    return React.lazy(() =>
      import(`inter-frontend-svgs/lib/orangeds/MD/${name}.js`).catch(() => null),
    )
  }

  function renderIcon(val) {
    const Icon = loadIcon(val)
    return (
      <Suspense fallback={<S.FallbackIcon>{val}</S.FallbackIcon>}>
        <Icon color={orange[500]} style={{ marginRight: '10px' }} />
      </Suspense>
    )
  }

  function generateNews(images) {
    return images.map((image, key) => {
      const imageKey = `${key}-${slugify(title)}`
      return (
        <S.Image key={imageKey}>
          <a href={image.content.link} target="_blank" rel="noreferrer">
            <img src={image.content.url} alt={image.content.alt} />
          </a>
          <S.ImageTitle>{image.content.title}</S.ImageTitle>
        </S.Image>
      )
    })
  }

  return (
    <S.VisualizationContainer>
      <S.TitleContainer>
        <S.Icon>{renderIcon(icon)}</S.Icon>
        <S.Title>{title}</S.Title>
      </S.TitleContainer>
      <S.Subtitle>{subtitle}</S.Subtitle>
      <S.ImagesContainer>{generateNews(news)}</S.ImagesContainer>
    </S.VisualizationContainer>
  )
}
