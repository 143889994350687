import React from 'react'

import { Input } from '@inter/inter-ui'

import { FormInputLabel } from '../../../../components/Form/styles'
import * as S from '../styles'

export default function CitationEditor({ content, changeContent }) {
  const { author, text } = content

  return (
    <S.BlockContainer>
      <S.BlockTitle>Citação</S.BlockTitle>
      <S.InputContainer>
        <FormInputLabel>Autor:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe o autor"
          value={author}
          onChange={(e) => {
            changeContent({ ...content, author: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.InputContainer>
        <FormInputLabel>Texto:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe o texto da citação"
          value={text}
          onChange={(e) => {
            changeContent({ ...content, text: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
    </S.BlockContainer>
  )
}
