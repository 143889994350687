import styled from 'styled-components'

import { grayscale, gray, orange, white } from '../../styles/colors'

export const CardOption = styled.div`
  background-color: ${white};
  border: solid 1px ${grayscale[300]};
  border-radius: 8px;
  cursor: pointer;
  height: 180px;
  margin: 0 auto;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 7px;
  text-align: center;
  transition: all 300ms ease-in-out;
  user-select: none;
  vertical-align: top;
  ${(props) =>
    props.iconOrangeDs
      ? ``
      : `svg {
        fill: ${gray[300]};
      }`}

  &:hover {
    background-color: ${grayscale[100]};
    border-color: ${orange[500]};
  }
`

export const IconWrapper = styled.div`
  display: block;
  height: 100px;
  padding-top: 35px;

  svg {
    transition: all 300ms ease-in-out;
  }

  ${CardOption}:hover & {
    svg {
      fill: ${orange[500]};
    }
  }
`
export const IconOrangeDsWrapper = styled.div`
  display: block;
  height: 100px;
  padding-top: 35px;

  svg {
    transition: all 300ms ease-in-out;
  }

  ${CardOption}:hover & {
    svg {
      path {
        stroke: ${orange[500]};
      }
    }
  }
`

export const TitleWrapper = styled.div`
  color: ${grayscale[400]};
  display: block;
  font-size: 18px;
  font-weight: 400;
  height: 80px;
  transition: all 300ms ease-in-out;
  width: 100%;

  ${CardOption}:hover & {
    color: ${orange[500]};
  }
`
