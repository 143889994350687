import React from 'react'

import FileUploader from '../../../../components/FileUploader'
import * as S from '../styles'

export default function AudioEditor({ content, changeContent, folder }) {
  return (
    <S.BlockContainer>
      <S.BlockTitle>Áudio</S.BlockTitle>
      <S.InputContainer>
        <FileUploader
          content={content}
          changeContent={(newContent) => {
            changeContent({
              ...content,
              file: newContent.file,
              description: newContent.description,
            })
          }}
          folder={folder}
          type="audio/mpeg, audio/ogg, audio/wav"
        />
      </S.InputContainer>
    </S.BlockContainer>
  )
}
