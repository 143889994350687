import React from 'react'

import { useAuth } from '../../../hooks'
import List from '../../../components/List'
import {
  APRENDA_INVESTIR_BANNER_CONFIG_URL,
  APRENDA_INVESTIR_BANNER_URL,
} from '../../../config/urls'
import { BASE, APRENDA_INVESTIR, APRENDA_INVESTIR_BANNER } from '../../../config/routes'
import { DEFAULT_ALLOWED_ACTIONS } from '../../../components/List/constants'

export default function BannerList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions['aprenda-investir'].banner.publish,
    }
  }

  return (
    <List
      pageTitle="Banner"
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Banner' },
      ]}
      configUrl={APRENDA_INVESTIR_BANNER_CONFIG_URL}
      baseUrl={APRENDA_INVESTIR_BANNER_URL}
      baseRoute={APRENDA_INVESTIR_BANNER}
      searchField="title"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        groupBy: ['uuid'],
      }}
      permissions={getPermissions()}
      allowedActions={[...DEFAULT_ALLOWED_ACTIONS, 'UNPUBLISH']}
    />
  )
}
