import React from 'react'

import Moderation from '../../../components/Moderation'
import { APRENDA_INVESTIR_TRAIL_URL } from '../../../config/urls'
import { BASE, APRENDA_INVESTIR_TRAIL, APRENDA_INVESTIR } from '../../../config/routes'
import Visualization from './Visualization'

export default function TrailModeration() {
  return (
    <Moderation
      pageTitle="Trilha"
      mainUrl={APRENDA_INVESTIR_TRAIL_URL}
      mainRoute={APRENDA_INVESTIR_TRAIL}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Trilha' },
      ]}
      renderVisualization={(trail) => <Visualization trail={trail} />}
    />
  )
}
