import React from 'react'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import Chevron from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'

import { Checkbox, Input, SelectNative } from '@inter/inter-ui'

import { FormInputLabel } from '../../../../../components/Form/styles'
import ImageUploader from '../../../../../components/ImageUploader'
import { IMAGES_URL } from '../../../../../config/urls'
import * as S from './styles'

const LinkEditor = ({ content, changeContent }) => {
  const [currentKey, setCurrentKey] = React.useState(0)

  React.useEffect(() => {
    changeContent({
      ...content,
      data: content.data.map((block, index) => ({ ...block, key: `link-editor-${index}` })),
    })
    setCurrentKey(content.data.length)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function addBlock() {
    changeContent({
      ...content,
      key: `link-editor-${currentKey}`,
      data: [...content.data, { link: '', isVideo: false, url: '', alt: '' }],
    })
    setCurrentKey(currentKey + 1)
  }

  function removeBlock(index) {
    const updatedContent = { ...content }
    updatedContent.data.splice(index, 1)

    changeContent(updatedContent)
  }

  function changePosition(indexFrom, indexTo) {
    if (indexTo < 0 || indexTo > content.data.length - 1) return

    const updatedContent = { ...content }
    const aux = updatedContent.data[indexFrom]

    updatedContent.data[indexFrom] = updatedContent.data[indexTo]
    updatedContent.data[indexTo] = aux

    changeContent(updatedContent)
  }

  function updateContent(index, newContent) {
    const updatedContent = { ...content }
    updatedContent.data[index] = newContent

    changeContent(updatedContent)
  }

  function renderCard(block, index) {
    const { key, link, isVideo, url, alt } = block

    return (
      <S.CardContainer key={`${key}-${index}`}>
        <S.BlockContainer>
          <S.InputContainer>
            <FormInputLabel>Link:</FormInputLabel>
            <Input
              type="text"
              placeholder="Informe um link"
              value={link}
              onChange={(e) => {
                updateContent(index, { ...block, link: e.target.value })
              }}
              style={{
                width: '100%',
              }}
            />
          </S.InputContainer>
          <S.InputContainer>
            <Checkbox
              defaultChecked={isVideo}
              label="É vídeo?"
              onChange={(e) => {
                updateContent(index, { ...block, isVideo: e.target.checked })
              }}
            />
          </S.InputContainer>
          <S.BlockTitle>Imagem</S.BlockTitle>
          <S.InputContainer>
            <ImageUploader
              image={{ url, alt }}
              setImage={(img) => {
                updateContent(index, {
                  ...block,
                  url: img && img.url,
                  alt: img && img.alt,
                })
              }}
              label=""
              url={`${IMAGES_URL}/image`}
              folder="home/social/images"
              enableAlt
            />
          </S.InputContainer>
        </S.BlockContainer>
        <S.CardControls>
          <button type="button" className="btn" onClick={() => removeBlock(index)}>
            <Close height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index - 1)}>
            <Chevron height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index + 1)}>
            <Chevron height={20} rotate={180} color="grayscale--400" />
          </button>
        </S.CardControls>
      </S.CardContainer>
    )
  }

  return (
    <S.BlockContainer>
      <S.InputContainer>
        <FormInputLabel>Título:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe o título"
          value={content.title}
          onChange={(e) => {
            changeContent({ ...content, title: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.InputContainer>
        <FormInputLabel>Rede social:</FormInputLabel>
        <SelectNative
          value={content.socialMedia}
          style={{ width: '100%' }}
          emptyOptionText="Selecione uma rede social"
          options={[
            {
              text: 'Instagram',
              value: 'instagram',
            },
            {
              text: 'Facebook',
              value: 'facebook',
            },
            {
              text: 'Twitter',
              value: 'twitter',
            },
            {
              text: 'Podcasts',
              value: 'podcast',
            },
          ]}
          onChange={(e) => changeContent({ ...content, socialMedia: e.target.value })}
        />
      </S.InputContainer>
      <S.InputContainer>
        <S.ContentContainer>
          <S.BlocksContainer>
            {content.data.length > 0 ? (
              content.data.map((block, index) => renderCard(block, index))
            ) : (
              <S.InfoText>
                <p> Por favor adicione novos blocos ... </p>
              </S.InfoText>
            )}
          </S.BlocksContainer>
          <S.ContentControls>
            <button type="button" onClick={() => addBlock()}>
              + Link
            </button>
          </S.ContentControls>
        </S.ContentContainer>
      </S.InputContainer>
    </S.BlockContainer>
  )
}

export default LinkEditor
