import styled from 'styled-components'

import { white } from '../../../styles/colors'

export const VisualizationContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

export const VideoCarouselTitle = styled.div`
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  width: 100%;
`

export const Images = styled.div`
  margin: 64px 0;
  .slick-slide {
    padding: 0 6px;
    & > div {
      width: 100%;
    }
  }
  .slick-list {
    width: 93%;
    margin: 0 auto;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }
`

export const Image = styled.figure`
  padding: 1.5rem 0;

  img {
    display: block;
    width: 100%;
  }
`
export const Player = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #16161624;
  border: 1px solid #ffffff24;
  border-radius: 50%;
  position: absolute;
  top: 96px;
  left: 50%;
  transform: translateX(-50%);
`

export const CardVideo = styled.figure`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: 100%;
  min-width: 100%;
  min-height: 292px;
  position: relative;
  border-radius: 16px;
  display: flex !important;
  align-items: flex-end;
  padding: 16px;
  figcaption {
    h3 {
      width: 100%;
      font-size: 20px;
      line-height: 25px;
      font-weight: 600;
    }

    color: ${white};
  }
`
