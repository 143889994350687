import styled, { css } from 'styled-components'

import { grayscale, orange } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

const selectedTemplate = (variant = 500) => css`
  border: 1px solid ${orange[variant]};

  div > div {
    background: ${orange[variant]};
  }
`

export const TemplatePickerContainer = styled.div`
  width: 100%;
`

export const TemplateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px -10px 10px -10px;
`

export const TemplateArea = styled.div`
  margin-bottom: 15px;
  padding: 0 10px;
  width: 20%;

  @media (max-width: ${breakpoints.xl}) {
    width: 25%;
  }

  @media (max-width: ${breakpoints.lg}) {
    width: 33.3333%;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`

export const TemplateCard = styled.div`
  align-items: center;
  border: 1px solid ${grayscale[300]};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 100%;
  min-height: 120px;
  padding: 15px;
  transition: all 0.3s ease-in-out;

  :hover {
    ${selectedTemplate(400)}
  }

  ${({ selected }) =>
    selected &&
    css`
      ${selectedTemplate()}

      :hover {
        ${selectedTemplate()}
      }
    `};
`

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  ${({ gridTemplateRows }) =>
    css`
      grid-template-rows: repeat(${gridTemplateRows}, 40px);
    `}
`

export const GridCard = styled.div`
  background: ${grayscale[200]};
  border-radius: 8px;
  transition: all 0.3s ease-in-out;

  ${({ gridArea }) =>
    css`
      grid-area: ${gridArea};
    `};
`
