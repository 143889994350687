import React from 'react'

import { useAuth } from '../../../hooks'
import List from '../../../components/List'
import { HOME_CONFIG_CONFIG_URL, HOME_CONFIG_URL } from '../../../config/urls'
import { BASE, HOME, HOME_CONFIG } from '../../../config/routes'

export default function HomeConfigList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions.home['home-config'].publish,
    }
  }
  return (
    <List
      pageTitle="Config"
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Home', route: HOME },
        { title: 'Config.' },
      ]}
      configUrl={HOME_CONFIG_CONFIG_URL}
      baseUrl={HOME_CONFIG_URL}
      baseRoute={HOME_CONFIG}
      searchField="type"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
      }}
      permissions={getPermissions()}
    />
  )
}
