import React from 'react'

import Moderation from '../../../components/Moderation'
import { APRENDA_INVESTIR_VIDEO_CAROUSEL_URL } from '../../../config/urls'
import { BASE, APRENDA_INVESTIR_VIDEO_CAROUSEL, APRENDA_INVESTIR } from '../../../config/routes'
import Visualization from './Visualization'

export default function VideoCarouselModeration() {
  return (
    <Moderation
      pageTitle="Config. do Carrossel de Vídeos"
      mainUrl={APRENDA_INVESTIR_VIDEO_CAROUSEL_URL}
      mainRoute={APRENDA_INVESTIR_VIDEO_CAROUSEL}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Config. do Carrossel de Vídeos' },
      ]}
      renderVisualization={(videoCarousel) => <Visualization videoCarousel={videoCarousel} />}
    />
  )
}
