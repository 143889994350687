import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { ALERT_URL } from '../../config/urls'
import { BASE, ALERT } from '../../config/routes'

export default function AlertModeration() {
  return (
    <Moderation
      pageTitle="Vídeos"
      mainUrl={ALERT_URL}
      mainRoute={ALERT}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Vídeos' }]}
      renderVisualization={(alert) => <Visualization alert={alert} />}
    />
  )
}
