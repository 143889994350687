import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { EVENT_URL } from '../../config/urls'
import { BASE, EVENT } from '../../config/routes'

export default function EventModeration() {
  return (
    <Moderation
      pageTitle="Evento"
      mainUrl={EVENT_URL}
      mainRoute={EVENT}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Evento' }]}
      renderVisualization={(event) => <Visualization event={event} />}
    />
  )
}
