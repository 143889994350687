import React from 'react'

import * as S from './styles'

export default function renderStatusColumn(item, showReprovalReason) {
  switch (item.status) {
    case 'UNPUBLISHED':
      return <S.Label variant="info">Não publicado</S.Label>

    case 'ON_APPROVAL':
      return <S.Label variant="alert">Em aprovação</S.Label>

    case 'REPROVED':
      return (
        <S.Label variant="error" clickable onClick={() => showReprovalReason(item.reprovalReason)}>
          Reprovado
        </S.Label>
      )

    case 'PUBLISHED':
      return <S.Label variant="success">Publicado</S.Label>

    default:
      return null
  }
}
