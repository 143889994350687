import React from 'react'

import { useAuth } from '../../hooks'
import List from '../../components/List'
import { AUTHOR_CONFIG_URL, AUTHOR_URL } from '../../config/urls'
import { BASE, AUTHOR } from '../../config/routes'

export default function AuthorList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions.author.author.publish,
    }
  }
  return (
    <List
      pageTitle="Autores"
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Autores' }]}
      configUrl={AUTHOR_CONFIG_URL}
      baseUrl={AUTHOR_URL}
      baseRoute={AUTHOR}
      searchField="name"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        groupBy: ['uuid'],
      }}
      permissions={getPermissions()}
      showOrder
    />
  )
}
