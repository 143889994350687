import styled, { css } from 'styled-components'

import { gray, white } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

export const PageContainer = styled.div`
  z-index: 10;

  ${({ fixed }) =>
    fixed
      ? css`
          background: ${white};
          box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);
          margin-left: -3rem;
          padding-left: 3rem;
          padding-right: 3rem;
          position: fixed;
          top: 0;
          width: 100%;

          @media (min-width: ${breakpoints.sm}) {
            width: 510px;
          }

          @media (min-width: ${breakpoints.md}) {
            width: 690px;
          }

          @media (min-width: ${breakpoints.lg}) {
            width: 930px;
          }

          @media (min-width: ${breakpoints.xl}) {
            width: 1110px;
          }

          @media (min-width: ${breakpoints.xxl}) {
            width: 1290px;
          }
        `
      : css`
          background: transparent;
          position: relative;
        `}
`

export const PageTitle = styled.div`
  align-items: center;
  color: ${gray[500]};
  display: flex;
  font-family: Sora;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 1rem;
  justify-content: space-between;
`

export const PageDescription = styled.p`
  font-size: 18px;
  margin-bottom: 1rem;
  text-align: center;
`
