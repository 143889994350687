import React from 'react'

import DefaultForm from '../../../components/DefaultForm'
import { HOME_BANNER_CONFIG_URL, HOME_BANNER_URL, IMAGES_URL } from '../../../config/urls'
import { BASE, HOME, HOME_BANNER } from '../../../config/routes'
import Visualization from './Visualization'

export default function BannerForm() {
  return (
    <DefaultForm
      pageTitle="Banner"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Home', route: HOME },
        { title: 'Banner', route: HOME_BANNER },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={HOME_BANNER_CONFIG_URL}
      mainRoute={HOME_BANNER}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: HOME_BANNER_URL }}
      renderVisualization={(banner) => <Visualization banner={banner} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'image':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'home/banner/images',
            enableAlt: true,
          },
        }
      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
