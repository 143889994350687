export const BASE = '/'
export const LOGIN = '/login'
export const SAML = '/saml'

export const ALERT = '/alertas'
export const ALERT_EDIT = `${ALERT}/:id`
export const ALERT_CREATE = `${ALERT}/novo`
export const ALERT_MODERATE = `${ALERT}/moderacao/:id`

export const CATEGORY = '/categorias'
export const CATEGORY_EDIT = `${CATEGORY}/:id`
export const CATEGORY_CREATE = `${CATEGORY}/novo`
export const CATEGORY_MODERATE = `${CATEGORY}/moderacao/:id`
export const CATEGORY_ORDER = `${CATEGORY}/ordem`

export const NEWS = '/noticias'
export const NEWS_EDIT = `${NEWS}/:id`
export const NEWS_CREATE = `${NEWS}/novo`
export const NEWS_MODERATE = `${NEWS}/moderacao/:id`

export const AUTHOR = '/autores'
export const AUTHOR_EDIT = `${AUTHOR}/:id`
export const AUTHOR_CREATE = `${AUTHOR}/novo`
export const AUTHOR_MODERATE = `${AUTHOR}/moderacao/:id`
export const AUTHOR_ORDER = `${AUTHOR}/ordem`

export const FILE_PACKAGE = '/pacotes'
export const FILE_PACKAGE_EDIT = `${FILE_PACKAGE}/:id`
export const FILE_PACKAGE_CREATE = `${FILE_PACKAGE}/novo`
export const FILE_PACKAGE_MODERATE = `${FILE_PACKAGE}/moderacao/:id`

export const SUBCATEGORY = '/subcategorias'
export const SUBCATEGORY_EDIT = `${SUBCATEGORY}/:id`
export const SUBCATEGORY_CREATE = `${SUBCATEGORY}/novo`
export const SUBCATEGORY_MODERATE = `${SUBCATEGORY}/moderacao/:id`
export const SUBCATEGORY_ORDER = `${SUBCATEGORY}/ordem`

export const APRENDA_INVESTIR = '/aprenda-investir'
export const APRENDA_INVESTIR_VIDEO_CAROUSEL = `${APRENDA_INVESTIR}/video-carousel`
export const APRENDA_INVESTIR_VIDEO_CAROUSEL_CREATE = `${APRENDA_INVESTIR_VIDEO_CAROUSEL}/novo`
export const APRENDA_INVESTIR_VIDEO_CAROUSEL_EDIT = `${APRENDA_INVESTIR_VIDEO_CAROUSEL}/:id`
export const APRENDA_INVESTIR_VIDEO_CAROUSEL_MODERATE = `${APRENDA_INVESTIR_VIDEO_CAROUSEL}/moderacao/:id`

export const APRENDA_INVESTIR_TRAIL = `${APRENDA_INVESTIR}/trail`
export const APRENDA_INVESTIR_TRAIL_CREATE = `${APRENDA_INVESTIR_TRAIL}/novo`
export const APRENDA_INVESTIR_TRAIL_EDIT = `${APRENDA_INVESTIR_TRAIL}/:id`
export const APRENDA_INVESTIR_TRAIL_MODERATE = `${APRENDA_INVESTIR_TRAIL}/moderacao/:id`
export const APRENDA_INVESTIR_TRAIL_ORDER = `${APRENDA_INVESTIR_TRAIL}/ordem`

export const APRENDA_INVESTIR_BANNER = `${APRENDA_INVESTIR}/banner`
export const APRENDA_INVESTIR_BANNER_CREATE = `${APRENDA_INVESTIR_BANNER}/novo`
export const APRENDA_INVESTIR_BANNER_EDIT = `${APRENDA_INVESTIR_BANNER}/:id`
export const APRENDA_INVESTIR_BANNER_MODERATE = `${APRENDA_INVESTIR_BANNER}/moderacao/:id`

export const HOME = '/home'
export const HOME_CONFIG = `${HOME}/home-config`
export const HOME_CONFIG_EDIT = `${HOME_CONFIG}/:id`
export const HOME_CONFIG_CREATE = `${HOME_CONFIG}/novo`
export const HOME_CONFIG_MODERATE = `${HOME_CONFIG}/moderacao/:id`

export const HOME_BANNER = `${HOME}/banner`
export const HOME_BANNER_EDIT = `${HOME_BANNER}/:id`
export const HOME_BANNER_CREATE = `${HOME_BANNER}/novo`
export const HOME_BANNER_MODERATE = `${HOME_BANNER}/moderacao/:id`

export const HOME_SOCIAL = `${HOME}/social`
export const HOME_SOCIAL_EDIT = `${HOME_SOCIAL}/:id`
export const HOME_SOCIAL_CREATE = `${HOME_SOCIAL}/novo`
export const HOME_SOCIAL_MODERATE = `${HOME_SOCIAL}/moderacao/:id`

export const QUOTE = '/quotes'
export const QUOTE_EDIT = `${QUOTE}/:id`
export const QUOTE_CREATE = `${QUOTE}/novo`
export const QUOTE_MODERATE = `${QUOTE}/moderacao/:id`

export const QUOTATION = '/cotacoes'
export const QUOTATION_EDIT = `${QUOTATION}/:id`
export const QUOTATION_CREATE = `${QUOTATION}/novo`
export const QUOTATION_MODERATE = `${QUOTATION}/moderacao/:id`

export const EVENT = '/evento'
export const EVENT_EDIT = `${EVENT}/:id`
export const EVENT_CREATE = `${EVENT}/novo`
export const EVENT_MODERATE = `${EVENT}/moderacao/:id`

export const LIVE = '/live'
export const LIVE_EDIT = `${LIVE}/:id`
export const LIVE_CREATE = `${LIVE}/novo`
export const LIVE_MODERATE = `${LIVE}/moderacao/:id`
