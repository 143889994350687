import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { QUOTE_URL, QUOTE_CONFIG_URL } from '../../config/urls'
import { BASE, QUOTE } from '../../config/routes'

export default function QuoteForm() {
  return (
    <DefaultForm
      pageTitle="Quotes"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Quotes', route: QUOTE },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={QUOTE_CONFIG_URL}
      mainRoute={QUOTE}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: QUOTE_URL }}
      renderVisualization={(quote) => <Visualization quote={quote} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }
      case 'title':
        return {
          ...field,
          type: 'text',
          rules: {
            ...field.rules,
            maxLength: {
              value: 85,
              message: 'A quote não pode exceder 85 caracteres.',
            },
          },
        }
      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
