import styled from 'styled-components'

import { grayscale } from '../../../styles/colors'

export const VisualizationContainer = styled.div`
  align-items: flex-start;
  background: ${grayscale[200]};
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  height: 366px;
  justify-content: center;
  padding: 80px;
  width: 935px;
`

export const BannerTitle = styled.div`
  color: ${grayscale[500]};
  font-family: Sora;
  font-size: 40px;
  font-weight: 600;
  height: 100px;
  left: calc(50% - 687px / 2 - 44px);
  line-height: 50px;
  top: calc(50% - 100px / 2);
  width: 687px;
`

export const BannerSubtitle = styled.div`
  color: ${grayscale[500]};
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  height: 100px;
  line-height: 17px;
  left: calc(50% - 169px / 2 - 303px);
  top: calc(50% - 17px / 2 + 67.5px);
  width: 687px;
`
