import React from 'react'

import { Button, Checkbox, Input } from '@inter/inter-ui'

import { FormInputLabel, FormInputInfoText } from '../Form/styles'
import { request } from '../../helpers'
import * as S from './styles'

export default function SimpleSelectWithInput({
  uuid,
  url,
  label,
  error,
  infoText,
  field,
  fieldLabel,
  items,
  setItems,
}) {
  const [checkedItems, setCheckedItems] = React.useState(
    items ? items.map((item) => ({ ...item, checked: true })) : [],
  )
  const [search, setSearch] = React.useState('')

  function getAssociatedItems() {
    request({
      method: 'GET',
      url: `${url}?uuid_ne=${uuid}&${field}_contains=${search}&status=PUBLISHED&_limit=50&_sort=title:ASC`,
    }).then((response) => {
      setCheckedItems(
        response.map((item) => ({
          ...item,
          checked: items.find((it) => it.uuid === item.uuid) !== undefined,
        })),
      )
    })
  }

  function renderOptions() {
    return checkedItems.map((item, index) => {
      const { checked, ...currentItem } = item
      const key = `key-${index}`
      return (
        <S.Item>
          <Checkbox
            key={key}
            defaultChecked={checked}
            checked={checked}
            label={currentItem[field]}
            onChange={(evt) => {
              const cloneItems = [...items]

              if (evt.target.checked) {
                cloneItems.push(currentItem)
              } else {
                cloneItems.splice(
                  cloneItems.findIndex((cloneItem) => cloneItem.uuid === currentItem.uuid),
                  1,
                )
              }

              const cloneCheckedItems = [...checkedItems]
              cloneCheckedItems[index].checked = evt.target.checked

              setCheckedItems(cloneCheckedItems)
              setItems(cloneItems)
            }}
          />
        </S.Item>
      )
    })
  }

  return (
    <S.SimpleSelectWithInputContainer>
      <FormInputLabel error={error}>{label}</FormInputLabel>
      <S.SearchContainer>
        <Input
          placeholder={`Pesquisar por ${fieldLabel}`}
          style={{ width: '95%' }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button onClick={() => getAssociatedItems()}>Buscar</Button>
      </S.SearchContainer>
      <S.ItemsContainer>
        {checkedItems.length > 0 ? renderOptions() : <span>Nenhum item disponível</span>}
      </S.ItemsContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.SimpleSelectWithInputContainer>
  )
}
