import styled, { css } from 'styled-components'

import { white, grayscale, gray, orange } from '../../styles/colors'

export const Container = styled.div`
  color: ${white};
  width: 100%;
`

export const Tabs = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 100%;

  .icon {
    fill: ${orange[500]};
  }
`

export const Label = styled.div`
  align-items: center;
  background: ${orange[500]};
  border-radius: 4px;
  color: ${white};
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  width: 100px;
`

export const Table = styled.table`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
`

export const Head = styled.thead`
  margin: 0 0 18px;
`

export const HeadRow = styled.tr`
  display: flex;
  flex-flow: row nowrap;
  padding: 0 20px;
  width: 100%;
`

export const HeadCell = styled.th`
  align-items: center;
  color: ${gray[200]};
  display: flex;
  flex-basis: 0;
  flex-flow: row nowrap;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  word-break: break-word;

  .icon {
    fill: ${grayscale[400]};
    margin-left: 4px;
  }

  ${({ sortableColumn }) =>
    sortableColumn &&
    css`
      cursor: pointer;
    `}
`

export const Body = styled.tbody``

export const BodyRow = styled.tr`
  background-color: ${grayscale[100]};
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 8px;
  padding: 20px;
  width: 100%;

  ${({ isSubRow }) =>
    isSubRow &&
    css`
      background: rgba(245, 246, 250, 0.5);
      margin-left: 25px;
      width: calc(100% - 25px);
    `}
`

export const BodyCell = styled.td`
  align-items: center;
  color: ${gray[300]};
  display: flex;
  flex-basis: 0;
  flex-flow: row nowrap;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;

  &:first-child {
    padding-left: 0;
  }
`

export const CellText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`

export const PaginationButton = styled.button`
  background: none;
  border: 1px solid ${grayscale[100]};
  border-left: 0;
  color: ${gray[300]};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  outline: none;
  height: 40px;
  width: 40px;

  &:disabled {
    background: ${grayscale[100]};
    color: ${gray[300]};
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid ${grayscale[100]};
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`

export const TableRow = styled.tr`
  display: flex;
  flex-flow: row nowrap;
`

export const TableData = styled.td`
  flex-grow: 1;
`

export const EmptyDataInfo = styled.p`
  color: ${gray[300]};
  font-size: 14px;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: calc(1rem + 8px);
  text-align: center;
  width: 100%;
`
