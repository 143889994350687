/* eslint-disable */
import React from 'react'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import Chevron from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'

import NewsEditor from './NewsEditor'
import * as S from './styles'
import { FormInputLabel, FormInputInfoText } from '../../../components/Form/styles'

function Editor({ blocks = [], setBlocks, error, infoText, url, folder, isTrail = false }, ref) {
  const [currentKey, setCurrentKey] = React.useState(0)

  React.useEffect(() => {
    setBlocks(blocks.map((block, index) => ({ ...block, key: `news-editor-${index}` })))
    setCurrentKey(blocks.length)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const addBlock = React.useCallback(() => {
    setBlocks([...blocks, getBlockStructure(currentKey + 1, isTrail)])
    setCurrentKey(currentKey + 1)
  }, [blocks, setBlocks])

  const removeBlock = React.useCallback(
    (index) => {
      const updatedBlocks = [...blocks]
      updatedBlocks.splice(index, 1)

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  const changePosition = React.useCallback(
    (indexFrom, indexTo) => {
      if (indexTo < 0 || indexTo > blocks.length - 1) return

      const updatedBlocks = [...blocks]
      const aux = updatedBlocks[indexFrom]

      updatedBlocks[indexFrom] = updatedBlocks[indexTo]
      updatedBlocks[indexTo] = aux

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  const changeContent = React.useCallback(
    (index, content) => {
      const updatedBlocks = [...blocks]
      updatedBlocks[index].content = content

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  function renderEditor(block, index) {
    const { content } = block

    return (
      <NewsEditor
        blockKey={block.key}
        content={content}
        changeContent={(newContent) => {
          changeContent(index, newContent)
        }}
        saveUrl={url}
        folder={folder}
        isTrail={isTrail}
      />
    )
  }

  function renderCard(block, index) {
    return (
      <S.CardContainer key={block.key}>
        {renderEditor(block, index)}
        <S.CardControls>
          <button type="button" className="btn" onClick={() => removeBlock(index)}>
            <Close height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index - 1)}>
            <Chevron height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index + 1)}>
            <Chevron height={20} rotate={180} color="grayscale--400" />
          </button>
        </S.CardControls>
      </S.CardContainer>
    )
  }

  return (
    <S.EditorContainer ref={ref}>
      <FormInputLabel error={error}>Notícias</FormInputLabel>
      <S.ContentContainer>
        <S.BlocksContainer>
          {blocks.length > 0 ? (
            blocks.map((block, index) => renderCard(block, index))
          ) : (
            <S.InfoText>
              <p> Por favor adicione novos blocos ... </p>
            </S.InfoText>
          )}
        </S.BlocksContainer>
        <S.ContentControls>
          <button type="button" onClick={() => addBlock()}>
            + Notícia
          </button>
        </S.ContentControls>
      </S.ContentContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.EditorContainer>
  )
}

function getBlockStructure(currentKey, isTrail) {
  return {
    key: `news-editor-${currentKey}`,
    content: {
      title: '',
      url: '',
      alt: '',
      link: '',
      ...(isTrail && {
        date: new Date(),
        isVideo: false,
        videoTitle: '',
        videoSlug: '',
        videoDescription: '',
        videoCategory: '',
      }),
    },
  }
}

export default React.forwardRef(Editor)
