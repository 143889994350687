import React from 'react'

import { Input } from '@inter/inter-ui'

import RichTextEditor from '../../../../components/RichTextEditor'
import { FormInputLabel } from '../../../../components/Form/styles'
import * as S from '../styles'

export default function TextEditor({ content, changeContent }) {
  const { title, text } = content

  return (
    <S.BlockContainer>
      <S.BlockTitle>Texto</S.BlockTitle>
      <S.InputContainer>
        <FormInputLabel>Título:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe o título (opcional)"
          value={title}
          onChange={(e) => {
            changeContent({ ...content, title: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.InputContainer>
        <RichTextEditor
          content={text}
          onChange={(newContent) => {
            changeContent({ ...content, text: newContent })
          }}
        />
      </S.InputContainer>
    </S.BlockContainer>
  )
}
