import React from 'react'

import DatePicker from 'react-datepicker'
import pt from 'date-fns/locale/pt-BR'

import 'react-datepicker/dist/react-datepicker.css'
import { FormInputLabel, FormInputInfoText } from '../Form/styles'
import * as S from './styles'

export default function DateTimeEditor({
  label,
  error,
  infoText,
  date = new Date(),
  setDate,
  timeIntervals = 1,
  showTimeSelect = true,
  dateFormat = 'Pp',
  minDate = null,
}) {
  function handleDataChangeRaw(evt) {
    evt.preventDefault()
  }

  return (
    <S.BlockContainer>
      <FormInputLabel error={error}>{label}</FormInputLabel>
      <S.DatePickerBlock>
        <DatePicker
          placeholderText={infoText}
          selected={date}
          onChange={(d) => setDate(d)}
          minDate={minDate}
          locale={pt}
          showTimeSelect={showTimeSelect}
          timeFormat="p"
          timeIntervals={timeIntervals}
          dateFormat={dateFormat}
          onChangeRaw={(evt) => handleDataChangeRaw(evt)}
        />
      </S.DatePickerBlock>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.BlockContainer>
  )
}
