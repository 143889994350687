import React from 'react'

import { Link } from 'react-router-dom'
import CancelModerateIcon from 'inter-frontend-svgs/lib/v2/action/refuse'
import DeleteIcon from 'inter-frontend-svgs/lib/v2/action/delete'
import EditIcon from 'inter-frontend-svgs/lib/v2/action/pencil'
import InfoIcon from 'inter-frontend-svgs/lib/v2/action/info-2'
import ModerateIcon from 'inter-frontend-svgs/lib/v2/action/stats/success'
import UnpublishIcon from 'inter-frontend-svgs/lib/v2/action/stats/error'

import { ACTIONS } from './constants'
import { request } from '../../helpers'
import * as S from './styles'

export default function renderActionsColumn(
  item,
  baseRoute,
  permissions,
  allowedActions,
  baseUrl,
  setModalConfig,
  setConfirmModalConfig,
  doSearch,
) {
  const { id, createdBy, approvedBy, unpublishedBy, status } = item

  const icons = {
    CANCEL_APPROVAL_REQUEST: <CancelModerateIcon height={22} width={22} color="gray--300" />,
    DELETE: <DeleteIcon height={22} width={22} color="gray--300" />,
    EDIT: <EditIcon height={22} width={22} color="gray--300" />,
    INFO: <InfoIcon height={22} width={22} color="gray--300" />,
    MODERATE: <ModerateIcon height={22} width={22} />,
    REQUEST_APPROVAL: (
      <S.RequestApprovalIcon>
        <ModerateIcon height={24} width={24} />
      </S.RequestApprovalIcon>
    ),
    UNPUBLISH: <UnpublishIcon height={22} width={22} />,
  }

  const getActionButton = (action) => {
    const { title, message, method, pathPrefix = '' } = ACTIONS[action]

    switch (action) {
      case 'DELETE':
      case 'REQUEST_APPROVAL':
      case 'CANCEL_APPROVAL_REQUEST':
      case 'UNPUBLISH':
        return (
          <S.ActionButton
            key={`${action}-${id}`}
            title={title}
            onClick={() => {
              setConfirmModalConfig({
                isOpen: true,
                message,
                onConfirm: () => {
                  setConfirmModalConfig({ isOpen: false })
                  request({
                    method,
                    url: `${baseUrl}${pathPrefix}/${id}`,
                  }).then(() => doSearch())
                },
              })
            }}
          >
            {icons[action]}
          </S.ActionButton>
        )

      case 'EDIT':
      case 'MODERATE':
        return (
          <Link
            key={`${action}-${id}`}
            to={{ pathname: `${baseRoute}${pathPrefix}/${id}`, state: item }}
          >
            <S.ActionButton title={title}>{icons[action]}</S.ActionButton>
          </Link>
        )

      case 'INFO':
        return (
          <S.ActionButton
            key={`${action}-${id}`}
            title={title}
            onClick={() =>
              setModalConfig({
                isOpen: true,
                header: title,
                message: (
                  <S.UsersInfo>
                    <p>Criado por: {createdBy}</p>
                    {approvedBy && <p>Aprovado por: {approvedBy}</p>}
                    {unpublishedBy && <p>Despublicado por: {unpublishedBy}</p>}
                  </S.UsersInfo>
                ),
              })
            }
          >
            {icons[action]}
          </S.ActionButton>
        )

      default:
        return null
    }
  }

  return allowedActions.reduce((acc, cur) => {
    switch (cur) {
      case 'INFO':
      case 'EDIT':
      case 'DELETE':
        return [...acc, getActionButton(cur)]

      case 'REQUEST_APPROVAL':
        return status === 'UNPUBLISHED' && !permissions.moderator
          ? [...acc, getActionButton(cur)]
          : acc

      case 'CANCEL_APPROVAL_REQUEST':
        return status === 'ON_APPROVAL' && !permissions.moderator
          ? [...acc, getActionButton(cur)]
          : acc

      case 'MODERATE':
        return (status === 'UNPUBLISHED' || status === 'ON_APPROVAL') && permissions.moderator
          ? [...acc, getActionButton(cur)]
          : acc

      case 'UNPUBLISH':
        return status === 'PUBLISHED' && permissions.moderator
          ? [...acc, getActionButton(cur)]
          : acc

      default:
        return acc
    }
  }, [])
}
