import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { QUOTATION_URL } from '../../config/urls'
import { BASE, QUOTATION } from '../../config/routes'

export default function QuotationModeration() {
  return (
    <Moderation
      pageTitle="Cotações"
      mainUrl={QUOTATION_URL}
      mainRoute={QUOTATION}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Cotações', route: QUOTATION_URL },
        { title: 'Moderação' },
      ]}
      renderVisualization={(quotation) => <Visualization quotation={quotation} />}
    />
  )
}
