import React from 'react'

import InterLogo from 'inter-frontend-svgs/lib/logos/banco-mono'
import LogoutIcon from 'inter-frontend-svgs/lib/layout/logout'

import { withRequest } from '../../hocs'
import { useAuth } from '../../hooks'
import { BASE } from '../../config/routes'
import * as S from './styles'

function Layout({ children }) {
  const { signOut } = useAuth()

  return (
    <>
      <S.ModalArea id="modal-area" />
      <S.Header>
        <S.LayoutHeader>
          <S.HomeLink href={BASE}>
            <InterLogo height={40} />
          </S.HomeLink>
          <S.LogoutButton onClick={signOut}>
            Sair
            <LogoutIcon height={20} />
          </S.LogoutButton>
        </S.LayoutHeader>
      </S.Header>
      <S.Main>
        <S.LayoutCard>{children}</S.LayoutCard>
      </S.Main>
    </>
  )
}

export default withRequest(Layout)
