import React from 'react'

import axios from 'axios'

import LoadingScreen from '../components/LoadingScreen'
import ErrorModal from '../components/ErrorModal'

export default function withRequest(WrappedComponent) {
  return (props) => {
    const [requestLoading, setRequestLoading] = React.useState(false)
    const [requestError, setRequestError] = React.useState(false)

    React.useEffect(() => {
      axios.interceptors.request.use((request) => {
        setRequestLoading(true)
        return request
      })

      axios.interceptors.response.use(
        (response) => {
          setRequestLoading(false)
          return response
        },
        (error) => {
          setRequestLoading(false)
          setRequestError(true)
          return Promise.reject(error)
        },
      )
    }, [])

    return (
      <>
        <WrappedComponent {...props} />
        {requestLoading && <LoadingScreen />}
        <ErrorModal isOpen={requestError} closeModal={() => setRequestError(false)} />
      </>
    )
  }
}
