import React, { useEffect, useState } from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { request, slugify } from '../../helpers'
import {
  LIVE_URL,
  LIVE_CONFIG_URL,
  LIVE_ADDITIONAL_INFO_URL,
  AUTHOR_URL,
  CATEGORY_URL,
  IMAGES_URL,
  TAGS_URL,
  QUOTATION_URL,
} from '../../config/urls'
import { BASE, LIVE } from '../../config/routes'

export default function LiveForm() {
  const [additionalInfo, setAdditionalInfo] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const data = await request({
        method: 'GET',
        url: `${LIVE_ADDITIONAL_INFO_URL}`,
      })
      setAdditionalInfo(data)
    }
    fetchData()
  }, [])

  return (
    additionalInfo && (
      <DefaultForm
        pageTitle="Live"
        breadcrumbs={(path) => [
          { title: 'Invest CMS', route: BASE },
          { title: 'Live', route: LIVE },
          { title: path === 'novo' ? 'Novo' : 'Editar' },
        ]}
        configUrl={LIVE_CONFIG_URL}
        mainRoute={LIVE}
        buildForm={(data) => buildForm(data, additionalInfo)}
        submitOptions={{ method: 'POST', url: LIVE_URL }}
        renderVisualization={(live) => (
          <Visualization live={live} authors={additionalInfo.authors} />
        )}
      />
    )
  )
}

function validateTitle(title, { lives }, uuid) {
  let errorMessage = ''

  const titleInputValue = slugify(title)
  const titleAlreadyExist = lives.some(
    (live) => slugify(live.title) === titleInputValue && live.uuid !== uuid.defaultValue,
  )

  if (title !== '' && titleAlreadyExist) {
    errorMessage = 'Já existe uma Live cadastrada com esse título.'
  }

  return errorMessage
}

function buildForm(fields, additionalInfo) {
  let hasInvalidData

  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
      case 'alreadyPublish':
        return {
          ...field,
          type: 'hidden',
        }

      case 'title':
        return {
          ...field,
          type: 'text',
          rules: {
            ...field.rules,
            validate: {
              validTitle: (title) => {
                const message = validateTitle(title, additionalInfo, fields[0])
                return message.length === 0 || message
              },
            },
          },
        }

      case 'author':
        return {
          ...field,
          type: 'select',
          fetch: {
            url: `${AUTHOR_URL}?status=PUBLISHED&_sort=name:ASC`,
            callback: (data) =>
              data.map(({ uuid, name }) => ({
                value: uuid,
                label: name,
              })),
          },
        }

      case 'category':
        return {
          ...field,
          type: 'select',
          fetch: {
            url: `${CATEGORY_URL}?status=PUBLISHED&_sort=name:ASC`,
            callback: (data) =>
              data.map(({ uuid, name }) => ({
                value: uuid,
                label: name,
              })),
          },
        }

      case 'sendPush':
      case 'featured':
      case 'investProExclusive':
        return {
          ...field,
          type: 'checkbox',
          size: '1/2',
        }

      case 'pushContent':
        return {
          ...field,
          type: 'text',
          dependsOn: 'sendPush',
          rules: {
            maxLength: {
              value: 400,
              message: 'Este campo não pode exceder 400 caracteres.',
            },
          },
        }

      case 'pushTitle':
        return {
          ...field,
          type: 'text',
          dependsOn: 'sendPush',
          rules: {
            maxLength: {
              value: 40,
              message: 'Este campo não pode exceder 40 caracteres.',
            },
          },
        }

      case 'tags':
        return {
          ...field,
          type: 'select-with-input',
          options: {
            url: TAGS_URL,
            field: 'name',
          },
        }

      case 'date':
      case 'startDate':
        return {
          ...field,
          type: 'date',
          defaultValue: field.defaultValue ? new Date(field.defaultValue) : new Date(),
          options: {
            showTimeSelect: true,
          },
        }

      case 'endDate':
        return {
          ...field,
          type: 'date',
          defaultValue: field.defaultValue
            ? new Date(field.defaultValue)
            : new Date(new Date().getTime() + 5 * 60000),
          options: {
            showTimeSelect: true,
          },
          fieldRules: {
            mainItemField: 'endDate',
            dependentField: 'startDate',
            validationCallback: (startDate, endDate) => {
              hasInvalidData = endDate > startDate
            },
          },
          rules: {
            ...field.rules,
            validate: {
              validEndDate: () =>
                hasInvalidData
                  ? 'A data de término deve ser maior do que a data de início.'
                  : undefined,
            },
          },
        }

      case 'content':
        return {
          ...field,
          type: 'text-editor',
          options: {
            fieldLabel: 'Conteúdo',
          },
          rules: {
            ...field.rules,
            validate: {
              validContent: (content) =>
                !content.length || content.trim() === '<p></p>'
                  ? 'Este campo é obrigatório'
                  : undefined,
            },
          },
        }

      case 'detailImage':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'live/images/detail',
            enableAlt: true,
          },
          rules: {
            ...field.rules,
            validate: {
              validDetailImage: (image) => (!image ? 'Este campo é obrigatório' : undefined),
            },
          },
        }

      case 'homeImage':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'live/images/home',
            enableAlt: true,
          },
          rules: {
            ...field.rules,
            validate: {
              validHomeImage: (image) => (!image ? 'Este campo é obrigatório' : undefined),
            },
          },
        }

      case 'featuredImage':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'live/images/featured',
            enableAlt: true,
          },
          rules: {
            ...field.rules,
            validate: {
              validFeaturedImage: (image) => (!image ? 'Este campo é obrigatório' : undefined),
            },
          },
        }

      case 'report':
        return {
          ...field,
          type: 'single-file-uploader',
          options: {
            folder: 'live/files',
            type: '.pdf',
            label: 'Relatório',
          },
        }

      case 'quotations':
        return {
          ...field,
          type: 'select-with-input',
          options: {
            url: QUOTATION_URL,
            field: 'name',
          },
        }

      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
