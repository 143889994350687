import React from 'react'

import { request } from '../../helpers'
import Page from '../../components/Page'
import DraggableList from '../../components/DraggableList'
import LoadingScreen from '../../components/LoadingScreen'
import { BASE, SUBCATEGORY } from '../../config/routes'
import { SUBCATEGORY_URL } from '../../config/urls'

export default function Order() {
  const [list, setList] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    getData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = React.useCallback(() => {
    setLoading(true)

    request({
      method: 'GET',
      url: `${SUBCATEGORY_URL}?status=PUBLISHED`,
    }).then((response) => {
      setLoading(false)
      setList(response.sort((a, b) => a.order - b.order))
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page
      title="Subcategorias"
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Subcategorias', route: SUBCATEGORY },
        { title: 'Ordem' },
      ]}
    >
      {loading ? (
        <LoadingScreen />
      ) : (
        <DraggableList
          listItems={list}
          fieldToShow="name"
          emptyListMessage="Não existem itens para ordenação"
          handleNewOrder={(newList) => setList(newList)}
          mainRoute={SUBCATEGORY}
          submitUrl={`${SUBCATEGORY_URL}/update-order`}
        />
      )}
    </Page>
  )
}
