import React from 'react'

import DefaultForm from '../../../components/DefaultForm'
import {
  APRENDA_INVESTIR_VIDEO_CAROUSEL_CONFIG_URL,
  APRENDA_INVESTIR_VIDEO_CAROUSEL_URL,
  IMAGES_URL,
} from '../../../config/urls'
import { BASE, APRENDA_INVESTIR, APRENDA_INVESTIR_VIDEO_CAROUSEL } from '../../../config/routes'
import Visualization from './Visualization'

export default function VideoCarouselForm() {
  return (
    <DefaultForm
      pageTitle="Config. do Carrossel de Vídeos"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Config. do Carrossel de Vídeos', route: APRENDA_INVESTIR_VIDEO_CAROUSEL },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={APRENDA_INVESTIR_VIDEO_CAROUSEL_CONFIG_URL}
      mainRoute={APRENDA_INVESTIR_VIDEO_CAROUSEL}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: APRENDA_INVESTIR_VIDEO_CAROUSEL_URL }}
      renderVisualization={(videoCarousel) => <Visualization videoCarousel={videoCarousel} />}
    />
  )
}

function validateNews(news) {
  let errorMessage = ''
  news.forEach((item) => {
    const { content } = item

    if (content.title.length === 0) {
      errorMessage = 'O título deve ser preenchido corretamente.'
    }

    if (content.url.length === 0) {
      errorMessage = 'Uma imagem deve ser informada.'
    }

    if (content.link.length === 0) {
      errorMessage = 'Um link deve ser informado.'
    }
  })

  return errorMessage
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'news':
        return {
          ...field,
          type: 'video-carousel-news-editor',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'aprenda-investir/video-carousel/images',
          },
          rules: {
            ...field.rules,
            validate: {
              validNews: (news) => {
                const message = validateNews(news)
                return message.length === 0 || message
              },
            },
          },
        }

      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
