import React from 'react'

import RichTextEditor from '../RichTextEditor'
import { FormInputLabel } from '../Form/styles'
import * as S from './styles'

export default function TextEditor({ fieldLabel, content, changeContent, error }) {
  return (
    <S.BlockContainer>
      <FormInputLabel error={error}>{fieldLabel}</FormInputLabel>
      <S.InputContainer>
        <RichTextEditor
          content={content}
          onChange={(newContent) => {
            changeContent(newContent)
          }}
        />
      </S.InputContainer>
    </S.BlockContainer>
  )
}
