import React from 'react'

import * as S from './styles'

export default function BannerVisualization({ banner }) {
  const { image, description, title } = banner
  return (
    <S.VisualizationContainer style={{ background: `url(${image.url})` }}>
      <S.BannerTitle>{title}</S.BannerTitle>
      <S.BannerSubtitle>{description}</S.BannerSubtitle>
    </S.VisualizationContainer>
  )
}
