import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { ALERT_URL, ALERT_CONFIG_URL, IMAGES_URL } from '../../config/urls'
import { BASE, ALERT } from '../../config/routes'

const categories = [
  {
    value: 'todos',
    label: 'Todos',
  },
  {
    value: 'Maratona Fundos Imobiliários',
    label: 'Maratona Fundos Imobiliários',
  },
  {
    value: 'Mercado de Ações',
    label: 'Mercado de Ações',
  },
  {
    value: 'Inter Ajuda: Home Broker',
    label: 'Inter Ajuda: Home Broker',
  },
  {
    value: 'Inter Week',
    label: 'Inter Week',
  },
]

export default function AlertForm() {
  return (
    <DefaultForm
      pageTitle="Vídeos"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Vídeos', route: ALERT },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={ALERT_CONFIG_URL}
      mainRoute={ALERT}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: ALERT_URL }}
      renderVisualization={(alert) => <Visualization alert={alert} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'isLive':
        return {
          ...field,
          type: 'checkbox',
        }

      case 'categoryId':
        return {
          ...field,
          type: 'select',
          options: categories,
        }

      case 'thumbnail':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'alert/thumbnails',
            enableAlt: true,
          },
        }

      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
