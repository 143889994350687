import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import {
  AUTHOR_URL,
  CATEGORY_URL,
  NEWS_URL,
  NEWS_CONFIG_URL,
  TAGS_URL,
  IMAGES_URL,
  SUBCATEGORY_URL,
  QUOTATION_URL,
} from '../../config/urls'
import { BASE, NEWS } from '../../config/routes'
import { validateContentBlocks } from '../../components/Form/validations'

export default function NewsForm() {
  return (
    <DefaultForm
      title="Notícias"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Notícias', route: NEWS },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={NEWS_CONFIG_URL}
      mainRoute={NEWS}
      buildForm={(data) => buildForm(data)}
      submitOptions={{
        method: 'POST',
        url: NEWS_URL,
      }}
      renderVisualization={(news) => <Visualization news={news} />}
    />
  )
}

function getDispatchTimeOptions() {
  const options = ['9', '11', '13', '15', '19']

  return options.map((option) => ({
    label: `${option.toString()}h`,
    value: `${option.toString()}`,
  }))
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
      case 'slug':
        return {
          ...field,
          type: 'hidden',
        }
      case 'publishedAt':
        return {
          ...field,
          type: 'date',
          defaultValue: field.defaultValue ? new Date(field.defaultValue) : new Date(),
          size: '1/2',
          options: {
            showTimeSelect: true,
          },
        }
      case 'isBestContent':
      case 'isVideo':
      case 'spotlight':
      case 'isPremium':
      case 'sendToMktCloud':
        return {
          ...field,
          type: 'checkbox',
        }
      case 'subtitle':
        return {
          ...field,
          type: 'text',
          dependsOn: 'isPremium',
        }
      case 'readingTime':
        return {
          ...field,
          type: 'number',
          size: '1/2',
        }
      case 'authorId':
        return {
          ...field,
          type: 'select',
          size: '1/2',
          fetch: {
            url: `${AUTHOR_URL}?status=PUBLISHED`,
            callback: (data) =>
              data.map((author) => ({
                value: author.uuid,
                label: author.name,
              })),
          },
        }
      case 'categoryId':
        return {
          ...field,
          type: 'select',
          size: '1/2',
          fetch: {
            url: `${CATEGORY_URL}?status=PUBLISHED`,
            callback: (data) =>
              data.map((category) => ({
                value: category.uuid,
                label: category.name,
              })),
          },
        }
      case 'quotationId':
        return {
          ...field,
          type: 'select',
          size: '1/2',
          fetch: {
            url: `${QUOTATION_URL}?status=PUBLISHED`,
            callback: (data) =>
              data.map((quotation) => ({
                value: quotation.uuid,
                label: quotation.name,
              })),
          },
        }
      case 'subcategoryId':
        return {
          ...field,
          type: 'select',
          size: '1/2',
          fetch: {
            url: `${SUBCATEGORY_URL}?status=PUBLISHED`,
            callback: (data) =>
              data.map((subcategory) => ({
                value: subcategory.uuid,
                label: subcategory.name,
              })),
          },
        }
      case 'tags':
        return {
          ...field,
          type: 'select-with-input',
          options: {
            url: TAGS_URL,
            field: 'name',
          },
        }
      case 'relatedNews':
        return {
          ...field,
          type: 'simple-select-with-input',
          options: {
            url: `${NEWS_URL}`,
            field: 'title',
            fieldLabel: 'Título',
          },
        }
      case 'homeImage':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'news/home-images',
            enableAlt: true,
          },
        }
      case 'emailSubject':
        return {
          ...field,
          type: 'text',
          size: '1/2',
          dependsOn: 'sendToMktCloud',
        }
      case 'dispatchTime':
        return {
          ...field,
          type: 'select',
          size: '1/2',
          dependsOn: 'sendToMktCloud',
          options: getDispatchTimeOptions(),
        }
      case 'emailBody':
        return {
          ...field,
          type: 'text-editor',
          dependsOn: 'sendToMktCloud',
          options: {
            fieldLabel: 'Corpo do e-mail',
          },
        }
      case 'segmentations':
        return {
          ...field,
          type: 'multiple-select',
          options: {
            availableTopics: [
              { value: 'Carteiras', label: 'Carteiras' },
              { value: 'Macroeconomia', label: 'Macroeconomia' },
              { value: 'FIIs', label: 'FIIs' },
              { value: 'Global Markets', label: 'Global Markets' },
              { value: 'O Mercado na Semana', label: 'O Mercado na Semana' },
            ],
          },
          dependsOn: 'sendToMktCloud',
        }
      case 'actions':
        return {
          ...field,
          type: 'multiple-select',
          options: {
            availableTopics: [
              { value: 'Varejo', label: 'Varejo' },
              { value: 'Tecnologia', label: 'Tecnologia' },
              { value: 'Comunicação', label: 'Comunicação' },
              { value: 'Concessão e Transportes', label: 'Concessão e Transportes' },
              { value: 'Construção Civil', label: 'Construção Civil' },
              { value: 'Shoppings', label: 'Shoppings' },
              {
                value: 'Utilities (Elétricas e Saneamento)',
                label: 'Utilities (Elétricas e Saneamento)',
              },
              { value: 'Saúde', label: 'Saúde' },
              { value: 'Óleo e Gás', label: 'Óleo e Gás' },
              { value: 'Siderurgia e Mineração', label: 'Siderurgia e Mineração' },
              { value: 'Papel e celulose', label: 'Papel e celulose' },
              { value: 'Frigoríficos', label: 'Frigoríficos' },
              { value: 'Bancos', label: 'Bancos' },
              { value: 'Seguradoras', label: 'Seguradoras' },
              { value: 'Financeiras', label: 'Financeiras' },
            ],
          },
          dependsOn: 'sendToMktCloud',
        }
      case 'contentBlocks':
        return {
          ...field,
          type: 'news-editor',
          options: {
            audioFolder: 'news/audio',
            folder: 'news/images',
          },
          rules: {
            ...field.rules,
            validate: {
              validBlocks: (blocks) => {
                const message = validateContentBlocks(blocks)
                return message.length === 0 || message
              },
            },
          },
        }
      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
