import React from 'react'

import { Button, Input } from '@inter/inter-ui'

import Modal from '../Modal'
import * as S from './styles'

export default function ConfirmModal({ isOpen, closeModal, message, inputOptions, onConfirm }) {
  const [inputValue, setInputValue] = React.useState('')

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      {message && <S.ConfirmMessage>{message}</S.ConfirmMessage>}
      {inputOptions && (
        <Input
          label={inputOptions.label}
          placeholder={inputOptions.placeholder}
          value={inputValue}
          onChange={(evt) => setInputValue(evt.target.value)}
          style={{ width: '100%' }}
        />
      )}
      <S.ActionButtons>
        <Button variant="secondary" onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          disabled={inputOptions && inputValue === ''}
          onClick={() => onConfirm(inputValue)}
        >
          Confirmar
        </Button>
      </S.ActionButtons>
    </Modal>
  )
}
