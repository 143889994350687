import styled, { css, keyframes } from 'styled-components'

import { Icon } from '@inter/inter-ui'

import { grayscale, white } from '../../styles/colors'

const scaleUp = keyframes`
  0% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
`

export const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 20;
`

export const Modal = styled.div`
  animation: ${scaleUp} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  background-color: ${white};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  min-width: 400px;
  position: relative;
  transition: transform 0.5s;

  ${({ width }) => width && css`width: ${width}}`};
`

export const Close = styled(Icon).attrs({
  name: 'close',
  height: 20,
  width: 20,
})`
  position: absolute;
  right: 10px;
  top: 10px;
`

export const Header = styled.div`
  border-bottom: 1px solid ${grayscale[100]};
  margin-bottom: 12px;
  user-select: none;
`

export const HeaderTitle = styled.h1`
  color: ${grayscale[500]};
  margin: 10px 0 0 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
`

export const Footer = styled.footer`
  border-top: 1px solid ${grayscale[100]};
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
`
