import styled, { css } from 'styled-components'

export const ConfirmMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
`

export const actionButtons = css`
  display: flex;
  flex-wrap: wrap;

  button {
    width: 100px;

    &:first-child {
      margin-right: 2rem;
    }
  }
`

export const ActionButtons = styled.div`
  justify-content: center;
  ${actionButtons}
`
