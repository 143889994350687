import React from 'react'

import { useAuth } from '../../hooks'
import List from '../../components/List'
import { EVENT_CONFIG_URL, EVENT_URL } from '../../config/urls'
import { BASE, EVENT } from '../../config/routes'
import { DEFAULT_ALLOWED_ACTIONS } from '../../components/List/constants'

export default function EventList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions.event.event.publish,
    }
  }

  return (
    <List
      pageTitle="Evento"
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Evento' }]}
      configUrl={EVENT_CONFIG_URL}
      baseUrl={EVENT_URL}
      baseRoute={EVENT}
      searchField="title"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        groupBy: ['uuid'],
      }}
      permissions={getPermissions()}
      allowedActions={[...DEFAULT_ALLOWED_ACTIONS, 'UNPUBLISH']}
    />
  )
}
