import React from 'react'

import Breadcrumbs from '../Breadcrumbs'
import * as S from './styles'

export default function Page({ children, title, description, breadcrumbs, fixedHeader }) {
  const [isPositionFixed, setIsPositionFixed] = React.useState(false)

  const handleScroll = React.useCallback((positionTop) => {
    if (positionTop > 228) setIsPositionFixed(true)
    else setIsPositionFixed(false)
  }, [])

  React.useEffect(() => {
    if (fixedHeader) {
      const handler = () => handleScroll(window.scrollY)

      document.addEventListener('scroll', handler)

      return () => {
        document.removeEventListener('scroll', handler)
      }
    }

    return undefined
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <S.PageContainer fixed={isPositionFixed}>
        <S.PageTitle>{title}</S.PageTitle>
        {breadcrumbs && <Breadcrumbs options={breadcrumbs} />}
      </S.PageContainer>
      {isPositionFixed && <div style={{ width: '100%', height: 'calc(107px - 1rem)' }} />}
      {description && <S.PageDescription>{description}</S.PageDescription>}
      {children}
    </>
  )
}
