import React from 'react'

import { Input } from '@inter/inter-ui'

import { FormInputLabel } from '../../../../components/Form/styles'
import * as S from '../styles'

export default function VideoEditor({ content, changeContent }) {
  const { url, caption, maxWidth } = content

  return (
    <S.BlockContainer>
      <S.BlockTitle>Vídeo</S.BlockTitle>
      <S.InputContainer>
        <FormInputLabel>URL:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe a URL do YouTube"
          value={url}
          onChange={(e) => {
            changeContent({
              ...content,
              url: e.target.value.replace(/watch\?v=|watch\//g, 'embed/').replace(/&.*/g, ''),
            })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.InputContainer>
        <FormInputLabel>Legenda:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe uma legenda para o vídeo (opcional)"
          value={caption}
          onChange={(e) => {
            changeContent({ ...content, caption: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.InputContainer>
        <FormInputLabel>Largura Máxima (em px ou %):</FormInputLabel>
        <Input
          type="text"
          placeholder="Ex: 100% ou 325px (opcional)"
          value={maxWidth}
          onChange={(e) => {
            changeContent({ ...content, maxWidth: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
    </S.BlockContainer>
  )
}
