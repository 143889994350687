import React from 'react'

import { useAuth } from '../../../hooks'
import List from '../../../components/List'
import {
  APRENDA_INVESTIR_VIDEO_CAROUSEL_CONFIG_URL,
  APRENDA_INVESTIR_VIDEO_CAROUSEL_URL,
} from '../../../config/urls'
import { BASE, APRENDA_INVESTIR, APRENDA_INVESTIR_VIDEO_CAROUSEL } from '../../../config/routes'

export default function VideoCarouselList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions['aprenda-investir']['video-carousel'].publish,
    }
  }

  return (
    <List
      pageTitle="Config. do Carrossel de Vídeos"
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Config. do Carrossel de Vídeos' },
      ]}
      configUrl={APRENDA_INVESTIR_VIDEO_CAROUSEL_CONFIG_URL}
      baseUrl={APRENDA_INVESTIR_VIDEO_CAROUSEL_URL}
      baseRoute={APRENDA_INVESTIR_VIDEO_CAROUSEL}
      searchField="title"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        _limit: 5,
      }}
      permissions={getPermissions()}
    />
  )
}
