import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import QueryString from 'query-string'

import { Button } from '@inter/inter-ui'

import { useDebounce } from '../../hooks'
import Page from '../Page'
import Table from '../Table'
import { getDefaultSortField, fetchColumns, fetchData } from './services'
import { formatChildrenFilters } from './formatters'
import {
  DEFAULT_ALLOWED_ACTIONS,
  DEFAULT_EDITION_FILTERS,
  DEFAULT_MODERATION_FILTERS,
  INITIAL_QUERY_STRING,
  ITEMS_PER_PAGE,
} from './constants'
import Modal from '../Modal'
import ConfirmModal from '../ConfirmModal'
import { NewButtonLink, OrderButtonLink } from './styles'

export default function List({
  pageTitle,
  breadcrumbs,
  configUrl,
  baseUrl,
  baseRoute,
  searchField,
  editionFilters = DEFAULT_EDITION_FILTERS,
  moderationFilters = DEFAULT_MODERATION_FILTERS,
  childrenFilters,
  permissions,
  allowedActions = DEFAULT_ALLOWED_ACTIONS,
  hiddenColumns,
  showOrder,
  showNewButton = true,
}) {
  const [columns, setColumns] = React.useState([])
  const [data, setData] = React.useState({
    items: [],
    count: 0,
  })

  const [modalConfig, setModalConfig] = React.useState({
    isOpen: false,
    message: '',
  })

  const [confirmModalConfig, setConfirmModalConfig] = React.useState({
    isOpen: false,
    message: '',
    inputOptions: null,
    onConfirm: () => undefined,
  })

  const history = useHistory()
  const location = useLocation()
  const queryString =
    location.search === '' ? INITIAL_QUERY_STRING : QueryString.parse(location.search)

  const doSearch = React.useCallback(() => {
    fetchData(
      baseUrl,
      (response) =>
        setData({
          items: response.data,
          count: response.headers.count,
        }),
      queryString,
      editionFilters,
      moderationFilters,
    )
  }, [baseUrl, editionFilters, moderationFilters, queryString])

  React.useEffect(() => {
    fetchColumns(
      configUrl,
      setColumns,
      baseRoute,
      permissions,
      allowedActions,
      baseUrl,
      setModalConfig,
      setConfirmModalConfig,
      doSearch,
    )

    if (!queryString.sort) {
      getDefaultSortField(configUrl, (field, direction) =>
        handleChange('sort', `${field}:${direction}`),
      )
    } else {
      history.push(`${baseRoute}?${QueryString.stringify(queryString)}`)
      doSearch()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = React.useCallback(
    (parameter, value) => {
      queryString[parameter] = value !== '' ? value : undefined

      history.push(`${baseRoute}?${QueryString.stringify(queryString)}`)
      doSearch()
    },
    [baseRoute, doSearch, history, queryString],
  )

  const searchFunction = useDebounce(
    (searchValue) =>
      searchValue.length >= 3
        ? handleChange('search', `${searchField}:${encodeURIComponent(searchValue)}`)
        : handleChange('search', ''),
    500,
  )

  const getTabs = () => {
    const currentTabs = [
      {
        label: 'Edição',
        value: 'edition',
        selected: queryString.tab === 'edition',
        onClick: (tab) => handleChange('tab', tab),
      },
      {
        label: 'Moderação',
        value: 'moderation',
        selected: queryString.tab === 'moderation',
        onClick: (tab) => handleChange('tab', tab),
      },
    ]

    if (!permissions.moderator) {
      currentTabs.pop()
    }

    return currentTabs
  }

  return (
    <Page title={pageTitle} breadcrumbs={breadcrumbs}>
      <Modal
        isOpen={modalConfig.isOpen}
        onClose={() => setModalConfig({ isOpen: false, message: '', header: '' })}
        header={modalConfig.header}
      >
        {modalConfig.message}
      </Modal>
      <ConfirmModal
        isOpen={confirmModalConfig.isOpen}
        message={confirmModalConfig.message}
        onConfirm={confirmModalConfig.onConfirm}
        inputOptions={confirmModalConfig.inputOptions}
        closeModal={() => setConfirmModalConfig({ ...confirmModalConfig, isOpen: false })}
      />
      <Table
        columns={columns}
        data={data.items}
        hiddenColumns={hiddenColumns}
        tabs={getTabs()}
        search={
          columns.length > 0 && {
            field: columns.find((column) => column.accessor === searchField).Header,
            value: queryString.search ? queryString.search.split(':')[1] : '',
            onChange: searchFunction,
          }
        }
        sort={
          queryString.sort && {
            field: queryString.sort.split(':')[0],
            direction: queryString.sort.split(':')[1],
            onChange: (field, direction) => handleChange('sort', `${field}:${direction}`),
          }
        }
        paginator={
          queryString.page && {
            currentPage: parseInt(queryString.page, 10) > 0 ? parseInt(queryString.page, 10) : 1,
            setCurrentPage: (page) => handleChange('page', page),
            totalPages: Math.ceil(data.count / ITEMS_PER_PAGE),
          }
        }
        onRowClick={(row) => {
          if (queryString.tab === 'moderation' || !childrenFilters) return

          if (!row.isExpanded) {
            const filters = formatChildrenFilters(row.original, childrenFilters)
            fetchData(
              baseUrl,
              (response) => {
                const updatedItems = [...data.items]
                updatedItems[row.index].subRows = response.data

                setData({ ...data, items: updatedItems })
                row.toggleRowExpanded()
              },
              filters,
              editionFilters,
              moderationFilters,
            )
          } else {
            row.toggleRowExpanded()
          }
        }}
        extraControls={
          <>
            {showNewButton && (
              <NewButtonLink to={{ pathname: `${baseRoute}/novo`, state: {} }}>
                <Button variant="primary" style={{ minWidth: '100px' }}>
                  Novo
                </Button>
              </NewButtonLink>
            )}

            {showOrder && (
              <OrderButtonLink to={{ pathname: `${baseRoute}/ordem`, state: {} }}>
                <Button variant="secondary" style={{ minWidth: '100px' }}>
                  Ordem
                </Button>
              </OrderButtonLink>
            )}
          </>
        }
      />
    </Page>
  )
}
