import React from 'react'

import { useAuth } from '../../../hooks'
import List from '../../../components/List'
import { HOME_BANNER_CONFIG_URL, HOME_BANNER_URL } from '../../../config/urls'
import { BASE, HOME_BANNER, HOME } from '../../../config/routes'
import { DEFAULT_ALLOWED_ACTIONS } from '../../../components/List/constants'

export default function BannerList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions.home.banner.publish,
    }
  }

  return (
    <List
      pageTitle="Banner"
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Home', route: HOME },
        { title: 'Banner' },
      ]}
      configUrl={HOME_BANNER_CONFIG_URL}
      baseUrl={HOME_BANNER_URL}
      baseRoute={HOME_BANNER}
      searchField="title"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        groupBy: ['uuid'],
      }}
      permissions={getPermissions()}
      allowedActions={[...DEFAULT_ALLOWED_ACTIONS, 'UNPUBLISH']}
    />
  )
}
