import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { AUTHOR_URL, AUTHOR_CONFIG_URL, IMAGES_URL } from '../../config/urls'
import { AUTHOR, BASE } from '../../config/routes'

export default function AuthorForm() {
  return (
    <DefaultForm
      pageTitle="Autores"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Autores', route: AUTHOR },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={AUTHOR_CONFIG_URL}
      mainRoute={AUTHOR}
      buildForm={(data) => buildForm(data)}
      submitOptions={{
        method: 'POST',
        url: AUTHOR_URL,
      }}
      renderVisualization={(author) => <Visualization author={author} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }
      case 'name':
        return {
          ...field,
          type: 'text',
        }
      case 'investorCertificate':
        return {
          ...field,
          type: 'text',
          size: '1/2',
        }
      case 'photo':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'author/images',
            enableAlt: true,
          },
        }
      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
