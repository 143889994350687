import React from 'react'

import { BrowserRouter as Router } from 'react-router-dom'

import { AuthContextProvider } from './contexts/AuthContext'
import Routes from './components/Routes'

function App() {
  const auth = sessionStorage.getItem('auth')

  return (
    <Router>
      <AuthContextProvider authentication={auth && JSON.parse(auth)}>
        <Routes />
      </AuthContextProvider>
    </Router>
  )
}

export default App
