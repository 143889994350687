import React from 'react'

import * as S from './styles'

export default function AlertVisualization({ alert }) {
  const { isLive, title, description, externalLink } = alert

  return (
    <S.Container>
      {isLive && <S.Badge>• Live</S.Badge>}
      <S.InfoContainer>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.InfoContainer>
      <S.VideoContainer>
        {externalLink && (
          <S.Video style={{ margin: '0 auto' }}>
            <iframe
              id="ytplayer"
              title={title}
              src={externalLink.replace(/watch\?v=|watch\//g, 'embed/').replace(/&.*/g, '')}
              frameBorder="0"
              allow="autoplay"
              allowFullScreen
            />
          </S.Video>
        )}
      </S.VideoContainer>
    </S.Container>
  )
}
