import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { CATEGORY_URL } from '../../config/urls'
import { BASE, CATEGORY } from '../../config/routes'

export default function CategoryModeration() {
  return (
    <Moderation
      pageTitle="Categorias"
      mainUrl={CATEGORY_URL}
      mainRoute={CATEGORY}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Categorias', route: CATEGORY },
        { title: 'Moderação' },
      ]}
      renderVisualization={(category) => <Visualization category={category} />}
    />
  )
}
