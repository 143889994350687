import styled from 'styled-components'

import { grayscale, orange, white } from '../../styles/colors'

export const ListContainer = styled.div`
  display: table;
  width: 100%;

  button {
    position: relative;
    float: right;
    margin-top: 3rem;
    margin-left: 20px;
    min-width: 150px;
  }
`

export const ListLabel = styled.label`
  font-weight: 700;
  margin-bottom: 2em;
`

export const EmptyListMessage = styled.p`
  font-size: 14px;
  text-align: center;
  margin-top: 2em;
`

export const List = styled.div`
  background-color: ${white};
  border-radius: 5px;
  max-height: 500px;
  margin-top: 2em;
  outline: none;
  overflow: auto;
  position: relative;
  width: 100%;
  z-index: 0;
`
export const ListItem = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  color: ${white};
  border-radius: 4px;
  border: 1px solid ${grayscale[200]};
  box-sizing: border-box;
  background-color: ${grayscale[100]};
  font-weight: 400;
  height: 49px;
  padding: 0 20px;
  margin-bottom: 8px;
  width: 100%;
  user-select: none;
`
export const ListItemValue = styled.span`
  width: 50%;
  color: ${grayscale[400]};
  text-align: left;
  font-size: 16px;
  font-weight: 600;
`
export const ListItemPosition = styled.span`
  width: 50%;
  color: ${orange[500]};
  text-align: right;
  font-size: 16px;
  font-weight: 400;
`
