import React from 'react'

import { OptionButton } from './styles'

export default function EditorOption({ children, title, onToggle, active, disabled }) {
  return (
    <OptionButton
      title={title}
      active={active}
      disabled={disabled}
      onMouseDown={(evt) => {
        evt.preventDefault()
        onToggle()
      }}
    >
      {children}
    </OptionButton>
  )
}
