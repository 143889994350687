import React from 'react'

import * as S from './styles'

export default function CategoryVisualization({ category }) {
  const { name, subtitle, color500, color300, color200, color100 } = category

  return (
    <S.VisualizationContainer>
      <S.PrimaryColorContainer bgColor={color500}>
        <S.CategoryTitle>{name}</S.CategoryTitle>
        <S.CategorySubTitle>{subtitle}</S.CategorySubTitle>
        <S.ColorInfo>
          <S.ColorVariant>500</S.ColorVariant>
          <S.ColorHex>{color500.replace('#', '')}</S.ColorHex>
        </S.ColorInfo>
      </S.PrimaryColorContainer>
      <S.SecondaryColorsContainer>
        <S.SecondaryColorContainer bgColor={color300}>
          <S.ColorInfo>
            <S.ColorVariant>300</S.ColorVariant>
            <S.ColorHex>{color300.replace('#', '')}</S.ColorHex>
          </S.ColorInfo>
        </S.SecondaryColorContainer>
        <S.SecondaryColorContainer bgColor={color200}>
          <S.ColorInfo>
            <S.ColorVariant>200</S.ColorVariant>
            <S.ColorHex>{color200.replace('#', '')}</S.ColorHex>
          </S.ColorInfo>
        </S.SecondaryColorContainer>
        <S.SecondaryColorContainer bgColor={color100}>
          <S.ColorInfo>
            <S.ColorVariant>100</S.ColorVariant>
            <S.ColorHex>{color100.replace('#', '')}</S.ColorHex>
          </S.ColorInfo>
        </S.SecondaryColorContainer>
      </S.SecondaryColorsContainer>
    </S.VisualizationContainer>
  )
}
