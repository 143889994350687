import React from 'react'

import { useForm, Controller } from 'react-hook-form'

import { Button } from '@inter/inter-ui'

import renderField from './renders'
import * as S from './styles'

function findDefaultValue(current, formFields) {
  const formField = formFields.find((item) => item.name === current.dependsOn)
  if (!formField) {
    return false
  }
  return formFields.find((item) => item.name === current.dependsOn).defaultValue
}
function Form(
  {
    formFields,
    onSubmit,
    submitButtonText = 'Salvar',
    isVisualizating,
    renderVisualization,
    additionalButtons,
  },
  ref,
) {
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useForm()

  const uuid = formFields.find((item) => item.name === 'uuid')

  const watchedFields = formFields.reduce((acc, cur) => {
    const { dependsOn, fieldRules } = cur

    if (dependsOn) {
      return {
        ...acc,
        [dependsOn]:
          watch(dependsOn) === undefined ? findDefaultValue(cur, formFields) : watch(dependsOn),
      }
    }

    if (fieldRules) {
      fieldRules.validationCallback(
        watch(fieldRules.mainItemField),
        watch(fieldRules.dependentField),
      )
    }

    return acc
  }, {})

  const renderFields = React.useMemo(
    () =>
      formFields.map((formField) => {
        const checkVisibility = (field) => {
          if (!field.dependsOn) return true

          return field.dependencyCallback
            ? field.dependencyCallback(watchedFields[field.dependsOn])
            : watchedFields[field.dependsOn]
        }

        return (
          <Controller
            key={formField.name}
            name={formField.name}
            defaultValue={formField.defaultValue}
            rules={
              !checkVisibility(formField)
                ? { ...formField.rules, required: false }
                : formField.rules
            }
            control={control}
            render={({ field }) =>
              renderField(
                {
                  ...formField,
                  ...field,
                  error: errors[formField.name] !== undefined,
                  errorMessage:
                    errors[formField.name] !== undefined && errors[formField.name].message,
                  identifier: uuid && uuid.defaultValue ? uuid.defaultValue : '',
                },
                checkVisibility(formField),
              )
            }
          />
        )
      }),
    [formFields, control, errors, uuid, watchedFields],
  )

  React.useImperativeHandle(ref, () => ({ runValidations: trigger }))

  return formFields.length > 0 ? (
    <S.Form
      ref={ref}
      onSubmit={handleSubmit((data) => onSubmit(data))}
      onKeyDown={(e) => {
        if (e.code === 'Enter') e.preventDefault()
      }}
    >
      {isVisualizating ? (
        renderVisualization(getValues())
      ) : (
        <S.FormContainer>{renderFields}</S.FormContainer>
      )}
      <S.ActionButtons>
        {additionalButtons}
        <Button type="submit" variant="primary" style={{ minWidth: '150px' }}>
          {submitButtonText}
        </Button>
      </S.ActionButtons>
    </S.Form>
  ) : null
}

export default React.forwardRef(Form)
