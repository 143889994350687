import styled from 'styled-components'

import { grayscale } from '../../../styles/colors'

export const VisualizationContainer = styled.div`
  align-items: flex-end;
  background: ${grayscale[200]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 32px;
  height: 366px;
  width: 935px;
`

export const BannerTitle = styled.div`
  color: ${grayscale[500]};
  font-family: Sora;
  font-size: 40px;
  font-weight: 600;
  height: 100px;
  line-height: 50px;
  width: 50%;
`

export const BannerSubtitle = styled.div`
  color: ${grayscale[500]};
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  width: 50%;
`
