import React from 'react'

import { Link } from 'react-router-dom'
import BannerIcon from 'inter-frontend-svgs/lib/v2/action/photo-library'
import HomeConfigIcon from 'inter-frontend-svgs/lib/v2/action/house'
import SocialIcon from 'inter-frontend-svgs/lib/v2/misc/time'

import { useAuth } from '../../hooks'
import Page from '../../components/Page'
import Card from '../../components/Card'
import { HOME_CONFIG, HOME_BANNER, BASE, HOME_SOCIAL } from '../../config/routes'

export default function Home() {
  const { auth } = useAuth()
  const permissions = {
    'home-config': auth.permissions.home['home-config'].find,
    banner: auth.permissions.home.banner.find,
    social: auth.permissions.home.social.find,
  }

  return (
    <Page
      title="Invest CMS"
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Home' }]}
      description="Selecione o serviço da Home que deseja:"
    >
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="row">
            {permissions.banner && (
              <div className="col-sm-6 col-lg-3">
                <Link to={HOME_BANNER}>
                  <Card title="Banner" icon={<BannerIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions['home-config'] && (
              <div className="col-sm-6 col-lg-3">
                <Link to={HOME_CONFIG}>
                  <Card title="Config." icon={<HomeConfigIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.social && (
              <div className="col-sm-6 col-lg-3">
                <Link to={HOME_SOCIAL}>
                  <Card title="Redes Sociais" icon={<SocialIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}
