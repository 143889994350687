import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { grayscale, white, status } from '../../styles/colors'

export const Icon = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  width: 100%;

  .icon {
    fill: ${grayscale[400]};
  }

  ${({ isSubRow }) =>
    isSubRow &&
    css`
      margin-left: 2rem;
    `}
`

export const Label = styled.div`
  align-items: center;
  border-radius: 4px;
  color: ${white};
  display: flex;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  justify-content: center;
  width: 96px;

  ${({ variant }) =>
    status[variant] &&
    css`
      background: ${status[variant]};
    `}

  ${({ clickable }) => css`
    cursor: ${clickable ? 'pointer' : 'inherit'};
  `}
`

export const NewButtonLink = styled(Link)`
  position: absolute;
  right: 48px;
`

export const OrderButtonLink = styled(Link)`
  position: absolute;
  right: 160px;
`

export const ActionButton = styled.button`
  background: transparent;
  margin-right: 4px;
  user-select: none;
`

export const UsersInfo = styled.div`
  p {
    margin-bottom: 5px;
  }
`

export const RequestApprovalIcon = styled.div`
  svg {
    path:first-child {
      fill: ${status.alert};
    }
  }
`
