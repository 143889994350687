import styled from 'styled-components'

import { grayscale } from '../../styles/colors'

export const Container = styled.div`
  align-items: center;
  color: ${grayscale[400]};
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  text-align: left;
`

export const CellphoneContainer = styled.div`
  width: 100%;
  overflow-y: hidden;
  position: relative;
  min-height: 800px;

  @media (min-width: 480px) {
    width: 400px;
  }

  img {
    position: absolute;
    width: 100%;
  }
`

export const LiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 27px;
  width: 100%;

  @media (min-width: 480px) {
    width: 400px;
  }
`

export const LiveContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`

export const Live = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 725px;
  line-height: 17px;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0;
  }
`

export const LiveHeader = styled.div`
  align-items: center;
  color: #6a6e81;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  button {
    align-items: center;
    background: #f5f6fa;
    border: 1px solid #f5f6fa;
    border-radius: 50%;
    cursor: initial;
    display: flex;
    justify-content: center;
    outline: none;
    padding: 10px;
  }
`

export const BannerContainer = styled.div`
  margin: 0 -16px 22px -16px;

  img {
    max-height: 150px;
    position: relative;
  }
`

export const SectionTitle = styled.div`
  color: #4b4e5c;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 16px;
  width: 100%;
`

export const LiveDetailsContainer = styled.div`
  align-items: center;
  color: #979aa7;
  border-bottom: 1px solid #f5f6fa;
  display: flex;
  font-size: 10px;
  justify-content: space-between;
  line-height: 12px;
  margin-bottom: 32px;
  padding-bottom: 8px;
`

export const LiveDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const AuthorPhoto = styled.div`
  margin-right: 8px;

  img {
    border-radius: 8px;
    height: 32px;
    position: relative;
    width: 32px;
  }
`

export const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
`

export const AuthorInfo = styled.div`
  display: block;
`

export const AuthorName = styled.div`
  color: #6a6e81;
  font-weight: 600;
  margin-bottom: 4px;
`

export const Calendar = styled.div`
  align-items: center;
  display: flex;

  span {
    margin-left: 0.5rem;
  }
`

export const Subtitle = styled.div`
  color: #6a6e81;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 8px;
  width: 100%;
`

export const Report = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;

  a {
    border: 1px solid #ff8700;
    border-radius: 4px;
    cursor: pointer;
    color: #4b4e5c;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 14px 42px;
  }
`
