export const validateContentBlocks = (contentBlocks) => {
  let errorMessage = ''
  contentBlocks.forEach((block) => {
    const { type, content } = block

    switch (type) {
      case 'citation':
        if (content.text.length === 0 || content.author.length === 0) {
          errorMessage = 'Os blocos do tipo citação devem ser preenchidos corretamente.'
        }
        break
      case 'image':
        if (content.url && content.url.length === 0) {
          errorMessage = 'Os blocos de imagem devem ser preenchidos corretamente.'
        }
        break
      case 'text':
        if (content.text.replace(/<[^>]+>/g, '').replace(/\r?\n|\r/g, '') === '') {
          errorMessage = 'Os blocos de texto devem ser preenchidos corretamente.'
        }
        break
      case 'video':
        if (content.url.length === 0) {
          errorMessage = 'Os blocos de vídeo devem ser preenchidos corretamente.'
        }
        break
      case 'tweet':
        if (content.url.length === 0) {
          errorMessage = 'Os blocos de tweets devem ser preenchidos corretamente.'
        }
        break
      case 'file':
        if (content.description.length === 0 || Object.keys(content.file).length === 0) {
          errorMessage = 'Os blocos de arquivos devem ser preenchidos corretamente.'
        }
        break
      case 'instagram':
        if (content.url.length === 0) {
          errorMessage = 'É obrigatório informar uma url de um post do Instagram.'
        } else if (content.htmlContent.length === 0) {
          errorMessage = 'A url do Instagram informada é inválida.'
        }
        break
      case 'facebook':
        if (content.url.length === 0) {
          errorMessage = 'Os blocos do Facebook devem ser preenchidos corretamente.'
        }
        break
      case 'audio':
        if (content.description.length === 0 || Object.keys(content.file).length === 0) {
          errorMessage = 'Os blocos de áudio devem ser preenchidos corretamente.'
        }
        break
      case 'anchor':
        if (content.url.length === 0) {
          errorMessage = 'Os blocos do Anchor devem ser preenchidos corretamente.'
        }
        break
      default:
        break
    }
  })

  return errorMessage
}
