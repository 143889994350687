import styled, { css } from 'styled-components'

import { status, grayscale } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'
import { actionButtons } from '../ConfirmModal/styles'

const textModifiers = {
  error: css`
    color: ${status.error};
  `,
  disabled: css`
    color: ${grayscale[300]};
  `,
}

export const Form = styled.form`
  margin-bottom: 80px;
  margin-top: 2rem;
`

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`

export const FormInput = styled.div`
  align-items: center;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;

  ${({ size }) => {
    if (size) {
      const [numerator, denominator] = size.replace(/ /g, '').split('/')
      const percentage = (numerator / denominator) * 100
      const percentageXXL = percentage > 25 ? percentage : 25
      const percentageXL = percentage > 33.3333 ? percentage : 33.3333
      const percentageLG = percentage > 50 ? percentage : 50

      return css`
        flex: 0 0 ${percentage}%;
        max-width: ${percentage}%;

        @media (max-width: ${breakpoints.xxl}) {
          flex: 0 0 ${percentageXXL}%;
          max-width: ${percentageXXL}%;
        }

        @media (max-width: ${breakpoints.xl}) {
          flex: 0 0 ${percentageXL}%;
          max-width: ${percentageXL}%;
        }

        @media (max-width: ${breakpoints.lg}) {
          flex: 0 0 ${percentageLG}%;
          max-width: ${percentageLG}%;
        }

        @media (max-width: ${breakpoints.md}) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      `
    }

    return css`
      flex: 0 0 100%;
      max-width: 100%;
    `
  }}
`

export const FormCustomInput = styled.div`
  margin-bottom: 20px;
`

export const FormInputTitle = styled.div`
  display: flex;
  margin-bottom: 4px;
`

export const FormInputLabel = styled.label`
  ${({ error, disabled }) => css`
    color: ${grayscale[400]};
    font-family: Inter;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    margin-right: auto;
    margin-top: auto;
    ${error && textModifiers.error}
    ${disabled && textModifiers.disabled}
  `}
`

export const FormInputInfoText = styled.span`
  ${({ error, disabled, isTextEditor }) => css`
    bottom: ${isTextEditor ? '0' : '5px'};
    color: ${grayscale[300]};
    font-family: Inter;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    ${error && textModifiers.error}
    ${disabled && textModifiers.disabled}
  `}
`

export const ActionButtons = styled.div`
  bottom: 3rem;
  position: absolute;
  right: 3rem;

  ${actionButtons}
`
