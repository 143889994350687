import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'

import { Button, Input } from '@inter/inter-ui'

import { request } from '../../helpers'
import LoadingScreen from '../LoadingScreen'
import * as S from './styles'

const PAGE_SIZE = 100

export default function Gallery({ url, onSelectImage, size, folder }) {
  const [images, setImages] = React.useState(null)
  const [slicedImages, setSlicedImages] = React.useState(null)
  const [start, setStart] = React.useState(0)
  const [totalImages, setTotalImages] = React.useState(0)

  const [loading, setLoading] = React.useState(false)
  const [isDoingRequest, setIsDoingRequest] = React.useState(false)

  const [selectedImage, setSelectedImage] = React.useState(null)
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    getImages()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function getImages() {
    setLoading(true)
    const total = await request({
      method: 'GET',
      url: `${url}/count?folder=${folder}`,
    })
    setTotalImages(total)
    const response = await request({
      method: 'GET',
      url: `${url}?_limit=${PAGE_SIZE}&_start=0&folder=${folder}&_sort=uploadedAt:DESC&description_contains=${encodeURIComponent(
        search,
      )}`,
    })

    setStart(0)
    setLoading(false)
    setImages(response)
  }

  React.useEffect(() => {
    setSlicedImages(images ? images.slice(0, PAGE_SIZE) : null)
  }, [images])

  const fetchMoreImages = async () => {
    if (start >= totalImages) {
      return
    }
    setIsDoingRequest(true)
    const response = await request({
      method: 'GET',
      url: `${url}?_limit=${PAGE_SIZE}&_start=${start}&folder=${folder}&_sort=uploadedAt:DESC&description_contains=${encodeURIComponent(
        search,
      )}`,
    })
    setIsDoingRequest(false)
    setSlicedImages([...slicedImages, ...response])
    setStart(start + PAGE_SIZE)
  }

  const onScrollEvent = async (event) => {
    const position = event.srcElement.scrollHeight - event.srcElement.clientHeight

    if (
      parseInt(event.srcElement.scrollTop, 10) / parseInt(position, 10) > 0.9 &&
      !isDoingRequest
    ) {
      await fetchMoreImages()
    }
  }

  function renderGallery() {
    return (
      <>
        <S.SearchContainer>
          <Input
            placeholder="Pesquisar pela descrição da imagem"
            style={{ width: '95%' }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button onClick={() => getImages()}>Buscar</Button>
        </S.SearchContainer>
        <InfiniteScroll
          onScroll={onScrollEvent}
          style={{ overflow: 'none' }}
          dataLength={images ? images.length : 0}
          hasMore
          scrollableTarget="scrollableDiv"
        >
          <S.ImagesContainer>
            {slicedImages && slicedImages.length > 0 ? (
              slicedImages.map((image, idx) => {
                const key = `key-${idx}`

                return (
                  <S.Image
                    key={key}
                    label={idx}
                    size={size}
                    style={image && { backgroundImage: `url(${image.url})` }}
                    className={parseInt(selectedImage, 10) === idx ? 'selected' : ''}
                    onClick={() => {
                      setSelectedImage(idx)
                      onSelectImage(image)
                    }}
                  />
                )
              })
            ) : (
              <span>Nenhuma imagem disponível</span>
            )}
          </S.ImagesContainer>
        </InfiniteScroll>
      </>
    )
  }

  return (
    <S.GalleryContainer id="scrollableDiv">
      {loading ? <LoadingScreen /> : renderGallery()}
    </S.GalleryContainer>
  )
}
