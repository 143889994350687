import React from 'react'

import ShareIcon from 'inter-frontend-svgs/lib/orangeds/SM/share'
import PlayIcon from 'inter-frontend-svgs/lib/orangeds/MD/play'

import * as S from './styles'

export default function SocialsVisualization({ socials }) {
  const { title, description, posts } = socials
  return (
    <>
      <S.VisualizationContainer>
        <S.TitleContainer>
          <ShareIcon color="#ff7a00" />
          <S.Title>{title}</S.Title>
        </S.TitleContainer>
        <S.Subtitle>{description}</S.Subtitle>
      </S.VisualizationContainer>
      <S.SocialsContainer>
        {posts.map((item) => {
          const { content, key } = item
          return (
            <>
              <S.PostTitle key={`title-${key}`}>{content.title}</S.PostTitle>
              <S.SocialContainer key={`cont-${key}`}>
                {content.data.map((post) => {
                  const { url, isVideo } = post
                  if (content.socialMedia === 'podcast') {
                    return (
                      <S.PodcastPost key={`post-${key}`} style={{ backgroundImage: `url(${url})` }}>
                        {isVideo && <PlayIcon color="#fff" />}
                      </S.PodcastPost>
                    )
                  }
                  return (
                    <S.Post key={`post-${key}`} style={{ backgroundImage: `url(${url})` }}>
                      {isVideo && <PlayIcon color="#fff" />}
                    </S.Post>
                  )
                })}
              </S.SocialContainer>
            </>
          )
        })}
      </S.SocialsContainer>
    </>
  )
}
