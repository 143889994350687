import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { NEWS_URL } from '../../config/urls'
import { BASE, NEWS } from '../../config/routes'

export default function NewsModeration() {
  return (
    <Moderation
      pageTitle="Notícias"
      mainUrl={NEWS_URL}
      mainRoute={NEWS}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Notícias' }]}
      renderVisualization={(news) => <Visualization news={news} />}
    />
  )
}
