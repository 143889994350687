import styled from 'styled-components'

import { grayscale, white } from '../../styles/colors'

export const MultipleSelectContainer = styled.div`
  background-color: ${grayscale[100]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
`

export const ItemsContainer = styled.div`
  background-color: ${white};
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-top: 10px;
  padding: 10px 15px 0px 15px;
  span {
    padding-top: 30px;
    text-align: center;
    width: 100%;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 250px;
  width: 250px;
`
