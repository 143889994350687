import React from 'react'

import { request } from '../../../helpers'
import Page from '../../../components/Page'
import DraggableList from '../../../components/DraggableList'
import LoadingScreen from '../../../components/LoadingScreen'
import { BASE, APRENDA_INVESTIR_TRAIL, APRENDA_INVESTIR } from '../../../config/routes'
import { APRENDA_INVESTIR_TRAIL_URL } from '../../../config/urls'

export default function Order() {
  const [list, setList] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    getData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = React.useCallback(() => {
    setLoading(true)

    request({
      method: 'GET',
      url: `${APRENDA_INVESTIR_TRAIL_URL}?status=PUBLISHED`,
    }).then((response) => {
      setLoading(false)
      setList(response.sort((a, b) => a.order - b.order))
    })
  }, [])

  return (
    <Page
      title="Trilhas"
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Trilha' },
      ]}
    >
      {loading ? (
        <LoadingScreen />
      ) : (
        <DraggableList
          listItems={list}
          fieldToShow="title"
          emptyListMessage="Não existem itens para ordenação"
          handleNewOrder={(newList) => setList(newList)}
          mainRoute={APRENDA_INVESTIR_TRAIL}
          submitUrl={`${APRENDA_INVESTIR_TRAIL_URL}/update-order`}
        />
      )}
    </Page>
  )
}
