import React from 'react'

import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { request } from '../helpers'
import { AUTH_URL, USER_ROLE_URL, USER_URL } from '../config/urls'
import { BASE, SAML } from '../config/routes'

export const AuthContext = React.createContext({})

export function AuthContextProvider({ children, authentication }) {
  const [auth, setAuth] = React.useState(authentication)
  const [cookies, , removeCookie] = useCookies()
  const history = useHistory()

  const buildUserPermissions = React.useCallback(
    (permissions) =>
      Object.entries(permissions).reduce(
        (plugins, plugin) => ({
          ...plugins,
          [plugin[0]]: Object.entries(plugin[1].controllers).reduce(
            (controllers, controller) => ({
              ...controllers,
              [controller[0]]: Object.entries(controller[1]).reduce(
                (handlers, handler) => ({
                  ...handlers,
                  [handler[0]]: handler[1].enabled,
                }),
                {},
              ),
            }),
            {},
          ),
        }),
        {},
      ),
    [],
  )

  const signIn = React.useCallback(
    (loginInfo) => {
      request({ method: 'POST', url: AUTH_URL, body: loginInfo }).then((data) => {
        request({
          method: 'GET',
          url: `${USER_ROLE_URL}/${data.user.role.id}`,
          headers: { Authorization: `Bearer ${data.jwt}` },
        }).then(({ permissions }) => {
          const authData = { ...data, permissions: buildUserPermissions(permissions) }
          setAuth(authData)
          sessionStorage.setItem('auth', JSON.stringify(authData))
          history.push(BASE)
        })
      })
    },
    [buildUserPermissions, history],
  )

  const signInWithSaml = React.useCallback(
    (location) => {
      const { investjwt } = cookies

      if (investjwt) {
        request({
          method: 'GET',
          url: USER_URL,
          headers: { Authorization: `Bearer ${investjwt}` },
        }).then((user) => {
          request({
            method: 'GET',
            url: `${USER_ROLE_URL}/${user.role.id}`,
            headers: { Authorization: `Bearer ${investjwt}` },
          }).then(({ permissions }) => {
            const authData = { jwt: investjwt, permissions: buildUserPermissions(permissions) }
            setAuth(authData)
            sessionStorage.setItem('auth', JSON.stringify(authData))
            history.push(location !== SAML ? location : BASE)
          })
        })
      }
    },
    [cookies, buildUserPermissions, history],
  )

  const signOut = React.useCallback(() => {
    setAuth(null)
    sessionStorage.removeItem('auth')
    removeCookie('investjwt', {
      expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT'),
      domain:
        process.env.REACT_APP_NODE_ENV === 'production' ? '.bancointer.com.br' : '.uatbi.com.br',
    })
    history.push(BASE)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider value={{ auth, signIn, signInWithSaml, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}
