import React from 'react'

import FlareIcon from 'inter-frontend-svgs/lib/orangeds/MD/sun'

import * as S from './styles'

export default function QuoteVisualization({ quote }) {
  const { title } = quote

  return (
    <S.VisualizationContainer>
      <S.TitleContainer>
        <S.Icon>
          <FlareIcon color="#FF9D42" height={24} width={24} />
        </S.Icon>

        <S.Title>Bom dia, Inter</S.Title>
      </S.TitleContainer>
      <S.Subtitle>Saiba tudo sobre o mercado hoje, em primeira mão</S.Subtitle>
      <div>
        <S.Order>01</S.Order>
        <S.Text>{title}</S.Text>
      </div>
    </S.VisualizationContainer>
  )
}
