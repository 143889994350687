import React from 'react'

import { Switch, Route } from 'react-router-dom'

import ProtectedRoute from '../ProtectedRoute'
import Login from '../../pages/Login'
import LoginSaml from '../../pages/LoginSaml'
import Home from '../../pages/Home'
import NotFound from '../../pages/NotFound'
import AlertList from '../../modules/alert/List'
import AlertForm from '../../modules/alert/Form'
import AlertModerate from '../../modules/alert/Moderation'
import AprendaInvestir from '../../modules/aprenda-investir/index'
import AprendaInvestirVideoCarouselList from '../../modules/aprenda-investir/video-carousel/List'
import AprendaInvestirVideoCarouselForm from '../../modules/aprenda-investir/video-carousel/Form'
import AprendaInvestirVideoCarouselModerate from '../../modules/aprenda-investir/video-carousel/Moderation'
import AprendaInvestirTrailList from '../../modules/aprenda-investir/trail/List'
import AprendaInvestirTrailForm from '../../modules/aprenda-investir/trail/Form'
import AprendaInvestirTrailModerate from '../../modules/aprenda-investir/trail/Moderation'
import AprendaInvestirTrailOrder from '../../modules/aprenda-investir/trail/Order'
import AprendaInvestirBannerList from '../../modules/aprenda-investir/banner/List'
import AprendaInvestirBannerForm from '../../modules/aprenda-investir/banner/Form'
import AprendaInvestirBannerModerate from '../../modules/aprenda-investir/banner/Moderation'
import AuthorList from '../../modules/author/List'
import AuthorForm from '../../modules/author/Form'
import AuthorModerate from '../../modules/author/Moderation'
import AuthorOrder from '../../modules/author/Order'
import CategoryList from '../../modules/category/List'
import CategoryForm from '../../modules/category/Form'
import CategoryModerate from '../../modules/category/Moderation'
import CategoryOrder from '../../modules/category/Order'
import EventList from '../../modules/event/List'
import EventForm from '../../modules/event/Form'
import EventModerate from '../../modules/event/Moderation'
import FilePackageList from '../../modules/file-package/List'
import FilePackageForm from '../../modules/file-package/Form'
import FilePackageModerate from '../../modules/file-package/Moderation'
import HomeIndex from '../../modules/home/index'
import HomeConfigList from '../../modules/home/home-config/List'
import HomeConfigForm from '../../modules/home/home-config/Form'
import HomeConfigModerate from '../../modules/home/home-config/Moderation'
import HomeBannerList from '../../modules/home/banner/List'
import HomeBannerForm from '../../modules/home/banner/Form'
import HomeBannerModerate from '../../modules/home/banner/Moderation'
import HomeSocialList from '../../modules/home/social/List'
import HomeSocialForm from '../../modules/home/social/Form'
import HomeSocialModerate from '../../modules/home/social/Moderation'
import LiveList from '../../modules/live/List'
import LiveForm from '../../modules/live/Form'
import LiveModerate from '../../modules/live/Moderation'
import NewsList from '../../modules/news/List'
import NewsForm from '../../modules/news/Form'
import NewsModerate from '../../modules/news/Moderation'
import QuoteList from '../../modules/quote/List'
import QuoteForm from '../../modules/quote/Form'
import QuoteModerate from '../../modules/quote/Moderation'
import QuotationList from '../../modules/quotation/List'
import QuotationForm from '../../modules/quotation/Form'
import QuotationModerate from '../../modules/quotation/Moderation'
import SubcategoryList from '../../modules/subcategory/List'
import SubcategoryForm from '../../modules/subcategory/Form'
import SubcategoryModerate from '../../modules/subcategory/Moderation'
import SubcategoryOrder from '../../modules/subcategory/Order'
import {
  LOGIN,
  SAML,
  BASE,
  ALERT,
  ALERT_EDIT,
  ALERT_CREATE,
  ALERT_MODERATE,
  APRENDA_INVESTIR,
  APRENDA_INVESTIR_VIDEO_CAROUSEL,
  APRENDA_INVESTIR_VIDEO_CAROUSEL_CREATE,
  APRENDA_INVESTIR_VIDEO_CAROUSEL_EDIT,
  APRENDA_INVESTIR_VIDEO_CAROUSEL_MODERATE,
  APRENDA_INVESTIR_TRAIL,
  APRENDA_INVESTIR_TRAIL_CREATE,
  APRENDA_INVESTIR_TRAIL_EDIT,
  APRENDA_INVESTIR_TRAIL_MODERATE,
  APRENDA_INVESTIR_BANNER,
  APRENDA_INVESTIR_BANNER_CREATE,
  APRENDA_INVESTIR_BANNER_EDIT,
  APRENDA_INVESTIR_BANNER_MODERATE,
  AUTHOR,
  AUTHOR_CREATE,
  AUTHOR_EDIT,
  AUTHOR_MODERATE,
  AUTHOR_ORDER,
  CATEGORY,
  CATEGORY_CREATE,
  CATEGORY_EDIT,
  CATEGORY_MODERATE,
  CATEGORY_ORDER,
  EVENT,
  EVENT_EDIT,
  EVENT_CREATE,
  EVENT_MODERATE,
  FILE_PACKAGE,
  FILE_PACKAGE_CREATE,
  FILE_PACKAGE_EDIT,
  FILE_PACKAGE_MODERATE,
  LIVE,
  LIVE_EDIT,
  LIVE_CREATE,
  LIVE_MODERATE,
  NEWS,
  NEWS_CREATE,
  NEWS_EDIT,
  NEWS_MODERATE,
  QUOTE,
  QUOTE_CREATE,
  QUOTE_EDIT,
  QUOTE_MODERATE,
  QUOTATION,
  QUOTATION_CREATE,
  QUOTATION_EDIT,
  QUOTATION_MODERATE,
  SUBCATEGORY,
  SUBCATEGORY_CREATE,
  SUBCATEGORY_EDIT,
  SUBCATEGORY_MODERATE,
  SUBCATEGORY_ORDER,
  APRENDA_INVESTIR_TRAIL_ORDER,
  HOME,
  HOME_CONFIG,
  HOME_CONFIG_CREATE,
  HOME_CONFIG_EDIT,
  HOME_CONFIG_MODERATE,
  HOME_BANNER,
  HOME_BANNER_CREATE,
  HOME_BANNER_EDIT,
  HOME_BANNER_MODERATE,
  HOME_SOCIAL,
  HOME_SOCIAL_CREATE,
  HOME_SOCIAL_EDIT,
  HOME_SOCIAL_MODERATE,
} from '../../config/routes'

export default function Routes() {
  const loginData =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? {
          path: SAML,
          component: LoginSaml,
        }
      : {
          path: LOGIN,
          component: Login,
        }

  return (
    <Switch>
      <Route exact path={loginData.path} component={loginData.component} />
      <ProtectedRoute exact path={BASE} component={Home} />
      <ProtectedRoute exact path={ALERT} component={AlertList} />
      <ProtectedRoute exact path={ALERT_CREATE} component={AlertForm} />
      <ProtectedRoute exact path={ALERT_EDIT} component={AlertForm} />
      <ProtectedRoute exact path={ALERT_MODERATE} component={AlertModerate} />
      <ProtectedRoute exact path={APRENDA_INVESTIR} component={AprendaInvestir} />
      <ProtectedRoute exact path={HOME} component={HomeIndex} />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_VIDEO_CAROUSEL}
        component={AprendaInvestirVideoCarouselList}
      />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_VIDEO_CAROUSEL_CREATE}
        component={AprendaInvestirVideoCarouselForm}
      />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_VIDEO_CAROUSEL_EDIT}
        component={AprendaInvestirVideoCarouselForm}
      />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_VIDEO_CAROUSEL_MODERATE}
        component={AprendaInvestirVideoCarouselModerate}
      />
      <ProtectedRoute exact path={APRENDA_INVESTIR_TRAIL} component={AprendaInvestirTrailList} />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_TRAIL_ORDER}
        component={AprendaInvestirTrailOrder}
      />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_TRAIL_CREATE}
        component={AprendaInvestirTrailForm}
      />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_TRAIL_EDIT}
        component={AprendaInvestirTrailForm}
      />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_TRAIL_MODERATE}
        component={AprendaInvestirTrailModerate}
      />
      <ProtectedRoute exact path={APRENDA_INVESTIR_BANNER} component={AprendaInvestirBannerList} />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_BANNER_CREATE}
        component={AprendaInvestirBannerForm}
      />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_BANNER_EDIT}
        component={AprendaInvestirBannerForm}
      />
      <ProtectedRoute
        exact
        path={APRENDA_INVESTIR_BANNER_MODERATE}
        component={AprendaInvestirBannerModerate}
      />
      <ProtectedRoute exact path={AUTHOR} component={AuthorList} />
      <ProtectedRoute exact path={AUTHOR_ORDER} component={AuthorOrder} />
      <ProtectedRoute exact path={AUTHOR_CREATE} component={AuthorForm} />
      <ProtectedRoute exact path={AUTHOR_EDIT} component={AuthorForm} />
      <ProtectedRoute exact path={AUTHOR_MODERATE} component={AuthorModerate} />
      <ProtectedRoute exact path={CATEGORY} component={CategoryList} />
      <ProtectedRoute exact path={CATEGORY_ORDER} component={CategoryOrder} />
      <ProtectedRoute exact path={CATEGORY_CREATE} component={CategoryForm} />
      <ProtectedRoute exact path={CATEGORY_EDIT} component={CategoryForm} />
      <ProtectedRoute exact path={CATEGORY_MODERATE} component={CategoryModerate} />
      <ProtectedRoute exact path={EVENT} component={EventList} />
      <ProtectedRoute exact path={EVENT_CREATE} component={EventForm} />
      <ProtectedRoute exact path={EVENT_EDIT} component={EventForm} />
      <ProtectedRoute exact path={EVENT_MODERATE} component={EventModerate} />
      <ProtectedRoute exact path={FILE_PACKAGE} component={FilePackageList} />
      <ProtectedRoute exact path={FILE_PACKAGE_CREATE} component={FilePackageForm} />
      <ProtectedRoute exact path={FILE_PACKAGE_EDIT} component={FilePackageForm} />
      <ProtectedRoute exact path={FILE_PACKAGE_MODERATE} component={FilePackageModerate} />
      <ProtectedRoute exact path={HOME_BANNER} component={HomeBannerList} />
      <ProtectedRoute exact path={HOME_BANNER_CREATE} component={HomeBannerForm} />
      <ProtectedRoute exact path={HOME_BANNER_EDIT} component={HomeBannerForm} />
      <ProtectedRoute exact path={HOME_BANNER_MODERATE} component={HomeBannerModerate} />
      <ProtectedRoute exact path={HOME_CONFIG} component={HomeConfigList} />
      <ProtectedRoute exact path={HOME_CONFIG_CREATE} component={HomeConfigForm} />
      <ProtectedRoute exact path={HOME_CONFIG_EDIT} component={HomeConfigForm} />
      <ProtectedRoute exact path={HOME_CONFIG_MODERATE} component={HomeConfigModerate} />
      <ProtectedRoute exact path={HOME_SOCIAL} component={HomeSocialList} />
      <ProtectedRoute exact path={HOME_SOCIAL_CREATE} component={HomeSocialForm} />
      <ProtectedRoute exact path={HOME_SOCIAL_EDIT} component={HomeSocialForm} />
      <ProtectedRoute exact path={HOME_SOCIAL_MODERATE} component={HomeSocialModerate} />
      <ProtectedRoute exact path={LIVE} component={LiveList} />
      <ProtectedRoute exact path={LIVE_CREATE} component={LiveForm} />
      <ProtectedRoute exact path={LIVE_EDIT} component={LiveForm} />
      <ProtectedRoute exact path={LIVE_MODERATE} component={LiveModerate} />
      <ProtectedRoute exact path={NEWS} component={NewsList} />
      <ProtectedRoute exact path={NEWS_CREATE} component={NewsForm} />
      <ProtectedRoute exact path={NEWS_EDIT} component={NewsForm} />
      <ProtectedRoute exact path={NEWS_MODERATE} component={NewsModerate} />
      <ProtectedRoute exact path={QUOTE} component={QuoteList} />
      <ProtectedRoute exact path={QUOTE_CREATE} component={QuoteForm} />
      <ProtectedRoute exact path={QUOTE_EDIT} component={QuoteForm} />
      <ProtectedRoute exact path={QUOTE_MODERATE} component={QuoteModerate} />
      <ProtectedRoute exact path={QUOTATION} component={QuotationList} />
      <ProtectedRoute exact path={QUOTATION_CREATE} component={QuotationForm} />
      <ProtectedRoute exact path={QUOTATION_EDIT} component={QuotationForm} />
      <ProtectedRoute exact path={QUOTATION_MODERATE} component={QuotationModerate} />
      <ProtectedRoute exact path={SUBCATEGORY} component={SubcategoryList} />
      <ProtectedRoute exact path={SUBCATEGORY_ORDER} component={SubcategoryOrder} />
      <ProtectedRoute exact path={SUBCATEGORY_CREATE} component={SubcategoryForm} />
      <ProtectedRoute exact path={SUBCATEGORY_EDIT} component={SubcategoryForm} />
      <ProtectedRoute exact path={SUBCATEGORY_MODERATE} component={SubcategoryModerate} />
      <ProtectedRoute component={NotFound} />
    </Switch>
  )
}
