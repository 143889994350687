import React from 'react'

import { Button } from '@inter/inter-ui'

import Modal from '../Modal'
import ErrorImage from '../../assets/images/error.svg'
import * as S from './styles'

export default function ErrorModal({ isOpen, closeModal }) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal} width="750px">
      <S.ErrorContainer>
        <S.ErrorImageContainer>
          <S.ErrorImage src={ErrorImage} alt="Não foi possível concluir a solicitação" />
        </S.ErrorImageContainer>
        <S.ErrorTitle>Ops... Algo deu errado.</S.ErrorTitle>
        <S.ErrorDescription>Não foi possível concluir a solicitação.</S.ErrorDescription>
        <S.ErrorDescription>Tente novamente mais tarde.</S.ErrorDescription>
        <Button variant="primary" onClick={closeModal}>
          Voltar
        </Button>
      </S.ErrorContainer>
    </Modal>
  )
}
