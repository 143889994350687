import React from 'react'

import DefaultForm from '../../../components/DefaultForm'
import { HOME_SOCIAL_CONFIG_URL, HOME_SOCIAL_URL } from '../../../config/urls'
import { BASE, HOME, HOME_SOCIAL } from '../../../config/routes'
import Visualization from './Visualization'

export default function SocialForm() {
  return (
    <DefaultForm
      title="Redes Sociais"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Home', route: HOME },
        { title: 'Redes Sociais', route: HOME_SOCIAL },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={HOME_SOCIAL_CONFIG_URL}
      mainRoute={HOME_SOCIAL}
      buildForm={(data) => buildForm(data)}
      submitOptions={{
        method: 'POST',
        url: HOME_SOCIAL_URL,
      }}
      renderVisualization={(socials) => <Visualization socials={socials} />}
    />
  )
}

function validatePosts(posts) {
  let errorMessage = ''
  posts.forEach((post) => {
    const { content } = post

    if (content.title.length === 0) {
      errorMessage = 'O título deve ser informado.'
    }

    if (content.socialMedia.length === 0) {
      errorMessage = 'A rede social deve ser informada.'
    }

    if (content.data.length === 0) {
      errorMessage = 'Pelo menos um post deve ser informado.'
    } else {
      content.data.forEach((item) => {
        if (item.link.length === 0) {
          errorMessage = 'O post deve ter um link.'
        }
        if (item.url.length === 0) {
          errorMessage = 'O post deve ter uma imagem.'
        }
      })
    }
  })

  return errorMessage
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'posts':
        return {
          ...field,
          type: 'social-posts-editor',
          rules: {
            ...field.rules,
            validate: {
              validPosts: (posts) => {
                const message = validatePosts(posts)
                return message.length === 0 || message
              },
            },
          },
        }

      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
