import React from 'react'

import Moderation from '../../../components/Moderation'
import { APRENDA_INVESTIR_BANNER_URL } from '../../../config/urls'
import { BASE, APRENDA_INVESTIR_BANNER, APRENDA_INVESTIR } from '../../../config/routes'
import Visualization from './Visualization'

export default function BannerModeration() {
  return (
    <Moderation
      pageTitle="Banner"
      mainUrl={APRENDA_INVESTIR_BANNER_URL}
      mainRoute={APRENDA_INVESTIR_BANNER}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Banner' },
      ]}
      renderVisualization={(banner) => <Visualization banner={banner} />}
    />
  )
}
