import styled from 'styled-components'

export const LinkDecoratorWrapper = styled.span`
  position: relative;
  text-decoration: underline;
`

export const LinkDecoratorAnchor = styled.a`
  cursor: text;
`
