import styled from 'styled-components'

import { grayscale, white } from '../../styles/colors'

export const Container = styled.div`
  color: ${grayscale[500]};
  font-family: Sora;
  font-size: 18px;
  margin: 0px auto 0px auto;
`
export const Title = styled.h2`
  font-size: 56px;
  font-weight: 600;
`

export const AuthorContainer = styled.div`
  display: flex;
  margin: 32px 0 50px 0;
`

export const TextContainer = styled.div`
  margin: 32px 0;
`

export const AuthorName = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
`
export const ProfilePicture = styled.img`
  align-self: flex-start;
  border-radius: 50%;
  height: 45px;
  margin: 1px 20px 0 0;
  min-height: 45px;
  min-width: 45px;
`

export const Summary = styled.div`
  border: 1px solid ${grayscale[200]};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 40px;
`
export const SummaryTitle = styled.h4`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 24px;
`

export const NewsContainer = styled.div`
  margin: 64px 0;
`

export const NewsImage = styled.img`
  height: 100%;
  width: 100%;
`

export const NewsInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const NewsStatisticContainer = styled.div`
  align-items: center;
  display: flex;
`

export const NewsStatistic = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 0 20px 0 9px;
`

export const ContentBlocks = styled.div`
  margin: 64px 0;
`

export const AnchorContainer = styled.div`
  margin: 32px 0;
`

export const TextTitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 24px;
`

export const Text = styled.div`
  color: ${grayscale[400]};
  line-height: 26px;
`

export const Citation = styled.div`
  border: 1px dashed ${grayscale[200]};
  border-left: none;
  border-right: none;
  margin: 64px 0;
  padding: 40px 0;
`

export const IconContainer = styled.div`
  margin: 5px 30px 0 0;
`

export const CitationTitle = styled.h3`
  font: 500 18px Inter;
  line-height: 26px;
  margin-bottom: 24px;
  word-break: break-all;
`

export const CitationAuthor = styled.p`
  font: 400 18px Inter;
  color: ${grayscale[400]};
  margin: 0;
`

export const Tags = styled.div`
  display: flex;
  flex-direction: column;
  margin: 64px 0;
`

export const TagTitle = styled.p`
  color: ${grayscale[400]};
  font-size: 14px;
  margin-bottom: 8px;
`

export const Tag = styled.span`
  background-color: ${grayscale[500]};
  border-radius: 4px;
  color: ${white};
  font: 700 12px Inter;
  padding: 2px 4px;
  margin-right: 8px;
`
export const Video = styled.figure`
  padding: 1.5rem 0;

  iframe {
    min-height: 433px;
    width: 100%;
  }
`
export const VideoCaption = styled.figcaption`
  background: ${grayscale[100]};
  color: ${grayscale[400]};
  display: block;
  font-size: 14px;
  line-height: 17px;
  padding: 0.5rem 1rem;
  width: 100%;
`

export const Image = styled.figure`
  padding: 1.5rem 0;

  img {
    display: block;
    width: 100%;
  }
`

export const ImageCaption = styled.figcaption`
  background: ${grayscale[100]};
  color: ${grayscale[400]};
  display: block;
  font-size: 14px;
  line-height: 17px;
  padding: 0.5rem 1rem;
  width: 100%;
`

export const TweetContainer = styled.div`
  padding: 1.5rem 0;
  width: 50%;
`

export const InstagramContainer = styled.div`
  padding: 1.5rem 0;
  width: 50%;
`

export const FacebookContainer = styled.div`
  padding: 1.5rem 0;
  width: 50%;
`

export const AudioContainer = styled.div`
  border: 1px solid ${grayscale[200]};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  max-width: 100%;
  padding: 24px;

  audio {
    margin-top: 10px;
    width: 100%;
  }
  audio::-webkit-media-controls-panel {
    background-color: ${white};
  }
  audio::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
`
