import { EditorState, Modifier, RichUtils } from 'draft-js'

export default function toggleLink(
  editorState,
  handleChange,
  selection,
  { action, title, target: url, targetOption },
) {
  if (action === 'link') {
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', { url, targetOption })
      .getLastCreatedEntityKey()

    let contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      title,
      editorState.getCurrentInlineStyle(),
      entityKey,
    )
    let updatedEditorState = EditorState.push(editorState, contentState, 'insert-characters')

    const anchorOffset = selection.get('anchorOffset') + title.length
    const updatedSelection = updatedEditorState.getSelection().merge({
      anchorOffset,
      focusOffset: anchorOffset,
    })

    updatedEditorState = EditorState.acceptSelection(updatedEditorState, updatedSelection)
    contentState = Modifier.insertText(
      updatedEditorState.getCurrentContent(),
      updatedSelection,
      '',
      updatedEditorState.getCurrentInlineStyle(),
    )

    handleChange(EditorState.push(updatedEditorState, contentState, 'insert-characters'))
  } else {
    handleChange(RichUtils.toggleLink(editorState, selection, null))
  }
}
