import React from 'react'

import * as S from './style'

export default function Card({ title, icon, iconOrangeDs = false }) {
  return (
    <S.CardOption iconOrangeDs={iconOrangeDs}>
      {iconOrangeDs ? (
        <S.IconOrangeDsWrapper>{icon}</S.IconOrangeDsWrapper>
      ) : (
        <S.IconWrapper>{icon}</S.IconWrapper>
      )}

      <S.TitleWrapper>{title}</S.TitleWrapper>
    </S.CardOption>
  )
}
