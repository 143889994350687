import React, { Suspense } from 'react'

import { SelectNative } from '@inter/inter-ui'

import { orange } from '../../styles/colors'
import { FormInputLabel, FormInputInfoText } from '../Form/styles'
import * as S from './styles'

export default function IconSelector({ icon, setIcon, options, label, error, infoText }) {
  function loadIcon(name) {
    return React.lazy(() =>
      import(`inter-frontend-svgs/lib/orangeds/MD/${name}.js`).catch(() => null),
    )
  }

  function renderIcon(val) {
    const Icon = loadIcon(val)
    return (
      <Suspense fallback={<S.FallbackIcon>{val}</S.FallbackIcon>}>
        <Icon color={orange[500]} style={{ margin: '10px' }} />
      </Suspense>
    )
  }

  return (
    <S.SelectContainer>
      <FormInputLabel error={error}>{label}</FormInputLabel>
      <S.ItemsContainer>
        <SelectNative
          options={options}
          emptyOptionText="Selecione um ícone"
          value={icon}
          iconLeft={icon && icon.length > 0 && renderIcon(icon)}
          onChange={(e) => setIcon(e.target.value)}
        />
      </S.ItemsContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.SelectContainer>
  )
}
