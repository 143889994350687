import styled from 'styled-components'

import { white } from '../../styles/colors'

export const VisualizationContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

export const Title = styled.div`
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 10px;
  width: 100%;
`

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  width: 100%;
`

export const File = styled.div`
  background-color: ${white};
  border: transparent;
  padding: 10px;
`

export const IconWrapper = styled.div`
  float: left;
  width: 50px;
`
export const TextWrapper = styled.div`
  width: calc(100% - 50px);
`
