import React from 'react'

import { Input } from '@inter/inter-ui'

import { FormInputLabel } from '../../../../components/Form/styles'
import * as S from '../styles'

export default function FacebookEditor({ content, changeContent }) {
  const { url } = content

  return (
    <S.BlockContainer>
      <S.BlockTitle>Post do Facebook</S.BlockTitle>
      <S.InputContainer>
        <FormInputLabel>URL:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe a URL"
          value={url}
          onChange={(e) => {
            changeContent({
              ...content,
              url: e.target.value,
            })
          }}
          style={{
            width: '100%',
          }}
        />
        <S.BlockInfo>
          Ex: &quot;https://www.facebook.com/20531316728/posts/10154009990506729/&quot;
        </S.BlockInfo>
      </S.InputContainer>
    </S.BlockContainer>
  )
}
