import styled, { css } from 'styled-components'

import BgLogin from '../../assets/images/bg-login.png'
import { orange, white } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'
import { container } from '../../components/Layout/styles'

export const LoginBackground = styled.div`
  background: url(${BgLogin}) no-repeat top center;
  background-size: cover;
  min-height: 100%;
  position: absolute;
  width: 100%;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
`

export const LoginContainer = styled.div`
  ${container}
  height: 100vh;
`

export const LoginHeader = styled.div`
  position: relative;
  padding: 2rem 0;

  .icon {
    fill: ${white};
  }
`

export const LoginBody = styled.div`
  align-items: center;
  color: ${white};
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 280px;
  position: relative;

  @media (max-width: ${breakpoints.md}) {
    margin-top: 140px;
  }
`

const section = css`
  color: ${orange[500]};
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    flex: 0 0 50%;
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 3rem;
  }
`

export const LoginSection = styled.section`
  ${section}
`

export const LoginTitle = styled.h1`
  font-size: 38px;
`

export const LoginDescription = styled.p`
  font-size: 20px;
  margin-bottom: 0;
`

export const LoginCard = styled.section`
  background: ${white};
  border-radius: 8px;
  padding: 2rem;
`
