import styled from 'styled-components'

import { orange } from '../../styles/colors'

export const VisualizationContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

export const TitleContainer = styled.div`
  align-items: center;
  display: inline-flex;
`

export const Icon = styled.div`
  margin: 2px;
  position: relative;
  top: -8px;
`

export const Title = styled.p`
  font-family: Sora;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`

export const Subtitle = styled.p`
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
`

export const Order = styled.div`
  color: ${orange[400]};
  font-family: Sora;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`

export const Text = styled.p`
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`
