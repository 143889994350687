import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { SUBCATEGORY_URL, SUBCATEGORY_CONFIG_URL } from '../../config/urls'
import { BASE, SUBCATEGORY } from '../../config/routes'

export default function SubcategoryForm() {
  return (
    <DefaultForm
      pageTitle="Subcategorias"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Subcategorias', route: SUBCATEGORY },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={SUBCATEGORY_CONFIG_URL}
      mainRoute={SUBCATEGORY}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: SUBCATEGORY_URL }}
      renderVisualization={(subcategory) => <Visualization subcategory={subcategory} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }
      case 'name':
        return {
          ...field,
          type: 'text',
        }
      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
