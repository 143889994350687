/* eslint-disable */
import React from 'react'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import Chevron from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'

import TextEditor from './TextEditor'
import CitationEditor from './CitationEditor'
import VideoEditor from './VideoEditor'
import ImageEditor from './ImageEditor'
import TweetEditor from './TweetEditor'
import InstagramEditor from './InstagramEditor'
import FacebookEditor from './FacebookEditor'
import AudioEditor from './AudioEditor'
import AnchorEditor from './AnchorEditor'
import FileEditor from '../../../components/FileUploader'
import * as S from './styles'
import { FormInputLabel, FormInputInfoText } from '../../../components/Form/styles'

function NewsEditor({ blocks = [], setBlocks, error, infoText, folder, audioFolder }, ref) {
  const [currentKey, setCurrentKey] = React.useState(0)

  React.useEffect(() => {
    setBlocks(blocks.map((block, index) => ({ ...block, key: `news-editor-${index}` })))
    setCurrentKey(blocks.length)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const addBlock = React.useCallback(
    (blockType) => {
      setBlocks([...blocks, getBlockStructure(blockType, currentKey + 1)])
      setCurrentKey(currentKey + 1)
    },
    [blocks, setBlocks],
  )

  const removeBlock = React.useCallback(
    (index) => {
      const updatedBlocks = [...blocks]
      updatedBlocks.splice(index, 1)

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  const changePosition = React.useCallback(
    (indexFrom, indexTo) => {
      if (indexTo < 0 || indexTo > blocks.length - 1) return

      const updatedBlocks = [...blocks]
      const aux = updatedBlocks[indexFrom]

      updatedBlocks[indexFrom] = updatedBlocks[indexTo]
      updatedBlocks[indexTo] = aux

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  const changeContent = React.useCallback(
    (index, content) => {
      const updatedBlocks = [...blocks]
      updatedBlocks[index].content = content

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  function renderEditor(block, index) {
    const { key, type, content } = block

    switch (type) {
      case 'citation':
        return (
          <CitationEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
          />
        )
      case 'image':
        return (
          <ImageEditor
            content={content}
            blockKey={key}
            folder={folder}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
          />
        )
      case 'text':
        return (
          <TextEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
          />
        )
      case 'video':
        return (
          <VideoEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
          />
        )
      case 'tweet':
        return (
          <TweetEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
          />
        )
      case 'file':
        return (
          <FileEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
            folder="news/files"
            enableCover={false}
          />
        )
      case 'instagram':
        return (
          <InstagramEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
          />
        )
      case 'facebook':
        return (
          <FacebookEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
          />
        )
      case 'audio':
        return (
          <AudioEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
            folder={audioFolder}
          />
        )
      case 'anchor':
        return (
          <AnchorEditor
            content={content}
            changeContent={(newContent) => {
              changeContent(index, newContent)
            }}
          />
        )
      default:
        return <div>Editor indisponível</div>
    }
  }

  function renderCard(block, index) {
    return (
      <S.CardContainer key={block.key}>
        {renderEditor(block, index)}
        <S.CardControls>
          <button type="button" className="btn" onClick={() => removeBlock(index)}>
            <Close height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index - 1)}>
            <Chevron height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index + 1)}>
            <Chevron height={20} rotate={180} color="grayscale--400" />
          </button>
        </S.CardControls>
      </S.CardContainer>
    )
  }

  return (
    <S.EditorContainer ref={ref}>
      <FormInputLabel error={error}>Conteúdo</FormInputLabel>
      <S.ContentContainer>
        <S.BlocksContainer>
          {blocks.length > 0 ? (
            blocks.map((block, index) => renderCard(block, index))
          ) : (
            <S.InfoText>
              <p> Por favor adicione novos blocos ... </p>
            </S.InfoText>
          )}
        </S.BlocksContainer>
        <S.ContentControls>
          <button type="button" onClick={() => addBlock('citation')}>
            + Citação
          </button>
          <button type="button" onClick={() => addBlock('image')}>
            + Imagem
          </button>
          <button type="button" onClick={() => addBlock('text')}>
            + Texto
          </button>
          <button type="button" onClick={() => addBlock('video')}>
            + Vídeo
          </button>
          <button type="button" onClick={() => addBlock('tweet')}>
            + Tweet
          </button>
          <button type="button" onClick={() => addBlock('file')}>
            + Arquivo
          </button>
          <button type="button" onClick={() => addBlock('instagram')}>
            + Instagram
          </button>
          <button type="button" onClick={() => addBlock('facebook')}>
            + Facebook
          </button>
          <button type="button" onClick={() => addBlock('audio')}>
            + Áudio
          </button>
          <button type="button" onClick={() => addBlock('anchor')}>
            + Anchor
          </button>
        </S.ContentControls>
      </S.ContentContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.EditorContainer>
  )
}

function getBlockStructure(blockType, currentKey) {
  switch (blockType) {
    case 'citation':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { author: '', text: '' },
      }
    case 'image':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { url: '', alt: '', caption: '', maxWidth: '', zoom_enabled: false },
      }
    case 'text':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { title: '', text: '' },
      }
    case 'video':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { url: '', caption: '', maxWidth: '' },
      }
    case 'tweet':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { url: '' },
      }
    case 'file':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { description: '', file: {} },
      }
    case 'instagram':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { url: '', htmlContent: '' },
      }
    case 'facebook':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { url: '' },
      }
    case 'audio':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { description: '', file: {} },
      }
    case 'anchor':
      return {
        key: `news-editor-${currentKey}`,
        type: blockType,
        content: { url: '', title: '' },
      }
    default:
      return null
  }
}

export default React.forwardRef(NewsEditor)
