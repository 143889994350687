import styled from 'styled-components'

import breakpoints from '../../styles/breakpoints'

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  text-align: center;
  width: 100%;

  button {
    margin-top: 2rem;
    min-width: 100px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 7rem 0;
  }
`

export const ErrorImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const ErrorImage = styled.img`
  height: 250px;
`

export const ErrorTitle = styled.h3`
  font-size: 30px;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
`

export const ErrorDescription = styled.p``
