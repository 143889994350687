import styled from 'styled-components'

import { grayscale } from '../../styles/colors'

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  width: 100%;
`

export const DatePickerBlock = styled.div`
  background-color: ${grayscale[100]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
  margin-top: 4px;
  padding: 8px 16px;
  input {
    background-color: ${grayscale[100]};
  }
`
