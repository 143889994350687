import React, { useState } from 'react'

import ReadingTimeIcon from 'inter-frontend-svgs/lib/orangeds/MD/pending'
import PublishDateIcon from 'inter-frontend-svgs/lib/orangeds/MD/agenda'
import TweetEmbed from 'react-tweet-embed'
import { FacebookProvider, EmbeddedPost } from 'react-facebook'
import InstagramEmbed from 'react-instagram-embed'

import QuoteIcon from '../../assets/images/quote.svg'
import { request } from '../../helpers'
import { AUTHOR_URL } from '../../config/urls'
import * as S from './styles'

export default function NewsVisualization({ news }) {
  const [author, setAuthor] = useState(null)

  const { authorId, title, summary, homeImage, contentBlocks, tags, publishedAt, readingTime } =
    news

  React.useEffect(() => {
    request({
      method: 'GET',
      url: `${AUTHOR_URL}?uuid=${authorId}&status=PUBLISHED`,
    }).then((response) => {
      setAuthor(response[0])
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      {renderAuthor(author, formatDate(publishedAt), readingTime)}
      <div>
        {summary && summary.length > 0 && (
          <S.Summary>
            <S.SummaryTitle>Resumo</S.SummaryTitle>
            {summary}
          </S.Summary>
        )}
        <S.NewsContainer>
          {homeImage && homeImage.url && (
            <S.NewsImage src={homeImage.url} alt="Imagem de notícia" />
          )}
        </S.NewsContainer>
      </div>
      <S.ContentBlocks>{generateBlocks(contentBlocks)}</S.ContentBlocks>
      <S.Tags>
        <S.TagTitle>Tags: </S.TagTitle>
        <div>{tags && tags.map((tag) => <S.Tag> {tag} </S.Tag>)}</div>
      </S.Tags>
    </S.Container>
  )
}

function generateBlocks(blocks) {
  return blocks.map((block, key) => {
    const { type } = block
    const blockKey = `${key}-${type}`

    switch (type) {
      case 'text':
        return (
          <S.TextContainer key={blockKey}>
            <S.TextTitle>{block.content.title}</S.TextTitle>
            <S.Text dangerouslySetInnerHTML={{ __html: block.content.text }} />
          </S.TextContainer>
        )
      case 'citation':
        return (
          <S.Citation>
            <S.IconContainer>
              <img src={QuoteIcon} alt="Ícone de citação" />
            </S.IconContainer>
            <div>
              <S.CitationTitle>{block.content.text}</S.CitationTitle>
              <S.CitationAuthor>{block.content.author}</S.CitationAuthor>
            </div>
          </S.Citation>
        )
      case 'image':
        return (
          <S.Image key={blockKey} style={{ maxWidth: block.content.maxWidth, margin: '0 auto' }}>
            <img src={block.content.url} alt={block.content.alt} />
            {block.content.caption !== '' && (
              <S.ImageCaption>{block.content.caption}</S.ImageCaption>
            )}
          </S.Image>
        )
      case 'video':
        return (
          <S.Video key={blockKey} style={{ maxWidth: block.content.maxWidth, margin: '0 auto' }}>
            <iframe
              id="ytplayer"
              title={block.content.caption}
              src={block.content.url}
              frameBorder="0"
              allow="autoplay"
              allowFullScreen
            />
            {block.content.caption !== '' && (
              <S.VideoCaption>{block.content.caption}</S.VideoCaption>
            )}
          </S.Video>
        )
      case 'tweet':
        return (
          <S.TweetContainer key={blockKey} style={{ maxWidth: '100%', margin: '0 auto' }}>
            <TweetEmbed
              id={block.content.url.split('/').at(-1)}
              placeholder="Carregando..."
              options={{ theme: 'dark', align: 'center' }}
            />
          </S.TweetContainer>
        )
      case 'instagram':
        return (
          <S.InstagramContainer key={blockKey} style={{ maxWidth: '100%', margin: '0 auto' }}>
            <InstagramEmbed
              url={block.content.url}
              clientAccessToken="1234332290345952|ca119ce6c995c4e4c1abcc600b60c292"
              maxWidth="100%"
              hideCaption={false}
              containerTagName="div"
              protocol=""
              injectScript
              onFailure={() => undefined}
            />
          </S.InstagramContainer>
        )
      case 'facebook':
        return (
          <S.FacebookContainer key={blockKey} style={{ maxWidth: '100%', margin: '0 auto' }}>
            <FacebookProvider appId="1234332290345952">
              <EmbeddedPost href={block.content.url} width="600" />
            </FacebookProvider>
          </S.FacebookContainer>
        )
      case 'audio':
        return (
          <S.AudioContainer key={blockKey}>
            <div>{block.content.description}</div>
            <audio
              id="player"
              controls="controls"
              controlsList="nodownload noplaybackrate"
              src={block.content.file.url}
            >
              <track kind="captions" />
            </audio>
          </S.AudioContainer>
        )
      case 'anchor':
        return (
          <S.AnchorContainer key={blockKey}>
            <S.TextTitle>{block.content.title}</S.TextTitle>
            <div>
              <iframe
                id="anchorPlayer"
                title="anchor"
                src={block.content.url}
                height="180px"
                width="100%"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
              />
            </div>
          </S.AnchorContainer>
        )
      default:
        return <div />
    }
  })
}

function formatDate(publishDate) {
  const d = new Date(publishDate).toString()
  return `${d.substring(8, 10)}/${d.substring(4, 7).toLowerCase()}`
}

function renderAuthor(author, publishDate, readingTime) {
  return (
    author && (
      <S.AuthorContainer>
        <S.ProfilePicture
          src={author.photo && author.photo.url}
          alt={author.photo && author.photo.alt}
        />
        <S.NewsInfo>
          <S.AuthorName>{author.name}</S.AuthorName>
          <S.NewsStatisticContainer>
            <PublishDateIcon color="#6A6C72" height={14} width={14} />
            <S.NewsStatistic>Publicado {publishDate} </S.NewsStatistic>
            <ReadingTimeIcon color="#6A6C72" height={14} width={14} />
            <S.NewsStatistic>{readingTime || '0'} min de leitura</S.NewsStatistic>
          </S.NewsStatisticContainer>
        </S.NewsInfo>
      </S.AuthorContainer>
    )
  )
}
