import styled from 'styled-components'

import { actionButtons } from '../ConfirmModal/styles'

export const ModerationContainer = styled.div`
  padding: 16px 0 80px 0;
`

export const ModerationActionButtons = styled.div`
  bottom: 3rem;
  left: calc(50% - 100px - 1rem);
  position: absolute;

  ${actionButtons}
`
