import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { useAuth } from '../../hooks'
import Layout from '../Layout'
import { LOGIN, SAML } from '../../config/routes'

export default function ProtectedRoute({ children, component: Component, ...props }) {
  const { auth } = useAuth()

  const pathname =
    process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'staging'
      ? SAML
      : LOGIN

  return (
    <Route {...props}>
      {auth ? <Layout>{children || <Component />}</Layout> : <Redirect to={pathname} />}
    </Route>
  )
}
