import React from 'react'

import UploadIcon from 'inter-frontend-svgs/lib/layout/upload'
import ReactCrop from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'
import { Button } from '@inter/inter-ui'

import ConfirmModal from '../ConfirmModal'
import { request } from '../../helpers'
import { UPLOAD_URL } from '../../config/urls'
import * as S from './styles'

const MAXIMUM_FILE_SIZE_MB = 1

export default function SimpleUploader({
  folder,
  setUploadedFile,
  imageSize,
  maxWidth,
  hasDescriptionForm = false,
}) {
  const [uploading, setUploading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [imageSrc, setImageSrc] = React.useState(null)
  const [imageInfo, setImageInfo] = React.useState(null)
  const [crop, setCrop] = React.useState(
    imageSize && {
      aspect: imageSize.width / imageSize.height,
      width: imageSize.width,
      height: imageSize.height,
    },
  )
  const [modalConfig, setModalConfig] = React.useState({
    isOpen: false,
    message: '',
    inputOptions: null,
  })
  const [completedCrop, setCompletedCrop] = React.useState({})
  const imgRef = React.useRef(null)
  const previewCanvasRef = React.useRef(null)

  const onImageLoad = React.useCallback((img) => {
    imgRef.current = img
  }, [])

  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) return

    const image = imgRef.current
    const canvas = previewCanvasRef.current

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = completedCrop.width * pixelRatio
    canvas.height = completedCrop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height,
    )
  }, [completedCrop]) // eslint-disable-line

  function generateFile(canvas, imgCrop) {
    if (!canvas || !imgCrop) return null

    setUploading(true)
    setImageSrc(null)

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(new window.File([blob], imageInfo.name, { type: imageInfo.type }))
        },
        imageInfo.type,
        1,
      )
    })
  }

  function onUploadImage(e) {
    setError(null)
    if (e.target.files && e.target.files.length > 0) {
      setUploading(true)
      setImageInfo({
        name: e.target.files[0].name,
        type: e.target.files[0].type,
      })
      const reader = new window.FileReader()

      reader.addEventListener('load', () => {
        const image = new window.Image()
        image.src = reader.result

        image.addEventListener('load', () => {
          setUploading(false)

          if (image.width < imageSize.width || image.height < imageSize.height) {
            return setError(
              `O tamanho mínimo para a imagem é de ${imageSize.width}x${imageSize.height}.`,
            )
          }

          return setImageSrc(reader.result)
        })
      })

      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onChooseFile(file) {
    !uploading && setUploading(true)

    const formData = new window.FormData()

    formData.set('file', file)
    formData.set('folder', folder)
    imageSize && formData.set('imageSize', JSON.stringify(imageSize))
    maxWidth && formData.set('maxWidth', maxWidth)

    const fileSize = file && file.size / 1024 / 1024
    if (fileSize > MAXIMUM_FILE_SIZE_MB) {
      setUploading(false)
      setError('O tamanho da imagem não pode exceder 1MB.')
      return
    }

    const uploadFileCallback = () => {
      request({
        method: 'POST',
        url: UPLOAD_URL,
        body: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          setUploadedFile(response)
          setUploading(false)
        })
        .catch((err) => {
          setUploading(false)
          setError(
            err.response.data.statusCode === 400
              ? err.response.data.message
              : 'Um erro inesperado ocorreu! Por favor tente novamente.',
          )
        })
    }

    if (hasDescriptionForm) {
      setModalConfig({
        isOpen: true,
        message: 'Confirmar upload?',
        inputOptions: {
          placeholder: 'Informe uma descrição (metadado) para a imagem',
        },
        onConfirm: (description) => {
          setModalConfig({ ...modalConfig, isOpen: false })
          formData.set('description', description)
          uploadFileCallback()
        },
      })
    } else {
      uploadFileCallback()
    }
  }

  return (
    <>
      {imageSrc && imageSize ? (
        <>
          <S.CropContainer>
            <ReactCrop
              src={imageSrc}
              crop={crop}
              onImageLoaded={onImageLoad}
              onChange={(imgCrop) => setCrop(imgCrop)}
              onComplete={(imgCrop) => setCompletedCrop(imgCrop)}
              minWidth={imageSize.width}
              minHeight={imageSize.height}
              keepSelection
            />
          </S.CropContainer>
          <S.PreviewCanvas>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: Math.round(completedCrop.width || 0),
                height: Math.round(completedCrop.height || 0),
              }}
            />
          </S.PreviewCanvas>
          <S.CutButton>
            <Button
              fixedWidth
              variant="primary"
              onClick={async () => {
                onChooseFile(await generateFile(previewCanvasRef.current, completedCrop))
              }}
              disabled={!completedCrop.width || !completedCrop.height}
            >
              Recortar
            </Button>
          </S.CutButton>
        </>
      ) : (
        <>
          <S.UploaderContainer>
            <UploadIcon height={54} color="gray" />
            <Button fixedWidth variant="secondary">
              <S.UploaderInput
                id="file"
                name="file"
                type="file"
                accept="image/*"
                multiple={false}
                onChange={(e) => {
                  if (imageSize) {
                    return onUploadImage(e)
                  }
                  e.target.files && onChooseFile(e.target.files[0])
                  return false
                }}
              />
              Upload
            </Button>
            <p>Selecione um arquivo</p>
            <ConfirmModal
              isOpen={modalConfig.isOpen}
              message={modalConfig.message}
              onConfirm={modalConfig.onConfirm}
              inputOptions={modalConfig.inputOptions}
              closeModal={() => setModalConfig({ ...modalConfig, isOpen: false })}
            />
            <S.UploadErrorMessage> {error} </S.UploadErrorMessage>
          </S.UploaderContainer>
        </>
      )}
    </>
  )
}
