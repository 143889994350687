import React from 'react'

import { Link } from 'react-router-dom'
import Chevron from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'

import * as S from './styles'

export default function Breadcrumbs({ options }) {
  return <S.BreadcrumbsContainer>{renderBreadcrumbs(options)}</S.BreadcrumbsContainer>
}

function renderBreadcrumbs(options) {
  return options.map((option) => {
    const { title, route } = option

    return route ? (
      <React.Fragment key={option.route}>
        <Link to={route}>{title}</Link>
        <S.BreadcrumbsSeparator>
          <Chevron height={16} />
        </S.BreadcrumbsSeparator>
      </React.Fragment>
    ) : (
      title
    )
  })
}
