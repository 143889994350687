import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { FILE_PACKAGE_URL } from '../../config/urls'
import { BASE, FILE_PACKAGE } from '../../config/routes'

export default function FilePackageModeration() {
  return (
    <Moderation
      pageTitle="Arquivos"
      mainUrl={FILE_PACKAGE_URL}
      mainRoute={FILE_PACKAGE}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Arquivos', route: FILE_PACKAGE },
        { title: 'Moderação' },
      ]}
      renderVisualization={(filePackage) => <Visualization filePackage={filePackage} />}
    />
  )
}
