import React from 'react'

import { FormInputLabel, FormInputInfoText } from '../Form/styles'
import * as S from './styles'

export default function TemplatePicker({
  templates,
  selectedTemplate,
  setSelectedTemplate,
  label = 'Template',
  error,
  infoText,
}) {
  const renderTemplates = React.useCallback(
    () =>
      Object.entries(templates).map(([templateId, template]) => (
        <S.TemplateArea key={templateId}>
          <S.TemplateCard
            selected={selectedTemplate === templateId}
            onClick={() => setSelectedTemplate(templateId)}
          >
            <S.GridContainer gridTemplateRows={template.rows}>
              {template.gridAreas.map((gridArea) => (
                <S.GridCard key={gridArea} gridArea={gridArea} />
              ))}
            </S.GridContainer>
          </S.TemplateCard>
        </S.TemplateArea>
      )),
    [selectedTemplate, setSelectedTemplate, templates],
  )

  return (
    <S.TemplatePickerContainer>
      <FormInputLabel error={error}>{label}</FormInputLabel>
      <S.TemplateContainer>{renderTemplates(templates)}</S.TemplateContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.TemplatePickerContainer>
  )
}
