const BASE_URL = process.env.REACT_APP_API_URL

const USER_PERMISSION_URL = `${BASE_URL}/user-permission`
export const AUTH_URL = `${USER_PERMISSION_URL}/auth/local`
export const USER_ROLE_URL = `${USER_PERMISSION_URL}/role`
export const USER_URL = `${BASE_URL}/users-permissions/users/me`
export const AZURE_URL = `${BASE_URL}/user-permission/auth/saml/passport?authenticator=azure`
export const CONTENT_TYPES_URL = `${BASE_URL}/content-manager/content-types`

export const IMAGES_URL = `${BASE_URL}`
export const UPLOAD_URL = `${BASE_URL}/helper/upload`

export const ALERT_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::alert.alert/configuration`
export const ALERT_URL = `${BASE_URL}/alert`

export const CATEGORY_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::category.category/configuration`
export const CATEGORY_URL = `${BASE_URL}/category`

export const NEWS_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::news.news/configuration`
export const NEWS_URL = `${BASE_URL}/news`

export const AUTHOR_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::author.author/configuration`
export const AUTHOR_URL = `${BASE_URL}/author`

export const TAGS_URL = `${BASE_URL}/tag`

export const FILE_PACKAGE_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::file-package.file-package/configuration`
export const FILE_PACKAGE_URL = `${BASE_URL}/file-package`

export const SUBCATEGORY_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::subcategory.subcategory/configuration`
export const SUBCATEGORY_URL = `${BASE_URL}/subcategory`

export const APRENDA_INVESTIR_URL = `${BASE_URL}/aprenda-investir`
export const APRENDA_INVESTIR_VIDEO_CAROUSEL_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::aprenda-investir.video-carousel/configuration`
export const APRENDA_INVESTIR_VIDEO_CAROUSEL_URL = `${APRENDA_INVESTIR_URL}/video-carousel`
export const APRENDA_INVESTIR_TRAIL_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::aprenda-investir.trail/configuration`
export const APRENDA_INVESTIR_TRAIL_URL = `${APRENDA_INVESTIR_URL}/trail`
export const APRENDA_INVESTIR_BANNER_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::aprenda-investir.banner/configuration`
export const APRENDA_INVESTIR_BANNER_URL = `${APRENDA_INVESTIR_URL}/banner`

export const HOME_URL = `${BASE_URL}/home`
export const HOME_CONFIG_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::home.home-config/configuration`
export const HOME_CONFIG_URL = `${HOME_URL}/home-config`

export const HOME_BANNER_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::home.banner/configuration`
export const HOME_BANNER_URL = `${HOME_URL}/banner`

export const HOME_SOCIAL_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::home.social/configuration`
export const HOME_SOCIAL_URL = `${HOME_URL}/social`

export const QUOTE_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::quote.quote/configuration`
export const QUOTE_URL = `${BASE_URL}/quote`

export const QUOTATION_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::quotation.quotation/configuration`
export const QUOTATION_URL = `${BASE_URL}/quotation`

export const EVENT_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::event.event/configuration`
export const EVENT_URL = `${BASE_URL}/event`

export const LIVE_CONFIG_URL = `${CONTENT_TYPES_URL}/plugins::live.live/configuration`
export const LIVE_URL = `${BASE_URL}/live`
export const LIVE_ADDITIONAL_INFO_URL = `${LIVE_URL}/additional-info`
