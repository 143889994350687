import React from 'react'

import { useAuth } from '../../hooks'
import List from '../../components/List'
import { CATEGORY_CONFIG_URL, CATEGORY_URL } from '../../config/urls'
import { BASE, CATEGORY } from '../../config/routes'

export default function CategoryList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions.category.category.publish,
    }
  }
  return (
    <List
      pageTitle="Categorias"
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Categorias' }]}
      configUrl={CATEGORY_CONFIG_URL}
      baseUrl={CATEGORY_URL}
      baseRoute={CATEGORY}
      searchField="name"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        groupBy: ['uuid'],
      }}
      permissions={getPermissions()}
      showOrder
    />
  )
}
