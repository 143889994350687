import React from 'react'

import ol from '../../../assets/images/ol.svg'
import ul from '../../../assets/images/ul.svg'
import { EditorOption } from '../components'
import { toggleBlockType } from './handlers'
import { ControlImage } from './styles'

const BLOCK_TYPES = [
  {
    title: 'Lista Não Ordenada',
    style: 'unordered-list-item',
    children: <ControlImage src={ul} />,
  },
  {
    title: 'Lista Ordenada',
    style: 'ordered-list-item',
    children: <ControlImage src={ol} />,
  },
]

export default function BlockTypeControl({ editorState, handleChange }) {
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey())
    .getType()

  return BLOCK_TYPES.map(({ title, style, children }) => (
    <EditorOption
      key={style}
      title={title}
      active={style === blockType}
      onToggle={() => toggleBlockType(editorState, handleChange, style)}
    >
      {children}
    </EditorOption>
  ))
}
