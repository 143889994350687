import React from 'react'

import { Input } from '@inter/inter-ui'

import { FormInputLabel } from '../../../../components/Form/styles'
import * as S from '../styles'

export default function InstagramEditor({ content, changeContent }) {
  const { url } = content

  async function getHtmlContent(contentUrl) {
    const oembedUrl = `https://graph.facebook.com/v11.0/instagram_oembed?url=${contentUrl}&omitscript=true&access_token=1234332290345952|ca119ce6c995c4e4c1abcc600b60c292`
    return fetch(oembedUrl, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => (result.error ? '' : result.html))
  }
  return (
    <S.BlockContainer>
      <S.BlockTitle>Post do Instagram</S.BlockTitle>
      <S.InputContainer>
        <FormInputLabel>URL:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe a URL"
          value={url}
          onBlur={async (e) => {
            changeContent({
              ...content,
              url: e.target.value,
              htmlContent: await getHtmlContent(e.target.value),
            })
          }}
          style={{
            width: '100%',
          }}
        />
        <S.BlockInfo>Ex: &quot;https://www.instagram.com/p/CRJd7WZNbNg/&quot;</S.BlockInfo>
      </S.InputContainer>
    </S.BlockContainer>
  )
}
