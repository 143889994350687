import styled, { css } from 'styled-components'

import { grayscale, white } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

export const ModalArea = styled.div``

export const container = css`
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (min-width: ${breakpoints.sm}) {
    width: 510px;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 690px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 930px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 1110px;
  }

  @media (min-width: ${breakpoints.xxl}) {
    width: 1290px;
  }
`

export const Header = styled.header`
  ${container};
`

export const Main = styled.main`
  ${container}
`

export const LayoutHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 40px 0;

  .icon {
    fill: ${white};
  }

  a,
  button {
    &:hover {
      color: ${grayscale[200]};

      .icon {
        fill: ${grayscale[200]};
      }
    }
  }
`

export const LayoutCard = styled.div`
  background-color: ${white};
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
  min-height: calc(100vh - 97px);
  min-width: 320px;
  padding: 3rem;
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 60px;
    min-height: calc(100vh - 172px);
  }
`

export const HomeLink = styled.a``

export const LogoutButton = styled.button`
  align-items: center;
  background: transparent;
  color: ${white};
  display: flex;
  font-size: 18px;
  justify-content: space-between;

  svg {
    margin-left: 8px;
  }
`
