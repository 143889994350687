import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { FILE_PACKAGE_URL, FILE_PACKAGE_CONFIG_URL, CATEGORY_URL } from '../../config/urls'
import { BASE, FILE_PACKAGE } from '../../config/routes'

export default function FilePackageForm() {
  return (
    <DefaultForm
      pageTitle="Arquivos"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Arquivos', route: FILE_PACKAGE },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={FILE_PACKAGE_CONFIG_URL}
      mainRoute={FILE_PACKAGE}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: FILE_PACKAGE_URL }}
      renderVisualization={(filePackage) => <Visualization filePackage={filePackage} />}
    />
  )
}

function validateFiles(blocks) {
  let errorMessage = ''
  blocks.forEach((block) => {
    const { content } = block
    if (content.description.length === 0) {
      errorMessage = 'O arquivo deve ter uma descrição.'
    }
    if (Object.keys(content.file).length === 0) {
      errorMessage = 'O arquivo deve ser inserido.'
    }
    if (Object.keys(content.image).length === 0) {
      errorMessage = 'Uma imagem de capa deve ser informada.'
    }
  })

  return errorMessage
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'title':
        return {
          ...field,
          type: 'text',
        }
      case 'categoryId':
        return {
          ...field,
          type: 'select',
          size: '1/2',
          fetch: {
            url: `${CATEGORY_URL}?status=PUBLISHED`,
            callback: (data) =>
              data.map((category) => ({
                value: category.uuid,
                label: category.name,
              })),
          },
        }
      case 'files':
        return {
          ...field,
          type: 'multiple-file-uploader',
          rules: {
            ...field.rules,
            validate: {
              validFiles: (files) => {
                const message = validateFiles(files)
                return message.length === 0 || message
              },
            },
          },
          options: {
            folder: 'file-package/files',
            enableCover: true,
          },
        }

      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
