import React from 'react'

import DefaultForm from '../../../components/DefaultForm'
import {
  APRENDA_INVESTIR_BANNER_CONFIG_URL,
  APRENDA_INVESTIR_BANNER_URL,
  IMAGES_URL,
} from '../../../config/urls'
import { BASE, APRENDA_INVESTIR, APRENDA_INVESTIR_BANNER } from '../../../config/routes'
import Visualization from './Visualization'

export default function BannerForm() {
  return (
    <DefaultForm
      pageTitle="Banner"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Banner', route: APRENDA_INVESTIR_BANNER },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={APRENDA_INVESTIR_BANNER_CONFIG_URL}
      mainRoute={APRENDA_INVESTIR_BANNER}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: APRENDA_INVESTIR_BANNER_URL }}
      renderVisualization={(banner) => <Visualization banner={banner} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'image':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'aprenda-investir/banner/images',
            enableAlt: true,
          },
        }
      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
