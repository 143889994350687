import React from 'react'

import DefaultForm from '../../../components/DefaultForm'
import {
  CATEGORY_URL,
  HOME_CONFIG_URL,
  HOME_CONFIG_CONFIG_URL,
  NEWS_URL,
} from '../../../config/urls'
import { BASE, HOME, HOME_CONFIG } from '../../../config/routes'

export default function HomeConfigForm() {
  return (
    <DefaultForm
      title="Config."
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Home', route: HOME },
        { title: 'Config.', route: HOME_CONFIG },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={HOME_CONFIG_CONFIG_URL}
      mainRoute={HOME_CONFIG}
      buildForm={(data) => buildForm(data)}
      submitOptions={{
        method: 'POST',
        url: HOME_CONFIG_URL,
      }}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'type':
        return {
          ...field,
          type: 'radio',
          options: [
            { label: 'Carrossel', value: 'CAROUSEL' },
            { label: 'Colunas', value: 'COLUMNS' },
          ],
        }

      case 'news':
        return {
          ...field,
          type: 'select-with-draggable-list',
          options: {
            url: NEWS_URL,
            field: 'title',
            fieldLabel: 'Título',
          },
        }

      case 'categories':
        return {
          ...field,
          type: 'select-with-draggable-list',
          options: {
            url: CATEGORY_URL,
            field: 'name',
            fieldLabel: 'Nome',
          },
        }

      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
