import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { QUOTATION_URL, QUOTATION_CONFIG_URL, IMAGES_URL } from '../../config/urls'
import { BASE, QUOTATION } from '../../config/routes'
import { validateContentBlocks } from '../../components/Form/validations'

export default function QuotationForm() {
  return (
    <DefaultForm
      pageTitle="Cotações"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Cotações', route: QUOTATION },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={QUOTATION_CONFIG_URL}
      mainRoute={QUOTATION}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: QUOTATION_URL }}
      renderVisualization={(quotation) => <Visualization quotation={quotation} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'lastAnalysisID':
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }
      case 'name':
        return {
          ...field,
          type: 'text',
          rules: {
            ...field.rules,
            maxLength: {
              value: 85,
              message: 'A  não pode exceder 85 caracteres.',
            },
            required: true,
          },
        }
      case 'description':
        return {
          ...field,
          type: 'text',
          size: '1/2',
          rules: {
            ...field.rules,
            maxLength: {
              value: 50,
              message: 'A descrição nao pode exceder 50 caracteres.',
            },
            required: true,
          },
        }
      case 'recommendation':
        return {
          ...field,
          type: 'text',
          size: '1/2',
          rules: {
            ...field.rules,
            maxLength: {
              value: 50,
              message: 'A recomendação não pode exceder 50 caracteres.',
            },
            required: true,
          },
        }
      case 'image':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'quotation/images',
            enableAlt: true,
          },
          rules: {
            ...field.rules,
            required: true,
          },
        }

      case 'upside':
        return {
          ...field,
          type: 'text',
          size: '1/2',
          disabled: true,
          rules: {
            ...field.rules,
          },
        }
      case 'variation':
        return {
          ...field,
          size: '1/2',
          type: 'float',
          disabled: true,
          rules: {
            ...field.rules,
          },
        }
      case 'variationMin':
        return {
          ...field,
          size: '1/2',
          type: 'float',
          disabled: true,
          rules: {
            ...field.rules,
          },
        }
      case 'variationMax':
        return {
          ...field,
          type: 'float',
          size: '1/2',
          disabled: true,
          rules: {
            ...field.rules,
          },
        }
      case 'price':
        return {
          ...field,
          size: '1/2',
          type: 'number',
          disabled: true,
          rules: {
            ...field.rules,
          },
        }
      case 'priceForecast':
        return {
          ...field,
          size: '1/2',
          type: 'number',
          valueAsNumber: true,
          rules: {
            ...field.rules,
          },
        }
      case 'lastClosingPrice':
        return {
          ...field,
          type: 'float',
          size: '1/2',
          disabled: true,
          rules: {
            ...field.rules,
          },
        }

      case 'qrCode':
        return {
          ...field,
          type: 'text',
          rules: {
            ...field.rules,
            required: true,
          },
        }
      case 'deepLink':
        return {
          ...field,
          type: 'text',
          rules: {
            ...field.rules,
            required: true,
          },
        }
      case 'contentBlocks':
        return {
          ...field,
          type: 'news-editor',
          options: {
            audioFolder: 'news/audio',
            folder: 'news/images',
          },
          rules: {
            ...field.rules,
            validate: {
              validBlocks: (blocks) => {
                const message = validateContentBlocks(blocks)
                return message.length === 0 || message
              },
            },
          },
        }
      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
