import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { AUTHOR_URL } from '../../config/urls'
import { BASE, AUTHOR } from '../../config/routes'

export default function AuthorModeration() {
  return (
    <Moderation
      pageTitle="Autores"
      mainUrl={AUTHOR_URL}
      mainRoute={AUTHOR}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Autores' }]}
      renderVisualization={(author) => <Visualization author={author} />}
    />
  )
}
