import React from 'react'

import * as S from './styles'

export default function SubcategoryVisualization({ subcategory }) {
  const { name } = subcategory

  return (
    <S.VisualizationContainer>
      <S.SubcategoryLabel>Nome:</S.SubcategoryLabel>
      <S.SubcategoryTitle>{name}</S.SubcategoryTitle>
    </S.VisualizationContainer>
  )
}
