import React from 'react'

import { Checkbox, Input, Radio, RadioGroup, SelectNative } from '@inter/inter-ui'

import ImageUploader from '../ImageUploader'
import IconSelector from '../IconSelector'
import SelectWithInput from '../SelectWithInput'
import SimpleSelectWithInput from '../SimpleSelectWithInput'
import TemplatePicker from '../TemplatePicker'
import NewsEditor from '../../modules/news/NewsEditor'
import CarouselNewsEditor from '../../modules/aprenda-investir/Editor'
import SelectWithDraggableList from '../SelectWithDraggableList'
import DateTimeEditor from '../DateTimeEditor'
import TextEditor from '../TextEditor'
import MultipleFileUploader from '../MultipleFileUploader'
import FileUploader from '../FileUploader'
import SocialHomePostEditor from '../../modules/home/social/Editor/PostEditor'
import MultipleSelect from '../MultipleSelect'
import { request, slugify } from '../../helpers'
import * as S from './styles'

export default function renderField(field, visibility) {
  delete field.ref

  switch (field.type) {
    case 'checkbox':
      return renderCheckbox(field, visibility)

    case 'radio':
      return renderRadio(field)

    case 'select':
      return RenderSelect(field, visibility)

    case 'template':
      return RenderTemplate(field)

    case 'image':
      return RenderImage(field)

    case 'select-with-input':
      return RenderSelectWithInput(field)

    case 'simple-select-with-input':
      return RenderSimpleSelectWithInput(field)

    case 'news-editor':
      return RenderNewsEditor(field)

    case 'select-with-draggable-list':
      return RenderSelectWithDraggableList(field)

    case 'date':
      return RenderDateInput(field)

    case 'icon-selector':
      return RenderIconSelector(field)

    case 'multiple-file-uploader':
      return RenderMultipleFileUploader(field)

    case 'single-file-uploader':
      return RenderSingleFileUploader(field)

    case 'trail-news-editor':
    case 'video-carousel-news-editor':
      return RenderCarouselNewsEditor(field)

    case 'social-posts-editor':
      return RenderSocialHomePostEditor(field)

    case 'multiple-select':
      return RenderMultipleSelect(field, visibility)

    case 'text-editor':
      return RenderTextEditor(field, visibility)

    default:
      return renderInput(field, visibility)
  }
}

function renderCheckbox(field, visibility) {
  const { size, defaultValue, ...newField } = field

  delete newField.error

  return visibility ? (
    <S.FormInput size={size}>
      <S.FormCustomInput>
        <Checkbox {...newField} defaultChecked={defaultValue} checked={newField.value} />
      </S.FormCustomInput>
    </S.FormInput>
  ) : null
}

function renderRadio(field) {
  const { size, error, errorMessage, infoText, ...newField } = field

  return (
    <S.FormInput size={size}>
      <S.FormCustomInput>
        <S.FormInputTitle>
          <S.FormInputLabel htmlFor={newField.name} error={error}>
            {newField.label}
          </S.FormInputLabel>
        </S.FormInputTitle>
        <RadioGroup {...newField} id={newField.name} style={{ width: '100%' }}>
          {newField.options.map((option) => (
            <Radio key={slugify(option.label)} {...option} />
          ))}
        </RadioGroup>
        <S.FormInputInfoText error={error}>{error ? errorMessage : infoText}</S.FormInputInfoText>
      </S.FormCustomInput>
    </S.FormInput>
  )
}

function RenderSelect(field, visibility) {
  const [selectOptions, setSelectOptions] = React.useState()
  const { size, defaultValue, ...newField } = field
  const options = newField.options ? fixSelectOptions(newField.options) : selectOptions

  if (!options && newField.fetch) {
    const { url, callback, headers = {} } = newField.fetch
    request({ method: 'GET', url, headers }).then((data) =>
      setSelectOptions(fixSelectOptions(callback(data))),
    )
  }

  return visibility ? (
    <S.FormInput size={size}>
      <SelectNative
        {...newField}
        defaultValue={defaultValue}
        style={{ width: '100%' }}
        options={options || []}
        disabled={newField.disabled || !options}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
      />
    </S.FormInput>
  ) : null
}

function renderInput(field, visibility) {
  const { size, ...newField } = field

  return visibility ? (
    <S.FormInput size={size}>
      <Input
        {...newField}
        style={{
          width: '100%',
          ...(newField.type === 'hidden' && { display: 'none' }),
        }}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
      />
    </S.FormInput>
  ) : null
}

function RenderDateInput(field) {
  const [date, setDate] = React.useState(new Date())
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <DateTimeEditor
        {...newField}
        date={newField.value ? new Date(newField.value) : date}
        setDate={(options) => {
          onChange(options)
          setDate(options)
        }}
        timeIntervals={newField.options && newField.options.timeIntervals}
        showTimeSelect={newField.options && newField.options.showTimeSelect}
        dateFormat={newField.options && newField.options.dateFormat}
        minDate={newField.options && newField.options.minDate}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
      />
    </S.FormInput>
  )
}

function RenderTemplate(field) {
  const [selectedTemplate, setSelectedTemplate] = React.useState()
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <TemplatePicker
        {...newField}
        selectedTemplate={selectedTemplate || newField.value}
        setSelectedTemplate={(template) => {
          onChange(template)
          setSelectedTemplate(template)
        }}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
      />
    </S.FormInput>
  )
}

function RenderImage(field) {
  const [image, setImage] = React.useState()
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <ImageUploader
        {...newField}
        image={image || newField.value}
        setImage={(img) => {
          onChange(img)
          setImage(img)
        }}
        url={newField.options.url}
        folder={newField.options.folder}
        enableAlt={newField.options.enableAlt}
        hasDescriptionForm={newField.options.hasDescriptionForm}
        size={newField.options.size}
        imageSize={newField.options.imageSize}
        maxWidth={newField.options.maxWidth}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
      />
    </S.FormInput>
  )
}

function RenderSelectWithInput(field) {
  const [selectWithInputOptions, setSelectWithInputOptions] = React.useState()
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <SelectWithInput
        {...newField}
        selectedOptions={selectWithInputOptions || newField.value}
        setSelectedOptions={(options) => {
          onChange(options)
          setSelectWithInputOptions(options)
        }}
        url={newField.options.url}
        field={newField.options.field}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
      />
    </S.FormInput>
  )
}

function RenderSimpleSelectWithInput(field) {
  const [simpleSelectWithInputOptions, setSimpleSelectWithInputOptions] = React.useState([])
  const { size, onChange, identifier, ...newField } = field

  return (
    <S.FormInput size={size}>
      <SimpleSelectWithInput
        {...newField}
        items={newField.value || simpleSelectWithInputOptions}
        setItems={(options) => {
          onChange(options)
          setSimpleSelectWithInputOptions(options)
        }}
        url={newField.options.url}
        field={newField.options.field}
        fieldLabel={newField.options.fieldLabel}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
        uuid={identifier}
      />
    </S.FormInput>
  )
}

function RenderNewsEditor(field) {
  const [contentBlocks, setContentBlocks] = React.useState([])
  const ref = React.useRef(null)
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <NewsEditor
        {...newField}
        blocks={newField.value || contentBlocks}
        setBlocks={(content) => {
          onChange(content)
          setContentBlocks(content)
        }}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
        ref={ref}
        audioFolder={newField.options.audioFolder}
        folder={newField.options.folder}
      />
    </S.FormInput>
  )
}

function RenderTextEditor(field, visibility) {
  const [text, setText] = React.useState(null)
  const { size, onChange, ...newField } = field

  return visibility ? (
    <S.FormInput size={size}>
      <TextEditor
        {...newField}
        fieldLabel={newField.options.fieldLabel}
        content={newField.value || text}
        changeContent={(content) => {
          onChange(content)
          setText(content)
        }}
        error={newField.error}
      />
      <S.FormInputInfoText error={newField.error} isTextEditor>
        {newField.error ? newField.errorMessage : newField.infoText}
      </S.FormInputInfoText>
    </S.FormInput>
  ) : null
}

function RenderCarouselNewsEditor(field) {
  const [contentBlocks, setContentBlocks] = React.useState([])
  const ref = React.useRef(null)
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <CarouselNewsEditor
        {...newField}
        blocks={newField.value || contentBlocks}
        setBlocks={(content) => {
          onChange(content)
          setContentBlocks(content)
        }}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
        ref={ref}
        url={newField.options.url}
        folder={newField.options.folder}
        isTrail={newField.options.isTrail}
      />
    </S.FormInput>
  )
}

function RenderSocialHomePostEditor(field) {
  const [posts, setPosts] = React.useState([])
  const ref = React.useRef(null)
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <SocialHomePostEditor
        {...newField}
        blocks={newField.value || posts}
        setBlocks={(content) => {
          onChange(content)
          setPosts(content)
        }}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
        ref={ref}
      />
    </S.FormInput>
  )
}

function RenderMultipleSelect(field, visibility) {
  const [items, setItems] = React.useState([])
  const ref = React.useRef(null)
  const { size, onChange, ...newField } = field

  return visibility ? (
    <S.FormInput size={size}>
      <MultipleSelect
        {...newField}
        items={newField.value || items}
        options={newField.options.availableTopics}
        setItems={(options) => {
          onChange(options)
          setItems(options)
        }}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
        ref={ref}
      />
    </S.FormInput>
  ) : null
}

function RenderSelectWithDraggableList(field) {
  const [selectWithDraggableItems, setSelectWithDraggableItems] = React.useState([])
  const { size, onChange, identifier, ...newField } = field

  return (
    <S.FormInput size={size}>
      <SelectWithDraggableList
        {...newField}
        items={newField.value || selectWithDraggableItems}
        setItems={(options) => {
          onChange(options)
          setSelectWithDraggableItems(options)
        }}
        url={newField.options.url}
        field={newField.options.field}
        fieldLabel={newField.options.fieldLabel}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
        uuid={identifier}
      />
    </S.FormInput>
  )
}

function RenderIconSelector(field) {
  const [icon, setIcon] = React.useState(null)
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <IconSelector
        {...newField}
        icon={newField.value || icon}
        setIcon={(item) => {
          onChange(item)
          setIcon(item)
        }}
        style={{ width: '100%' }}
        options={newField.options || []}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
      />
    </S.FormInput>
  )
}

function RenderMultipleFileUploader(field) {
  const [blocks, setBlocks] = React.useState([])
  const ref = React.useRef(null)
  const { size, onChange, ...newField } = field

  return (
    <S.FormInput size={size}>
      <MultipleFileUploader
        {...newField}
        blocks={newField.value || blocks}
        setBlocks={(content) => {
          onChange(content)
          setBlocks(content)
        }}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
        ref={ref}
        folder={newField.options.folder}
        type={newField.options.type}
        enableCover={newField.options.enableCover}
      />
    </S.FormInput>
  )
}

function RenderSingleFileUploader(field) {
  const [content, setContent] = React.useState({ description: '', file: {} })
  const { size, onChange, ...newField } = field
  return (
    <S.FormInput size={size}>
      <FileUploader
        {...newField}
        content={newField.value || content}
        changeContent={(newContent) => {
          onChange(newContent)
          setContent(newContent)
        }}
        folder={newField.options.folder}
        type={newField.options.type}
        label={newField.options.label}
        infoText={newField.error ? newField.errorMessage : newField.infoText}
      />
    </S.FormInput>
  )
}

function fixSelectOptions(options) {
  return options.map((option) => ({
    text: option.label,
    value: option.value,
  }))
}
