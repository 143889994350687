import { createGlobalStyle } from 'styled-components'

import BackgroundImage from '../assets/images/bg-header.png'
import { gray, grayscale, orange, status, generateColorClasses } from './colors'

import './sass/bootstrap.scss'
import '@inter/inter-ui/dist/inter-ui.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const globalStyle = createGlobalStyle`
  body {
    background: url(${BackgroundImage}) center top no-repeat;
    color: ${gray[300]};
    font-family: Inter;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Sora;
  }

  a {
    color: ${orange[400]};
    text-decoration: none !important;

    &:hover {
      color: ${orange[500]}
    }
  }

  ${generateColorClasses(gray, 'gray')}
  ${generateColorClasses(grayscale, 'grayscale')}
  ${generateColorClasses(orange, 'orange')}
  ${generateColorClasses(status, 'status')}
`

export default globalStyle
