import { css } from 'styled-components'

export const gray = {
  100: '#D1D4E3',
  200: '#8C91A9',
  300: '#6A6E81',
  500: '#58595b',
}

export const grayscale = {
  100: '#F5F6FA',
  200: '#DEDFE4',
  300: '#B6B7BB',
  400: '#6A6C72',
  500: '#161616',
}

export const green = '#00AA4F'

export const orange = {
  100: '#FFF2E7',
  200: '#FFDAB7',
  300: '#FFB46E',
  400: '#FF9D42',
  500: '#FF7A00',
}

export const status = {
  success: '#20C05D',
  alert: '#FFBA00',
  info: '#40B9EA',
  error: '#F56A50',
}

export const black = '#000000'
export const white = '#FFFFFF'

export function generateColorClasses(colorMap, name) {
  return Object.keys(colorMap).map(
    (type) =>
      css`
        .bg-${name}--${type} {
          background: ${colorMap[type]};
        }
        .text-${name}--${type} {
          color: ${colorMap[type]};
        }
        .fill-${name}--${type} {
          fill: ${colorMap[type]};
        }
      `,
  )
}
