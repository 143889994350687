import React from 'react'

import { useAuth } from '../../../hooks'
import List from '../../../components/List'
import { APRENDA_INVESTIR_TRAIL_CONFIG_URL, APRENDA_INVESTIR_TRAIL_URL } from '../../../config/urls'
import { BASE, APRENDA_INVESTIR, APRENDA_INVESTIR_TRAIL } from '../../../config/routes'

export default function TrailList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions['aprenda-investir'].trail.publish,
    }
  }

  return (
    <List
      pageTitle="Trilha"
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Trilha' },
      ]}
      configUrl={APRENDA_INVESTIR_TRAIL_CONFIG_URL}
      baseUrl={APRENDA_INVESTIR_TRAIL_URL}
      baseRoute={APRENDA_INVESTIR_TRAIL}
      searchField="title"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        groupBy: ['uuid'],
      }}
      permissions={getPermissions()}
      showOrder
    />
  )
}
