import React from 'react'

import * as S from './styles'

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()

    return entityKey && contentState.getEntity(entityKey).getType() === 'LINK'
  }, callback)
}

function LinkDecorator({ children, contentState, entityKey }) {
  const { url, targetOption } = contentState.getEntity(entityKey).getData()

  return (
    <S.LinkDecoratorWrapper>
      <S.LinkDecoratorAnchor href={url} target={targetOption}>
        {children}
      </S.LinkDecoratorAnchor>
    </S.LinkDecoratorWrapper>
  )
}

export function getLinkDecorator() {
  return {
    strategy: findLinkEntities,
    component: LinkDecorator,
  }
}
