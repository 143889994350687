/* eslint-disable */
import React from 'react'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import Chevron from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'

import LinkEditor from './LinkEditor'
import * as S from './styles'
import { FormInputLabel, FormInputInfoText } from '../../../../../components/Form/styles'

function PostEditor({ blocks = [], setBlocks, error, infoText }, ref) {
  const [currentKey, setCurrentKey] = React.useState(0)

  React.useEffect(() => {
    setBlocks(blocks.map((block, index) => ({ ...block, key: `post-editor-${index}` })))
    setCurrentKey(blocks.length)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function addBlock() {
    setBlocks([
      ...blocks,
      { key: `post-editor-${currentKey}`, content: { title: '', socialMedia: '', data: [] } },
    ])
    setCurrentKey(currentKey + 1)
  }

  function removeBlock(index) {
    const updatedBlocks = [...blocks]
    updatedBlocks.splice(index, 1)

    setBlocks(updatedBlocks)
  }

  function changePosition(indexFrom, indexTo) {
    if (indexTo < 0 || indexTo > blocks.length - 1) return

    const updatedBlocks = [...blocks]
    const aux = updatedBlocks[indexFrom]

    updatedBlocks[indexFrom] = updatedBlocks[indexTo]
    updatedBlocks[indexTo] = aux

    setBlocks(updatedBlocks)
  }

  function changeContent(index, content) {
    const updatedBlocks = [...blocks]
    updatedBlocks[index].content = content

    setBlocks(updatedBlocks)
  }

  function renderEditor(block, index) {
    const { content } = block

    return (
      <LinkEditor
        content={content}
        changeContent={(newContent) => {
          changeContent(index, newContent)
        }}
      />
    )
  }

  function renderCard(block, index) {
    return (
      <S.CardContainer key={block.key}>
        {renderEditor(block, index)}
        <S.CardControls>
          <button type="button" className="btn" onClick={() => removeBlock(index)}>
            <Close height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index - 1)}>
            <Chevron height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index + 1)}>
            <Chevron height={20} rotate={180} color="grayscale--400" />
          </button>
        </S.CardControls>
      </S.CardContainer>
    )
  }

  return (
    <S.EditorContainer ref={ref}>
      <FormInputLabel error={error}>Posts</FormInputLabel>
      <S.ContentContainer>
        <S.BlocksContainer>
          {blocks.length > 0 ? (
            blocks.map((block, index) => renderCard(block, index))
          ) : (
            <S.InfoText>
              <p> Por favor adicione novos blocos ... </p>
            </S.InfoText>
          )}
        </S.BlocksContainer>
        <S.ContentControls>
          <button type="button" onClick={() => addBlock()}>
            + Bloco
          </button>
        </S.ContentControls>
      </S.ContentContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.EditorContainer>
  )
}

export default React.forwardRef(PostEditor)
