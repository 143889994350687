import React from 'react'

import { useAuth } from '../../../hooks'
import List from '../../../components/List'
import { HOME_SOCIAL_CONFIG_URL, HOME_SOCIAL_URL } from '../../../config/urls'
import { BASE, HOME, HOME_SOCIAL } from '../../../config/routes'

export default function SocialHomeList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions.home.social.publish,
    }
  }
  return (
    <List
      pageTitle="Redes Sociais"
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Home', route: HOME },
        { title: 'Redes Sociais' },
      ]}
      configUrl={HOME_SOCIAL_CONFIG_URL}
      baseUrl={HOME_SOCIAL_URL}
      baseRoute={HOME_SOCIAL}
      searchField="title"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        _limit: 5,
      }}
      permissions={getPermissions()}
    />
  )
}
