import React from 'react'

import ChevronUp from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'

import { request, isValidDate } from '../../helpers'
import { formatQueryParams, formatDate } from './formatters'
import renderStatusColumn from './statuses'
import renderActionsColumn from './actions'
import * as S from './styles'

export function getDefaultSortField(url, callback) {
  return request({ method: 'GET', url }).then((response) =>
    callback(
      response.data.contentType.settings.defaultSortBy,
      response.data.contentType.settings.defaultSortOrder,
    ),
  )
}

export function fetchColumns(
  url,
  callback,
  baseRoute,
  permissions,
  allowedActions,
  baseUrl,
  setModalConfig,
  setConfirmModalConfig,
  doSearch,
) {
  request({ method: 'GET', url }).then((response) => {
    const { layouts, metadatas } = response.data.contentType

    const columns = layouts.list.reduce((accumulator, accessor) => {
      const { label: Header } = metadatas[accessor].list

      return [
        ...accumulator,
        {
          Header,
          accessor,
          Cell: ({ value }) => (isValidDate(value) ? formatDate(value) : value),
        },
      ]
    }, [])

    callback([
      {
        id: 'expander',
        Header: () => null,
        Cell: ({ row }) => (
          <S.Icon>
            <ChevronUp height="15" width="15" rotate={row.isExpanded ? 0 : 180} />
          </S.Icon>
        ),
        maxWidth: 15,
        disableSortBy: true,
      },
      ...columns,
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) =>
          renderStatusColumn(row.original, (message) =>
            setModalConfig({
              isOpen: true,
              message,
            }),
          ),
        maxWidth: 120,
        disableSortBy: true,
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        Cell: ({ row }) =>
          renderActionsColumn(
            row.original,
            baseRoute,
            permissions,
            allowedActions,
            baseUrl,
            setModalConfig,
            setConfirmModalConfig,
            doSearch,
          ),
        maxWidth: 120,
        disableSortBy: true,
      },
    ])
  })
}

export function fetchData(url, callback, filters, editionFilters, moderationFilters) {
  const queryParams = filters ? formatQueryParams(filters, editionFilters, moderationFilters) : ''
  return request({ method: 'GET', url: `${url}${queryParams}` }, true).then(callback)
}
