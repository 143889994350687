import React from 'react'

import { useAuth } from '../../hooks'
import List from '../../components/List'
import { QUOTE_CONFIG_URL, QUOTE_URL } from '../../config/urls'
import { BASE, QUOTE } from '../../config/routes'

export default function QuoteList() {
  const { auth } = useAuth()
  function getPermissions() {
    return {
      editor: true,
      moderator: auth.permissions.quote.quote.publish,
    }
  }

  return (
    <List
      pageTitle="Quotes"
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Quotes' }]}
      configUrl={QUOTE_CONFIG_URL}
      baseUrl={QUOTE_URL}
      baseRoute={QUOTE}
      searchField="title"
      childrenFilters={{
        mainItem: false,
        _sort: 'createdAt:DESC',
        groupBy: ['uuid'],
      }}
      permissions={getPermissions()}
    />
  )
}
