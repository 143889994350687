import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { CATEGORY_URL, CATEGORY_CONFIG_URL } from '../../config/urls'
import { BASE, CATEGORY } from '../../config/routes'

const templates = {
  A: {
    rows: 2,
    gridAreas: ['1 / 1 / span 2 / span 4'],
  },
  B: {
    rows: 2,
    gridAreas: ['1 / 1 / span 2 / span 2', '1 / 3 / span 2 / span 2'],
  },
  C: {
    rows: 2,
    gridAreas: ['1 / 1 / span 2 / span 2', '1 / 3 / span 2 / span 1', '1 / 4 / span 2 / span 1'],
  },
  D: {
    rows: 2,
    gridAreas: [
      '1 / 1 / span 2 / span 2',
      '2 / 3 / span 1 / span 1',
      '1 / 3 / span 1 / span 1',
      '1 / 4 / span 2 / span 1',
    ],
  },
  E: {
    rows: 1,
    gridAreas: [
      '1 / 1 / span 1 / span 1',
      '1 / 2 / span 1 / span 1',
      '1 / 3 / span 1 / span 1',
      '1 / 4 / span 1 / span 1',
    ],
  },
}

const icons = [
  {
    text: 'Ícone #1',
    value: 'market',
  },
  {
    text: 'Ícone #2',
    value: 'investments',
  },
  {
    text: 'Ícone #3',
    value: 'inter-research',
  },
  {
    text: 'Ícone #4',
    value: 'product-many',
  },
  {
    text: 'Ícone #5',
    value: 'settings',
  },
  {
    text: 'Ícone #6',
    value: 'aprenda-a-investir',
  },
]

export default function CategoryForm() {
  return (
    <DefaultForm
      pageTitle="Categorias"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Categorias', route: CATEGORY },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={CATEGORY_CONFIG_URL}
      mainRoute={CATEGORY}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: CATEGORY_URL }}
      renderVisualization={(category) => <Visualization category={category} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'name':
        return {
          ...field,
          type: 'text',
        }
      case 'subtitle':
        return {
          ...field,
          type: 'text',
        }

      case 'templateType':
        return {
          ...field,
          type: 'template',
          templates,
        }
      case 'icon':
        return {
          ...field,
          type: 'icon-selector',
          options: icons,
        }
      default:
        return {
          ...field,
          type: 'color',
          size: '1/4',
        }
    }
  })
}
