import styled from 'styled-components'

import { grayscale, orange, white } from '../../styles/colors'

export const EditorContainer = styled.div`
  background-color: ${grayscale[100]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 15px;
  width: 100%;
`

export const ContentContainer = styled.div`
  background-color: ${grayscale[100]};
  border-radius: 8px;
  padding: 1.5rem;
`

export const BlocksContainer = styled.div``

export const InfoText = styled.div`
  color: ${grayscale[400]};
  margin: 3rem;
  text-align: center;
`

export const ContentControls = styled.div`
  text-align: right;
  button {
    background-color: transparent;
    border: none;
    color: ${orange[300]};
    cursor: pointer;
    margin-left: 10px;
    outline: none;
    text-transform: uppercase;

    &:hover {
      color: ${orange[500]};
    }
  }
`

export const CardContainer = styled.div`
  background-color: ${white};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
`

export const CardControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;

  button {
    background-color: ${white};
    border-radius: 2px;
    margin-bottom: 5px;
    padding: 10px;

    &:hover {
      svg {
        fill: ${orange[500]};
      }
    }
  }
`
