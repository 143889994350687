import React from 'react'

import { useOnClickOutside } from '../../hooks'
import { request, slugify } from '../../helpers'
import LoadingScreen from '../LoadingScreen'
import { FormInputLabel, FormInputInfoText } from '../Form/styles'
import * as S from './styles'

export default function SelectWithInput({
  selectedOptions = [],
  setSelectedOptions,
  field,
  url,
  label,
  error,
  infoText,
}) {
  const [options, setOptions] = React.useState(null)
  const [inputValue, setInputValue] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [showOptions, setShowOptions] = React.useState(false)

  function isValidOption(option) {
    const containsInFilter = option.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    const containsInSelectedOptions =
      selectedOptions.findIndex((selectedOption) => option === selectedOption) !== -1

    return containsInFilter && !containsInSelectedOptions
  }

  const containerRef = React.useRef(null)
  useOnClickOutside(
    containerRef,
    () => {
      setShowOptions(false)

      if (inputValue.trim().length !== 0 && isValidOption(inputValue)) {
        setSelectedOptions([...selectedOptions, inputValue])
        setInputValue('')
      }
    },
    inputValue,
  )

  React.useEffect(() => {
    setLoading(true)

    request({
      method: 'GET',
      url,
    }).then((response) => {
      setLoading(false)
      setOptions(response.map((tag) => tag[field]))
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function renderOptions(opts) {
    return opts.length > 0 ? (
      <S.OptionsContainer>
        {opts.filter(isValidOption).map((option, idx) => {
          const key = `option-${idx}`
          return (
            <S.Option
              key={key}
              onClick={() => {
                setSelectedOptions([...selectedOptions, option])
                setShowOptions(false)
                setInputValue('')
              }}
            >
              {option}
            </S.Option>
          )
        })}
      </S.OptionsContainer>
    ) : (
      <S.EmptyResult>Nenhum registro encontrado</S.EmptyResult>
    )
  }

  const slugifiedLabel = slugify(label)

  function renderSelect() {
    return (
      options && (
        <S.SelectWithInputContainer ref={containerRef}>
          <FormInputLabel>{label}</FormInputLabel>
          <S.InputContainer>
            <S.InputBox>
              <S.SelectedOptions>
                {selectedOptions.map((selectedOption, idx) => {
                  const key = `selected-option-${idx}`
                  return (
                    <S.SelectedOption
                      key={key}
                      onClick={() => {
                        const cloneSelectedOptions = [...selectedOptions]
                        cloneSelectedOptions.splice(idx, 1)

                        setSelectedOptions(cloneSelectedOptions)
                      }}
                    >
                      {selectedOption}
                      <span> X </span>
                    </S.SelectedOption>
                  )
                })}
              </S.SelectedOptions>
              <S.Input
                id={slugifiedLabel}
                name={slugifiedLabel}
                type="text"
                value={inputValue}
                onFocus={() => {
                  setShowOptions(true)
                }}
                onChange={(e) => {
                  setInputValue(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSelectedOptions([...selectedOptions, e.target.value])
                    setInputValue('')
                  }
                }}
              />
            </S.InputBox>
            {showOptions && renderOptions(options)}
          </S.InputContainer>
          <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
        </S.SelectWithInputContainer>
      )
    )
  }

  return <>{loading ? <LoadingScreen /> : renderSelect()}</>
}
