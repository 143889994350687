import React from 'react'

import DefaultForm from '../../components/DefaultForm'
import Visualization from './Visualization'
import { EVENT_URL, EVENT_CONFIG_URL, IMAGES_URL } from '../../config/urls'
import { BASE, EVENT } from '../../config/routes'

export default function EventForm() {
  return (
    <DefaultForm
      pageTitle="Evento"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Evento', route: EVENT },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={EVENT_CONFIG_URL}
      mainRoute={EVENT}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: EVENT_URL }}
      renderVisualization={(event) => <Visualization event={event} />}
    />
  )
}

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
      case 'alreadyPublish':
        return {
          ...field,
          type: 'hidden',
        }

      case 'description':
        return {
          ...field,
          rules: {
            maxLength: {
              value: 49,
              message: 'A descrição não pode exceder 49 caracteres.',
            },
          },
        }

      case 'expectedValue':
      case 'lastValue':
        return {
          ...field,
          size: '1/2',
        }

      case 'expectedValueVariation':
      case 'lastValueVariation':
        return {
          ...field,
          type: 'radio',
          options: [
            { label: 'Positiva', value: 'POSITIVA' },
            { label: 'Neutra', value: 'NEUTRA' },
            { label: 'Negativa', value: 'NEGATIVA' },
          ],
          defaultValue: field.defaultValue || 'NEUTRA',
          size: '1/2',
        }

      case 'urlLive':
        return {
          ...field,
          size: '1/2',
        }

      case 'date':
        return {
          ...field,
          type: 'date',
          defaultValue: field.defaultValue ? new Date(field.defaultValue) : new Date(),
          options: {
            showTimeSelect: true,
          },
        }

      case 'regionIcon':
        return {
          ...field,
          type: 'image',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'event/images',
            enableAlt: true,
          },
        }

      case 'investProExclusive':
        return {
          ...field,
          type: 'checkbox',
          size: '1/2',
        }

      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
