import { useEffect } from 'react'

export default function useOnClickOutside(ref, handler) {
  useEffect(() => {
    function onClickOutsideHandler(evt) {
      if (!ref.current || ref.current.contains(evt.target)) return

      handler()
    }

    document.addEventListener('mousedown', onClickOutsideHandler)
    document.addEventListener('touchstart', onClickOutsideHandler)

    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler)
      document.removeEventListener('touchstart', onClickOutsideHandler)
    }
  }, [handler, ref])
}
