import React from 'react'

import { getSelectionEntity, getSelectionText, getEntityRange } from 'draftjs-utils'

import { Input, Checkbox, Button } from '@inter/inter-ui'

import { useOnClickOutside } from '../../../hooks'
import { EditorOption } from '../components'
import { toggleLink } from './handlers'
import linkIcon from '../../../assets/images/link.svg'
import unlinkEnabledIcon from '../../../assets/images/unlink-enabled.svg'
import unlinkDisabledIcon from '../../../assets/images/unlink-disabled.svg'
import * as S from './styles'

const LINK_INITIAL_STATE = {
  title: '',
  target: '',
  targetOption: '_blank',
}

export default function LinkControl({ editorState, handleChange }) {
  const [link, setLink] = React.useState(LINK_INITIAL_STATE)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const modalRef = React.useRef(null)

  useOnClickOutside(modalRef, () => {
    setIsModalOpen(!isModalOpen)
  })

  const currentEntity = getSelectionEntity(editorState)
  const selection = getSelection(editorState, currentEntity)

  const renderModal = React.useCallback(
    () => (
      <S.LinkModal ref={modalRef}>
        <Input
          label="Título"
          placeholder="Informe o título do link"
          value={link.title}
          style={{ width: '100%' }}
          onChange={(evt) => setLink({ ...link, title: evt.target.value })}
        />
        <Input
          label="URL"
          placeholder="Informe a URL do link"
          value={link.target}
          style={{ width: '100%' }}
          onChange={(evt) => setLink({ ...link, target: evt.target.value })}
        />
        <S.LinkTargetOption>
          <Checkbox
            label="Abrir link em nova aba"
            checked={link.targetOption === '_blank'}
            defaultChecked={link.targetOption === '_blank'}
            style={{ width: '100%' }}
            onChange={(evt) =>
              setLink({ ...link, targetOption: evt.target.checked ? '_blank' : '_self' })
            }
          />
        </S.LinkTargetOption>
        <S.LinkModalButtons>
          <Button
            variant="secondary"
            onClick={() => {
              setIsModalOpen(false)
              setLink(LINK_INITIAL_STATE)
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            disabled={!link.title || !link.target}
            onClick={() => {
              toggleLink(editorState, handleChange, selection, {
                ...link,
                action: 'link',
              })
              setIsModalOpen(false)
              setLink(LINK_INITIAL_STATE)
            }}
          >
            Adicionar
          </Button>
        </S.LinkModalButtons>
      </S.LinkModal>
    ),
    [editorState, handleChange, link, selection],
  )

  const currentLink = getCurrentLinkValues(editorState, currentEntity)

  return (
    <S.LinkModalContainer>
      <EditorOption
        title="Inserir Link"
        onToggle={() => {
          setLink(
            currentLink || {
              ...LINK_INITIAL_STATE,
              title: getSelectionText(editorState),
            },
          )
          setIsModalOpen(true)
        }}
      >
        <S.ControlImage src={linkIcon} size={16} />
      </EditorOption>
      <EditorOption
        title="Remover Link"
        disabled={!currentLink}
        onToggle={() =>
          toggleLink(editorState, handleChange, selection, {
            action: 'unlink',
          })
        }
      >
        <S.ControlImage src={currentLink ? unlinkEnabledIcon : unlinkDisabledIcon} size={16} />
      </EditorOption>
      {isModalOpen && renderModal()}
    </S.LinkModalContainer>
  )
}

function getSelection(editorState, currentEntity) {
  let selection = editorState.getSelection()

  if (currentEntity) {
    const entityRange = getEntityRange(editorState, currentEntity)
    const isBackward = selection.getIsBackward()

    if (isBackward) {
      selection = selection.merge({
        anchorOffset: entityRange.end,
        focusOffset: entityRange.start,
      })
    } else {
      selection = selection.merge({
        anchorOffset: entityRange.start,
        focusOffset: entityRange.end,
      })
    }

    return selection
  }

  return selection
}

function getCurrentLinkValues(editorState, currentEntity) {
  const contentState = editorState.getCurrentContent()

  if (currentEntity && contentState.getEntity(currentEntity).get('type') === 'LINK') {
    const { text: title } = getEntityRange(editorState, currentEntity)
    const { url: target, targetOption } = contentState.getEntity(currentEntity).get('data')

    return { title, target, targetOption }
  }

  return null
}
