import React from 'react'

import Play from 'inter-frontend-svgs/lib/orangeds/MD/play'

import { Carousel } from '@inter/inter-ui/'

import * as S from './styles'
import { slugify } from '../../../helpers'

export default function VideoCarouselVisualization({ videoCarousel }) {
  const { title, news } = videoCarousel

  function generateNews(images) {
    return images.map((image, key) => {
      const imageKey = `${key}-${slugify(title)}`
      return (
        <S.CardVideo key={imageKey} bg={image.content.url}>
          <S.Player>
            <Play color="#ffffff" />
          </S.Player>
          <figcaption>
            <h3>{image.content.title}</h3>
          </figcaption>
        </S.CardVideo>
      )
    })
  }

  return (
    <S.VisualizationContainer>
      <S.VideoCarouselTitle>{title}</S.VideoCarouselTitle>
      <S.Images>
        <Carousel
          dots
          arrows
          infinite
          speed={500}
          slidesToShow={3}
          dotsType="small"
          verticalMode={false}
        >
          {generateNews(news)}
        </Carousel>
      </S.Images>
    </S.VisualizationContainer>
  )
}
