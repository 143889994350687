import React from 'react'

import { slugify } from '../../helpers'
import EmptyFileIcon from '../../assets/images/file.svg'
import * as S from './styles'

export default function FilePackageVisualization({ filePackage }) {
  const { title, files } = filePackage

  return (
    <S.VisualizationContainer>
      <S.Title>{title}</S.Title>
      <S.FilesContainer>
        {files.map((item, key) => {
          const fileKey = `${key}-${slugify(item.content.description)}`
          return (
            <S.File key={fileKey}>
              <S.IconWrapper>
                <img alt="" style={{ height: '20px', width: '20px' }} src={EmptyFileIcon} />
              </S.IconWrapper>
              <S.TextWrapper>
                <a href={item.content.file.url}>{item.content.description}</a>
              </S.TextWrapper>
            </S.File>
          )
        })}
      </S.FilesContainer>
    </S.VisualizationContainer>
  )
}
