import React from 'react'

import { useHistory } from 'react-router-dom'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { Button } from '@inter/inter-ui'

import { request } from '../../helpers'
import { FormInputLabel } from '../Form/styles'
import * as S from './styles'

export default function DraggableList({
  listItems,
  label,
  fieldToShow,
  handleNewOrder,
  emptyListMessage,
  submitUrl,
  mainRoute,
}) {
  const history = useHistory()

  const Item = SortableElement(({ value, position }) => (
    <S.ListItem>
      <S.ListItemValue>{value}</S.ListItemValue>
      <S.ListItemPosition>{position}</S.ListItemPosition>
    </S.ListItem>
  ))

  const List = SortableContainer(({ items, field }) => (
    <S.List>
      {items.map((value, index) => {
        const fieldValue = field ? value[field] : value
        const key = `item-${fieldValue}-${index}`

        return <Item key={key} index={index} value={fieldValue} position={index + 1} />
      })}
    </S.List>
  ))

  function arrayMove(array, from, to) {
    const newArray = [...array]
    const startIndex = from < 0 ? newArray.length + from : from

    if (startIndex >= 0 && startIndex < newArray.length) {
      const endIndex = to < 0 ? newArray.length + to : to

      const [item] = newArray.splice(from, 1)
      newArray.splice(endIndex, 0, item)
    }

    return newArray
  }

  function submit() {
    request({
      method: 'PATCH',
      url: submitUrl,
      body: listItems,
    }).then((response) => {
      handleNewOrder(response)
      history.push(mainRoute)
    })
  }

  return (
    <S.ListContainer>
      <FormInputLabel>{label}</FormInputLabel>
      {listItems.length > 0 ? (
        <List
          items={listItems}
          field={fieldToShow}
          lockAxis="y"
          onSortEnd={({ oldIndex, newIndex }) =>
            handleNewOrder(arrayMove(listItems, oldIndex, newIndex))
          }
        />
      ) : (
        <S.EmptyListMessage>{emptyListMessage}</S.EmptyListMessage>
      )}
      {submitUrl && (
        <>
          <Button
            onClick={() => {
              submit()
            }}
          >
            Salvar
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              history.push(mainRoute)
            }}
          >
            Cancelar
          </Button>
        </>
      )}
    </S.ListContainer>
  )
}
