import React from 'react'

import { Checkbox, Input, SelectNative } from '@inter/inter-ui'

import ImageUploader from '../../../../components/ImageUploader'
import DateTimeEditor from '../../../../components/DateTimeEditor'
import { FormInputLabel } from '../../../../components/Form/styles'
import * as S from '../styles'

export default function CarouselNewsEditor({
  blockKey,
  content,
  changeContent,
  saveUrl,
  folder,
  isTrail = false,
}) {
  const {
    url,
    alt,
    title,
    link,
    date,
    isVideo,
    videoTitle,
    videoSlug,
    videoDescription,
    videoCategory,
  } = content
  const categories = [
    {
      value: 'todos',
      text: 'Todos',
    },
    {
      value: 'Maratona Fundos Imobiliários',
      text: 'Maratona Fundos Imobiliários',
    },
    {
      value: 'Mercado de Ações',
      text: 'Mercado de Ações',
    },
    {
      value: 'Inter Ajuda: Home Broker',
      text: 'Inter Ajuda: Home Broker',
    },
    {
      value: 'Inter Week',
      text: 'Inter Week',
    },
  ]

  return (
    <S.BlockContainer>
      <S.InputContainer>
        <FormInputLabel>Título:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe o título"
          value={title}
          onChange={(e) => {
            changeContent({ ...content, title: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.BlockTitle>Imagem</S.BlockTitle>
      <S.InputContainer>
        <ImageUploader
          image={{ url, alt }}
          setImage={(img) => {
            changeContent({ ...content, url: img && img.url, alt: img && img.alt })
          }}
          url={saveUrl}
          folder={folder}
          label=""
          enableAlt
        />
      </S.InputContainer>
      <S.InputContainer>
        <FormInputLabel>Link:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe um link"
          value={link}
          onChange={(e) => {
            changeContent({ ...content, link: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      {isTrail && (
        <>
          <S.InputContainer>
            <FormInputLabel>Data:</FormInputLabel>
            <DateTimeEditor
              date={date ? new Date(date) : new Date()}
              setDate={(dt) => {
                changeContent({ ...content, date: dt })
              }}
            />
          </S.InputContainer>
          <S.InputContainer>
            <Checkbox
              key={`is-video-${blockKey}`}
              defaultChecked={isVideo}
              label="É vídeo"
              onChange={(e) => {
                changeContent({ ...content, isVideo: e.target.checked })
              }}
            />
          </S.InputContainer>
          {isVideo && (
            <>
              <S.InputContainer>
                <FormInputLabel>Título do vídeo:</FormInputLabel>
                <Input
                  type="text"
                  placeholder="Informe o título do vídeo"
                  value={videoTitle}
                  onChange={(e) => {
                    changeContent({ ...content, videoTitle: e.target.value })
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </S.InputContainer>
              <S.InputContainer>
                <FormInputLabel>Slug do vídeo:</FormInputLabel>
                <Input
                  type="text"
                  placeholder="Informe o slug do vídeo"
                  value={videoSlug}
                  onChange={(e) => {
                    changeContent({ ...content, videoSlug: e.target.value })
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </S.InputContainer>
              <S.InputContainer>
                <FormInputLabel>Descrição do vídeo:</FormInputLabel>
                <Input
                  type="text"
                  placeholder="Informe a descrição do vídeo"
                  value={videoDescription}
                  onChange={(e) => {
                    changeContent({ ...content, videoDescription: e.target.value })
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </S.InputContainer>
              <S.InputContainer>
                <FormInputLabel>Categoria do vídeo:</FormInputLabel>
                <SelectNative
                  value={videoCategory}
                  style={{ width: '100%' }}
                  emptyOptionText="Selecione a categoria do vídeo"
                  options={categories}
                  onChange={(e) => changeContent({ ...content, videoCategory: e.target.value })}
                />
              </S.InputContainer>
            </>
          )}
        </>
      )}
    </S.BlockContainer>
  )
}
