import styled, { css } from 'styled-components'

import { black, white } from '../../styles/colors'

export const VisualizationContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

export const PrimaryColorContainer = styled.div`
  color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  padding: 48px;
  width: 100%;

  ${({ bgColor }) => css`
    background: ${bgColor};
  `}
`

export const CategoryTitle = styled.div`
  font-size: 36px;
  line-height: 36px;
  width: 100%;
`

export const CategorySubTitle = styled.div`
  font-size: 20px;
  width: 100%;
`

export const SecondaryColorsContainer = styled.div`
  display: flex;
  height: 150px;
  width: 100%;
`

export const SecondaryColorContainer = styled.div`
  color: ${black};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 48px;
  width: calc(100% / 3);

  ${({ bgColor }) => css`
    background: ${bgColor};
  `}
`

export const ColorInfo = styled.div`
  font-size: 14px;
  width: 100%;
`

export const ColorVariant = styled.div`
  font-weight: 600;
  line-height: 24px;
`

export const ColorHex = styled.div`
  font-weight: 400;
  line-height: 14px;
`
