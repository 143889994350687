import styled from 'styled-components'

import { grayscale, gray } from '../../styles/colors'

export const BreadcrumbsContainer = styled.div`
  align-items: center;
  color: ${gray[500]};
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 0;
  margin-bottom: 1rem;
  width: 100%;
`

export const BreadcrumbsSeparator = styled.div`
  margin: 0 4px;

  svg {
    fill: ${grayscale[400]};
  }
`
