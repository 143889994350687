import React from 'react'

import { Input } from '@inter/inter-ui'

import { FormInputLabel } from '../../../../components/Form/styles'
import * as S from '../styles'

export default function TweetEditor({ content, changeContent }) {
  const { url } = content

  return (
    <S.BlockContainer>
      <S.BlockTitle>Tweet</S.BlockTitle>
      <S.InputContainer>
        <FormInputLabel>URL:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe a URL do Tweet"
          value={url}
          onChange={(e) => {
            changeContent({
              ...content,
              url: e.target.value,
            })
          }}
          style={{
            width: '100%',
          }}
        />
        <S.BlockInfo>
          Ex: &quot;https://twitter.com/potus/status/1422949440701308928&quot;
        </S.BlockInfo>
      </S.InputContainer>
    </S.BlockContainer>
  )
}
