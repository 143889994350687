import styled from 'styled-components'

import { grayscale, white } from '../../styles/colors'

export const SelectWithDraggableListContainer = styled.div`
  background-color: ${grayscale[100]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 230px;
  padding: 15px;
  width: 100%;
`

export const SearchContainer = styled.div`
  background-color: ${white};
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 10px 15px 0px 15px;

  button {
    margin-left: 10px;
    width: 20%;
  }
`

export const ItemsContainer = styled.div`
  background-color: ${white};
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  min-height: 100px;
  padding: 0px 15px 0px 15px;
  span {
    padding-top: 30px;
    text-align: center;
    width: 100%;
  }
`

export const DraggableContainer = styled.div`
  background-color: ${white};
  padding-left: 5px;
  padding-right: 5px;
`

export const Item = styled.div``
