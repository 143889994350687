import styled, { css } from 'styled-components'

import { grayscale } from '../../styles/colors'

export const Container = styled.div`
  align-items: center;
  color: ${grayscale[400]};
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  text-align: left;
`

export const CellphoneContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  min-height: 600px;

  @media (min-width: 480px) {
    width: 425px;
    min-height: 600px;
  }
`

export const CellphoneScreen = styled.img`
  position: absolute;
  width: 100%;
`

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 30px;
  width: 100%;

  @media (min-width: 480px) {
    width: 425px;
    min-height: 600px;
  }
`

export const EventContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

export const Event = styled.div`
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  padding: 16px;
  position: relative;
  width: 100%;
`

export const EventHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  button {
    align-items: center;
    background: #f5f6fa;
    border: 1px solid #f5f6fa;
    border-radius: 50%;
    cursor: initial;
    display: flex;
    justify-content: center;
    outline: none;
    padding: 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`

export const SectionTitle = styled.div`
  border-bottom: 1px solid #e6e7f0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  margin-right: -16px;
  padding-bottom: 8px;
`

export const EventDetails = styled.div`
  align-items: center;
  display: flex;
  padding: 16px 8px;
`

export const Calendar = styled.div`
  align-items: center;
  background: #f5f6fa;
  border-radius: 8px;
  display: flex;
  height: 64px;
  justify-content: center;
  margin-right: 16px;
  width: 64px;
`

export const Day = styled.span`
  display: block;
  font-family: 'Sora';
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  width: 100%;
`

export const Month = styled.span`
  display: block;
  text-align: center;
`

export const EventInfo = styled.div`
  display: block;
  max-width: 75%;

  div {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const EventTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;

  img {
    margin-left: 5px;
    position: relative;
    max-height: 12px;
  }
`

export const EventExpectation = styled.div`
  align-items: center;
  color: ${grayscale[300]};
  display: flex;

  span {
    display: inherit;
  }
`

export const Variation = styled.span`
  margin-left: 2px;

  &:first-child {
    margin-right: 15px;
  }

  ${(props) =>
    props.scenario === 'positiva' &&
    css`
      color: #20b766;
    `}

  ${(props) =>
    props.scenario === 'neutra' &&
    css`
      color: ${grayscale[400]};
    `}

  ${(props) =>
    props.scenario === 'negativa' &&
    css`
      color: #e74c3c;
    `}
`
