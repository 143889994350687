import styled from 'styled-components'

import { grayscale, status, white } from '../../styles/colors'

export const PackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  width: 100%;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px 0px 15px;
`

export const AltText = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  padding-right: 0;
  padding-top: 0.5rem;
`

export const UploaderHeader = styled.div`
  align-items: center;
  box-shadow: 0 0 0 1px ${white};
  display: flex;
  justify-content: center;
`

export const UploaderContainer = styled.div`
  align-items: center;
  border: 1px dashed ${grayscale[200]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  margin-top: 10px;
  min-height: 400px;
  width: 100%;

  button {
    margin-top: 2rem;
  }

  p {
    color: ${grayscale[400]};
    margin-top: 2rem;
  }

  svg {
    fill: ${grayscale[400]};
  }
`

export const UploadErrorMessage = styled.div`
  align-items: center;
  color: ${status.error};
  display: flex;
  justify-content: center;
  margin-top: 25px;
`

export const UploaderInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 9;
`

export const ImageUploaderContainer = styled.div`
  width: 100%;
`
