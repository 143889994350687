import React from 'react'

import Moderation from '../../../components/Moderation'
import { HOME_SOCIAL_URL } from '../../../config/urls'
import { BASE, HOME, HOME_SOCIAL } from '../../../config/routes'

export default function NewsModeration() {
  return (
    <Moderation
      pageTitle="Redes Sociais"
      mainUrl={HOME_SOCIAL_URL}
      mainRoute={HOME_SOCIAL}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Home', route: HOME },
        { title: 'Redes Sociais' },
      ]}
      renderVisualization={(social) => (
        <table style={{ width: '100%', textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{social.title}</td>
            </tr>
            <tr>
              <td>{social.description}</td>
            </tr>
          </tbody>
        </table>
      )}
    />
  )
}
