import React, { useEffect, useState } from 'react'

import HomeIcon from 'inter-frontend-svgs/lib/v2/action/house'
import BackIcon from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'
import CalendarIcon from 'inter-frontend-svgs/lib/v2/action/calendar-today'

import { request } from '../../helpers'
import { AUTHOR_URL } from '../../config/urls'
import iPhoneScreen from '../../assets/images/iphone-screen.png'
import * as S from './styles'

export default function LiveVisualization({ live }) {
  const [authors, setAuthors] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const data = await request({
        method: 'GET',
        url: `${AUTHOR_URL}`,
      })
      setAuthors(data)
    }
    fetchData()
  }, [])

  const { title, subtitle, author: authorUUID, tags, content, detailImage, report, url } = live
  const author = authors && authors.find((el) => el.uuid === authorUUID)

  return (
    authors && (
      <S.Container>
        <S.CellphoneContainer>
          <img src={iPhoneScreen} alt="Tela do iphone" />
          <S.LiveContainer>
            <S.LiveContent>
              <S.Live>
                <S.LiveHeader>
                  <button type="button">
                    <BackIcon height="20" width="20" color="orange--500" />
                  </button>
                  <span> {tags[0]} </span>
                  <button type="button">
                    <HomeIcon height="20" width="20" color="orange--500" />
                  </button>
                </S.LiveHeader>
                <S.BannerContainer>
                  <a target="_blank" href={url} rel="noreferrer">
                    <img src={detailImage.url} alt="banner" />
                  </a>
                </S.BannerContainer>
                <S.SectionTitle> {title} </S.SectionTitle>
                <S.LiveDetailsContainer>
                  <S.LiveDetails>
                    <S.AuthorContainer>
                      <S.AuthorPhoto>
                        <img alt="author" src={author && author.photo.url} />
                      </S.AuthorPhoto>
                      <S.AuthorInfo>
                        <S.AuthorName>{author && author.name}</S.AuthorName>
                        <span>{author && author.role}</span>
                      </S.AuthorInfo>
                    </S.AuthorContainer>
                    <S.Calendar>
                      <CalendarIcon height="20" width="20" color="gray--600" />
                      <span>2 horas atrás</span>
                    </S.Calendar>
                  </S.LiveDetails>
                </S.LiveDetailsContainer>
                <S.Subtitle>{subtitle}</S.Subtitle>
                <div
                  style={{ marginBottom: '40px', textAlign: 'justify', width: '100%' }}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                {Object.keys(report.file).length > 0 && (
                  <S.Report>
                    <a href={report.file.url} target="_blank" rel="noreferrer">
                      LER O RELATÓRIO
                    </a>
                  </S.Report>
                )}
              </S.Live>
            </S.LiveContent>
          </S.LiveContainer>
        </S.CellphoneContainer>
      </S.Container>
    )
  )
}
