import React from 'react'

import { Link } from 'react-router-dom'
import VideoCarouselIcon from 'inter-frontend-svgs/lib/v2/action/camera'
import TrailIcon from 'inter-frontend-svgs/lib/v2/action/widget'
import BannerIcon from 'inter-frontend-svgs/lib/v2/action/photo-library'

import { useAuth } from '../../hooks'
import Page from '../../components/Page'
import Card from '../../components/Card'
import {
  APRENDA_INVESTIR_VIDEO_CAROUSEL,
  APRENDA_INVESTIR_TRAIL,
  BASE,
  APRENDA_INVESTIR_BANNER,
} from '../../config/routes'

export default function Home() {
  const { auth } = useAuth()
  const permissions = {
    videoCarousel: auth.permissions['aprenda-investir']['video-carousel'].find,
    trail: auth.permissions['aprenda-investir'].trail.find,
    banner: auth.permissions['aprenda-investir'].banner.find,
  }

  return (
    <Page
      title="Invest CMS"
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Aprenda a Investir' }]}
      description="Selecione o serviço do Aprenda a Investir que deseja:"
    >
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="row">
            {permissions.banner && (
              <div className="col-sm-6 col-lg-3">
                <Link to={APRENDA_INVESTIR_BANNER}>
                  <Card title="Banner" icon={<BannerIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.videoCarousel && (
              <div className="col-sm-6 col-lg-3">
                <Link to={APRENDA_INVESTIR_VIDEO_CAROUSEL}>
                  <Card
                    title="Config. do Carrosel de Vídeos"
                    icon={<VideoCarouselIcon height="50" width="50" />}
                  />
                </Link>
              </div>
            )}
            {permissions.trail && (
              <div className="col-sm-6 col-lg-3">
                <Link to={APRENDA_INVESTIR_TRAIL}>
                  <Card title="Trilha" icon={<TrailIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}
