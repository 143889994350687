import React from 'react'

import { Link } from 'react-router-dom'
import AprendaInvestirIcon from 'inter-frontend-svgs/lib/v2/products/investimentos/poupança-money'
import AuthorIcon from 'inter-frontend-svgs/lib/v2/misc/time'
import CategoryIcon from 'inter-frontend-svgs/lib/v2/categories/livrarias'
import FilePackageIcon from 'inter-frontend-svgs/lib/v2/action/upload'
import HomeIcon from 'inter-frontend-svgs/lib/v2/action/house'
import NewsIcon from 'inter-frontend-svgs/lib/v2/products/investimentos/teste-perfil'
import QuoteIcon from 'inter-frontend-svgs/lib/v2/action/calendar'
import SubcategoryIcon from 'inter-frontend-svgs/lib/v2/categories/others'
import VideoIcon from 'inter-frontend-svgs/lib/v2/facilidades/play'
import QuotationIcon from 'inter-frontend-svgs/lib/orangeds/XL/home-broker'
import EventIcon from 'inter-frontend-svgs/lib/v2/action/notification-03'
import LiveIcon from 'inter-frontend-svgs/lib/v2/categories/entretenimento'

import { useAuth } from '../../hooks'
import Page from '../../components/Page'
import Card from '../../components/Card'
import {
  ALERT,
  APRENDA_INVESTIR,
  CATEGORY,
  AUTHOR,
  NEWS,
  HOME,
  FILE_PACKAGE,
  QUOTE,
  SUBCATEGORY,
  QUOTATION,
  EVENT,
  LIVE,
} from '../../config/routes'
import { gray } from '../../styles/colors'

export default function Home() {
  const { auth } = useAuth()
  const permissions = {
    alert: auth.permissions.alert.alert.find,
    author: auth.permissions.author.author.find,
    'aprenda-investir': auth.permissions['aprenda-investir']['aprenda-investir'].index,
    category: auth.permissions.category.category.find,
    event: auth.permissions.event.event.find,
    'file-package': auth.permissions['file-package']['file-package'].find,
    home: auth.permissions.home.home.index,
    live: auth.permissions.live.live.find,
    news: auth.permissions.news.news.find,
    quote: auth.permissions.quote.quote.find,
    quotation: auth.permissions.quotation.quotation.find,
    subcategory: auth.permissions.subcategory.subcategory.find,
  }

  return (
    <Page title="Invest CMS" description="Selecione o serviço que deseja:">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="row">
            {permissions['aprenda-investir'] && (
              <div className="col-sm-6 col-lg-3">
                <Link to={APRENDA_INVESTIR}>
                  <Card
                    title="Aprenda a Investir"
                    icon={<AprendaInvestirIcon height="50" width="50" />}
                  />
                </Link>
              </div>
            )}
            {permissions['file-package'] && (
              <div className="col-sm-6 col-lg-3">
                <Link to={FILE_PACKAGE}>
                  <Card title="Arquivos" icon={<FilePackageIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.author && (
              <div className="col-sm-6 col-lg-3">
                <Link to={AUTHOR}>
                  <Card title="Autores" icon={<AuthorIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.quote && (
              <div className="col-sm-6 col-lg-3">
                <Link to={QUOTE}>
                  <Card title="Bom dia, Inter" icon={<QuoteIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.quotation && (
              <div className="col-sm-6 col-lg-3">
                <Link to={QUOTATION}>
                  <Card iconOrangeDs title="Cotações" icon={<QuotationIcon color={gray[300]} />} />
                </Link>
              </div>
            )}
            {permissions.category && (
              <div className="col-sm-6 col-lg-3">
                <Link to={CATEGORY}>
                  <Card title="Categorias" icon={<CategoryIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.event && (
              <div className="col-sm-6 col-lg-3">
                <Link to={EVENT}>
                  <Card title="Evento" icon={<EventIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.home && (
              <div className="col-sm-6 col-lg-3">
                <Link to={HOME}>
                  <Card title="Home" icon={<HomeIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.live && (
              <div className="col-sm-6 col-lg-3">
                <Link to={LIVE}>
                  <Card title="Live" icon={<LiveIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.news && (
              <div className="col-sm-6 col-lg-3">
                <Link to={NEWS}>
                  <Card title="Notícias" icon={<NewsIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.subcategory && (
              <div className="col-sm-6 col-lg-3">
                <Link to={SUBCATEGORY}>
                  <Card title="Subcategorias" icon={<SubcategoryIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
            {permissions.alert && (
              <div className="col-sm-6 col-lg-3">
                <Link to={ALERT}>
                  <Card title="Vídeos" icon={<VideoIcon height="50" width="50" />} />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}
