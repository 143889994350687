export const ACTIONS = {
  INFO: {
    title: 'Informações',
  },
  EDIT: {
    title: 'Editar',
    pathPrefix: '',
  },
  DELETE: {
    title: 'Excluir',
    message: 'Confirmar exclusão deste item?',
    method: 'DELETE',
    pathPrefix: '',
  },
  REQUEST_APPROVAL: {
    title: 'Solicitar aprovação',
    message: 'Confirmar solicitação de moderação deste item?',
    method: 'POST',
    pathPrefix: '/request-approval',
  },
  CANCEL_APPROVAL_REQUEST: {
    title: 'Cancelar solicitação de aprovação',
    message: 'Confirmar cancelamento de moderação deste item?',
    method: 'POST',
    pathPrefix: '/cancel-approval-request',
  },
  MODERATE: {
    title: 'Moderar publicação',
    pathPrefix: '/moderacao',
  },
  UNPUBLISH: {
    title: 'Despublicar',
    message: 'Confirmar despublicação deste item?',
    method: 'POST',
    pathPrefix: '/unpublish',
  },
}

export const DEFAULT_ALLOWED_ACTIONS = [
  'INFO',
  'EDIT',
  'REQUEST_APPROVAL',
  'CANCEL_APPROVAL_REQUEST',
  'MODERATE',
]
export const DEFAULT_EDITION_FILTERS = { active: true, mainItem: true }
export const DEFAULT_MODERATION_FILTERS = {
  active: true,
  mainApprovalItem: true,
}
export const INITIAL_QUERY_STRING = { page: 1, tab: 'edition' }
export const ITEMS_PER_PAGE = 10
