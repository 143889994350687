import React from 'react'

import { Link } from 'react-router-dom'

import { Button } from '@inter/inter-ui'

import NotFoundImage from '../../assets/images/not-found.png'
import { BASE } from '../../config/routes'
import * as S from '../../components/ErrorModal/styles'

export default function NotFound() {
  return (
    <S.ErrorContainer>
      <S.ErrorImageContainer>
        <S.ErrorImage src={NotFoundImage} alt="Página não encontrada" />
      </S.ErrorImageContainer>
      <S.ErrorTitle>Ops... página não encontrada!</S.ErrorTitle>
      <S.ErrorDescription>Não foi possível concluir a solicitação.</S.ErrorDescription>
      <Link to={BASE}>
        <Button variant="primary">Voltar para home</Button>
      </Link>
    </S.ErrorContainer>
  )
}
