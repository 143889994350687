import React from 'react'

import Trash from 'inter-frontend-svgs/lib/layout/trash'

import { Button, Input } from '@inter/inter-ui'

import Modal from '../Modal'
import Gallery from './gallery'
import Uploader from './uploader'
import { FormInputLabel, FormInputInfoText } from '../Form/styles'
import * as S from './styles'

export default function ImageUploader({
  image,
  setImage,
  url,
  folder,
  label = 'Imagem',
  error,
  infoText,
  enableAlt = false,
  hasDescriptionForm = false,
  imageSize = null,
  maxWidth = 1200,
  size,
}) {
  const [modalState, setModalState] = React.useState('FROM_PC')
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(null)

  function changeModalState(newState) {
    if (modalState !== newState) {
      setModalState(newState)
    }
  }

  function setUploadedFile() {
    setModalState('FROM_GALLERY')
  }

  function renderModal() {
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        width="750px"
        header={
          <>
            <S.ModalButton
              onClick={() => changeModalState('FROM_PC')}
              className={modalState === 'FROM_PC' ? 'selected' : ''}
            >
              Upload de Imagem
            </S.ModalButton>
            <S.ModalButton
              onClick={() => changeModalState('FROM_GALLERY')}
              className={modalState === 'FROM_GALLERY' ? 'selected' : ''}
            >
              Imagens Disponíveis
            </S.ModalButton>
          </>
        }
        footer={
          <S.ModalFooter>
            <Button
              onClick={() => {
                setIsOpen(false)
                setImage(selectedImage)
              }}
              className={modalState === 'FROM_PC' ? 'uploader' : ''}
            >
              Concluir
            </Button>
          </S.ModalFooter>
        }
      >
        {modalState === 'FROM_GALLERY' ? (
          <Gallery url={url} onSelectImage={setSelectedImage} size={size} folder={folder} />
        ) : (
          <Uploader
            setUploadedFile={() => setUploadedFile()}
            folder={folder}
            maxWidth={maxWidth}
            imageSize={imageSize}
            hasDescriptionForm={hasDescriptionForm}
          />
        )}
      </Modal>
    )
  }

  return (
    <S.ImagePickerContainer>
      <FormInputLabel error={error}>{label}</FormInputLabel>
      <S.ImageContainer>
        <S.ImageUploader>
          {image && image.url ? (
            <>
              <img
                style={{ maxHeight: '500px', maxWidth: '800px' }}
                src={image.url}
                alt="Uma imagem"
              />
            </>
          ) : (
            <Button variant="primary" onClick={() => setIsOpen(true)}>
              Escolha ou faça o upload de uma imagem
            </Button>
          )}
        </S.ImageUploader>
        <S.RemoveImageButton
          type="button"
          disabled={!image || !image.url}
          onClick={() => setImage(null)}
        >
          <Trash height={20} color="gray" />
        </S.RemoveImageButton>
        {renderModal()}
        {enableAlt && (
          <S.AltText>
            <Input
              placeholder="Insira uma descrição para a imagem"
              style={{ width: '95%' }}
              value={image && image.alt}
              onChange={(e) => {
                setImage({ ...image, alt: e.target.value })
              }}
            />
          </S.AltText>
        )}
      </S.ImageContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.ImagePickerContainer>
  )
}
