import React from 'react'

import Moderation from '../../../components/Moderation'
import { HOME_CONFIG_URL } from '../../../config/urls'
import { BASE, HOME, HOME_CONFIG } from '../../../config/routes'

export default function HomeConfigModeration() {
  return (
    <Moderation
      pageTitle="Config."
      mainUrl={HOME_CONFIG_URL}
      mainRoute={HOME_CONFIG}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Home', route: HOME },
        { title: 'Config.' },
      ]}
      renderVisualization={(home) => (
        <table style={{ width: '100%', textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Notícias</th>
              <th>Categorias</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{home.type}</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>-</td>
              <td>{home.items && home.items.map((item) => <p>{item.title}</p>)}</td>
              <td>-</td>
            </tr>
            <tr>
              <td>-</td>
              <td>-</td>
              <td>{home.gridData && home.gridData.map((item) => <p>{item.name}</p>)}</td>
            </tr>
          </tbody>
        </table>
      )}
    />
  )
}
