import styled, { css } from 'styled-components'

import { grayscale, orange, white, status } from '../../styles/colors'

export const ImagePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 230px;
  padding: 10px 15px;
  width: 100%;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px 0px 15px;
`

export const ImageUploader = styled.div`
  align-items: center;
  border: 1px dashed ${grayscale[200]};
  display: flex;
  justify-content: center;
  min-height: 300px;
  width: 95%;
`

export const AltText = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  padding-right: 0;
  padding-top: 0.5rem;
`

export const RemoveImageButton = styled.button`
  align-items: center;
  background-color: ${white};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 5%;

  &:hover {
    svg {
      fill: ${orange[500]};
    }
  }
`

export const ModalButton = styled.button`
  background-color: ${white};
  border-bottom: 3px solid ${grayscale[200]};
  border-radius: 0px;
  color: ${grayscale[400]};
  height: 50px;
  text-transform: uppercase;
  width: 50%;

  &.selected {
    border-color: ${orange[300]};
    color: ${grayscale[800]};
  }
`

export const ModalFooter = styled.div`
  button {
    color: ${white};
    margin-top: 20px;

    &.uploader {
      display: none;
    }
  }
`

export const GalleryContainer = styled.div`
  align-items: center;
  color: ${grayscale[300]};
  display: flex;
  flex-wrap: wrap;
  height: 400px;
  justify-content: center;
  margin-top: 20px;
  overflow-y: auto;
  width: 100%;
`

export const Image = styled.button`
  background-color: ${white};
  background-position: center;
  background-size: cover;
  border: 3px solid white;
  cursor: pointer;
  margin: 3px;
  padding: 2px;
  outline: 1px solid ${grayscale[200]};

  &:hover {
    border-color: ${orange[300]};
  }

  &.selected {
    border-color: ${orange[300]};
  }

  ${({ size }) => {
    switch (size) {
      case 'LARGE':
        return css`
          width: 250px;
          height: 100px;
        `

      case 'SMALL':
        return css`
          width: 80px;
          height: 80px;
        `

      default:
        return css`
          width: 120px;
          height: 120px;
        `
    }
  }}
`

export const PreviewCanvas = styled.div`
  display: none;
`

export const CropContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
`

export const UploaderContainer = styled.div`
  align-items: center;
  border: 1px dashed ${grayscale[200]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  margin-top: 50px;
  min-height: 400px;
  width: 100%;

  button {
    margin-top: 2rem;
  }

  p {
    color: ${grayscale[400]};
    margin-top: 2rem;
  }

  svg {
    fill: ${grayscale[400]};
  }
`

export const CutButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`

export const UploadErrorMessage = styled.div`
  align-items: center;
  color: ${status.error};
  display: flex;
  justify-content: center;
  margin-top: 25px;
`

export const UploaderInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 9;
`
export const SearchContainer = styled.div`
  background-color: ${white};
  display: flex;
  flex-direction: row;
  padding: 10px 15px 0px 15px;
  width: 100%;

  button {
    margin-left: 10px;
    width: 20%;
  }
`
export const ImagesContainer = styled.div`
  margin: 25px;
`
