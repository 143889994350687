/* eslint-disable */
import React from 'react'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import Chevron from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'

import FileUploader from '../FileUploader'
import * as S from './styles'
import { FormInputLabel, FormInputInfoText } from '../Form/styles'

function MultipleFileUploader(
  { blocks = [], setBlocks, error, infoText, folder, type, enableCover },
  ref,
) {
  const [currentKey, setCurrentKey] = React.useState(0)

  React.useEffect(() => {
    setBlocks(blocks.map((block, index) => ({ ...block, key: `multiple-file-uploader-${index}` })))
    setCurrentKey(blocks.length)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const addBlock = React.useCallback(() => {
    setBlocks([
      ...blocks,
      {
        content: { description: '', file: {}, ...(enableCover && { image: {} }) },
        key: `multiple-file-uploader-${currentKey + 1}`,
      },
    ])
    setCurrentKey(currentKey + 1)
  }, [blocks, setBlocks])

  const removeBlock = React.useCallback(
    (index) => {
      const updatedBlocks = [...blocks]
      updatedBlocks.splice(index, 1)

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  const changePosition = React.useCallback(
    (indexFrom, indexTo) => {
      if (indexTo < 0 || indexTo > blocks.length - 1) return

      const updatedBlocks = [...blocks]
      const aux = updatedBlocks[indexFrom]

      updatedBlocks[indexFrom] = updatedBlocks[indexTo]
      updatedBlocks[indexTo] = aux

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  const changeContent = React.useCallback(
    (index, content) => {
      const updatedBlocks = [...blocks]
      updatedBlocks[index].content = content

      setBlocks(updatedBlocks)
    },
    [blocks, setBlocks],
  )

  function renderEditor(block, index) {
    const { content } = block
    return (
      <FileUploader
        content={content}
        changeContent={(newContent) => {
          changeContent(index, newContent)
        }}
        folder={folder}
        type={type}
        enableCover={enableCover}
      />
    )
  }

  function renderCard(block, index) {
    return (
      <S.CardContainer key={block.key}>
        {renderEditor(block, index)}
        <S.CardControls>
          <button type="button" className="btn" onClick={() => removeBlock(index)}>
            <Close height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index - 1)}>
            <Chevron height={20} color="grayscale--400" />
          </button>
          <button type="button" className="btn" onClick={() => changePosition(index, index + 1)}>
            <Chevron height={20} rotate={180} color="grayscale--400" />
          </button>
        </S.CardControls>
      </S.CardContainer>
    )
  }

  return (
    <S.EditorContainer ref={ref}>
      <FormInputLabel error={error}>Arquivos</FormInputLabel>
      <S.ContentContainer>
        <S.BlocksContainer>
          {blocks.length > 0 ? (
            blocks.map((block, index) => renderCard(block, index))
          ) : (
            <S.InfoText>
              <p> Por favor adicione novos blocos ... </p>
            </S.InfoText>
          )}
        </S.BlocksContainer>
        <S.ContentControls>
          <button type="button" onClick={() => addBlock()}>
            + Adicionar
          </button>
        </S.ContentControls>
      </S.ContentContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.EditorContainer>
  )
}

export default React.forwardRef(MultipleFileUploader)
