import styled from 'styled-components'

import { orange } from '../../styles/colors'

export const SelectContainer = styled.div`
  width: 100%;
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const FallbackIcon = styled.div`
  color: ${orange[500]};
  margin-right: 10px;
`
