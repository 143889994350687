/* eslint-disable */
import React from 'react'

import { useTable, useExpanded, usePagination } from 'react-table'
import Search from 'inter-frontend-svgs/lib/v2/navigation/search'
import ChevronUp from 'inter-frontend-svgs/lib/v2/navigation/chevron-up'

import { Input, Tab } from '@inter/inter-ui'

import * as S from './styles'

function getCellStyle(column) {
  return {
    maxWidth: column.maxWidth,
    minWidth: column.minWidth,
  }
}

export default function Table({
  columns,
  data,
  onRowClick,
  tabs,
  search,
  sort,
  paginator,
  hiddenColumns,
  extraControls,
  ...props
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      ...(hiddenColumns && { initialState: { hiddenColumns } }),
      ...(paginator && {
        useControlledState: (state) =>
          React.useMemo(
            () => ({
              ...state,
              pageIndex: paginator.currentPage,
            }),
            [state],
          ),
      }),
    },
    useExpanded,
    usePagination,
  )

  return (
    <S.Container {...props}>
      {tabs && (
        <S.Tabs>
          {tabs.map((tab) => (
            <Tab {...tab} key={tab.value} />
          ))}
        </S.Tabs>
      )}
      <S.Controls>
        {search && (
          <Input
            type="text"
            placeholder={`Pesquisar por ${search.field}`}
            value={search.value}
            iconRight={<Search height={20} width={20} />}
            style={{ minWidth: '30%' }}
            onChange={(evt) => search.onChange(evt.target.value)}
          />
        )}
        {extraControls}
      </S.Controls>
      <S.Table {...getTableProps()}>
        <S.Head>
          {headerGroups.map((headerGroup) => (
            <S.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const canSort = sort && !column.disableSortBy

                return (
                  <S.HeadCell
                    {...column.getHeaderProps({
                      style: getCellStyle(column),
                    })}
                    sortableColumn={canSort}
                    onClick={(evt) => {
                      evt.preventDefault()
                      canSort && sort.onChange(column.id, sort.direction === 'ASC' ? 'DESC' : 'ASC')
                    }}
                  >
                    {column.render('Header')}
                    {canSort && sort.field === column.id && (
                      <ChevronUp
                        height={15}
                        width={15}
                        rotate={sort.direction === 'ASC' ? 0 : 180}
                      />
                    )}
                  </S.HeadCell>
                )
              })}
            </S.HeadRow>
          ))}
        </S.Head>
        <S.Body {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <S.TableRow>
              <S.TableData colSpan={columns.length}>
                <S.EmptyDataInfo>Nenhum item encontrado</S.EmptyDataInfo>
              </S.TableData>
            </S.TableRow>
          ) : (
            rows.map((row) => {
              prepareRow(row)

              const isSubRow = row.depth > 0
              isSubRow && row.cells.shift()

              return (
                <React.Fragment key={row.getRowProps().key}>
                  <S.BodyRow
                    isSubRow={isSubRow}
                    onClick={(evt) => {
                      evt.preventDefault()
                      onRowClick(row)
                    }}
                  >
                    {row.cells.map((cell) => (
                      <S.BodyCell
                        {...cell.getCellProps({
                          style: getCellStyle(cell.column),
                        })}
                      >
                        <S.CellText onClick={(evt) => evt.stopPropagation()}>
                          {cell.render('Cell')}
                        </S.CellText>
                      </S.BodyCell>
                    ))}
                  </S.BodyRow>

                  {row.isExpanded && !isSubRow && row.subRows.length === 0 && (
                    <S.TableRow style={{ width: '100%' }}>
                      <S.TableData colSpan={columns.length} style={{ flexGrow: 1 }}>
                        <S.EmptyDataInfo>Não existem versões deste item</S.EmptyDataInfo>
                      </S.TableData>
                    </S.TableRow>
                  )}
                </React.Fragment>
              )
            })
          )}
        </S.Body>
      </S.Table>
      {paginator && rows.length > 0 && (
        <S.PaginationContainer>
          <S.PaginationButton
            onClick={() => paginator.setCurrentPage(1)}
            disabled={paginator.currentPage === 1}
          >
            «
          </S.PaginationButton>
          <S.PaginationButton
            onClick={() => paginator.setCurrentPage(paginator.currentPage - 1)}
            disabled={paginator.currentPage === 1}
          >
            ‹
          </S.PaginationButton>
          <S.PaginationButton>{paginator.currentPage}</S.PaginationButton>
          <S.PaginationButton
            onClick={() => paginator.setCurrentPage(paginator.currentPage + 1)}
            disabled={paginator.currentPage === paginator.totalPages}
          >
            ›
          </S.PaginationButton>
          <S.PaginationButton
            onClick={() => paginator.setCurrentPage(paginator.totalPages)}
            disabled={paginator.currentPage === paginator.totalPages}
          >
            »
          </S.PaginationButton>
        </S.PaginationContainer>
      )}
    </S.Container>
  )
}
