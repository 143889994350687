import React from 'react'

import HomeIcon from 'inter-frontend-svgs/lib/v2/action/house'
import BackIcon from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'

import iPhoneScreen from '../../assets/images/iphone-screen-zoom.png'
import * as S from './styles'

export default function EventVisualization({ event }) {
  const {
    title,
    description,
    expectedValue,
    lastValue,
    expectedValueVariation,
    lastValueVariation,
    date: stringDate,
    regionIcon,
  } = event
  const date = new Date(stringDate)
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  function getOperator(variation) {
    switch (variation) {
      case 'POSITIVA':
        return '+'
      case 'NEGATIVA':
        return '-'
      default:
        return ''
    }
  }

  return (
    <S.Container>
      <S.CellphoneContainer>
        <S.CellphoneScreen src={iPhoneScreen} />
        <S.EventContainer>
          <S.EventContent>
            <S.Event>
              <S.EventHeader>
                <button type="button">
                  <BackIcon height="20" width="20" color="orange--500" />
                </button>
                <span>Inter Research</span>
                <button type="button">
                  <HomeIcon height="20" width="20" color="orange--500" />
                </button>
              </S.EventHeader>
              <S.SectionTitle>Agenda do Mercado</S.SectionTitle>
              <S.EventDetails>
                <S.Calendar>
                  <div>
                    <S.Day>{date.getDate().toString().padStart(2, '0')}</S.Day>
                    <S.Month>{monthNames[date.getMonth()]}</S.Month>
                  </div>
                </S.Calendar>
                <S.EventInfo>
                  <S.EventTitle>
                    <span>{title}</span>
                    {Object.keys(regionIcon).length > 0 && (
                      <img src={regionIcon.url} alt={regionIcon.alt} />
                    )}
                  </S.EventTitle>
                  <div>
                    Horário:{' '}
                    {`${date.getHours().toString().padStart(2, '0')}:${date
                      .getMinutes()
                      .toString()
                      .padStart(2, '0')}h`}
                  </div>
                  <div>{description}</div>
                  <S.EventExpectation>
                    {expectedValue !== '' && (
                      <span>
                        Expectativa:
                        <S.Variation
                          scenario={expectedValueVariation.toLowerCase()}
                        >{`${getOperator(expectedValueVariation)}${expectedValue}`}</S.Variation>
                      </span>
                    )}
                    {lastValue !== '' && (
                      <span>
                        Última:
                        <S.Variation scenario={lastValueVariation.toLowerCase()}>{`${getOperator(
                          lastValueVariation,
                        )}${lastValue}`}</S.Variation>
                      </span>
                    )}
                  </S.EventExpectation>
                </S.EventInfo>
              </S.EventDetails>
            </S.Event>
          </S.EventContent>
        </S.EventContainer>
      </S.CellphoneContainer>
    </S.Container>
  )
}
