import styled from 'styled-components'

import { white, gray } from '../../styles/colors'

export const Container = styled.div`
  width: 100%;
`

export const EditorContainer = styled.div`
  background: ${white};
  border: 1px solid ${gray[100]};
  border-radius: 8px;
  font-family: Inter;
  font-size: 14px;
  padding: 16px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 1rem;
`

export const EditorControls = styled.div`
  align-items: center;
  border-bottom: 1px solid ${gray[100]};
  display: flex;
  padding-bottom: 16px;
`

export const EditorControlsSeparator = styled.div`
  border-left: 1px solid ${gray[100]};
  height: 30px;
  margin-right: 4px;
`

export const EditorTextArea = styled.div`
  cursor: text;
  padding-top: 16px;
  min-height: 150px;
`
