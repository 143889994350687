import React from 'react'

import DefaultForm from '../../../components/DefaultForm'
import {
  APRENDA_INVESTIR_TRAIL_CONFIG_URL,
  APRENDA_INVESTIR_TRAIL_URL,
  IMAGES_URL,
} from '../../../config/urls'
import { BASE, APRENDA_INVESTIR, APRENDA_INVESTIR_TRAIL } from '../../../config/routes'
import Visualization from './Visualization'

export default function TrailForm() {
  return (
    <DefaultForm
      pageTitle="Trilha"
      breadcrumbs={(path) => [
        { title: 'Invest CMS', route: BASE },
        { title: 'Aprenda a Investir', route: APRENDA_INVESTIR },
        { title: 'Trilha', route: APRENDA_INVESTIR_TRAIL },
        { title: path === 'novo' ? 'Novo' : 'Editar' },
      ]}
      configUrl={APRENDA_INVESTIR_TRAIL_CONFIG_URL}
      mainRoute={APRENDA_INVESTIR_TRAIL}
      buildForm={(data) => buildForm(data)}
      submitOptions={{ method: 'POST', url: APRENDA_INVESTIR_TRAIL_URL }}
      renderVisualization={(trail) => <Visualization trail={trail} />}
    />
  )
}

function validateNews(news) {
  let errorMessage = ''
  news.forEach((item) => {
    const { content } = item

    if (content.title.length === 0) {
      errorMessage = 'O título deve ser preenchido corretamente.'
    }

    if (content.url.length === 0) {
      errorMessage = 'Uma imagem deve ser informada.'
    }

    if (content.link.length === 0) {
      errorMessage = 'Um link deve ser informado.'
    }

    if (content.isVideo) {
      if (content.videoTitle.length === 0) {
        errorMessage = 'O título do vídeo deve ser informado.'
      }
      if (content.videoSlug.length === 0) {
        errorMessage = 'O slug do vídeo deve ser informado.'
      }
      if (content.videoDescription.length === 0) {
        errorMessage = 'A descrição do vídeo deve ser informada.'
      }
      if (content.videoCategory.length === 0) {
        errorMessage = 'A categoria do vídeo deve ser informada.'
      }
    }
  })

  return errorMessage
}

const icons = [
  {
    text: 'Ícone #1',
    value: 'market',
  },
  {
    text: 'Ícone #2',
    value: 'investments',
  },
  {
    text: 'Ícone #3',
    value: 'inter-research',
  },
  {
    text: 'Ícone #4',
    value: 'product-many',
  },
  {
    text: 'Ícone #5',
    value: 'settings',
  },
  {
    text: 'Ícone #6',
    value: 'aprenda-a-investir',
  },
]

function buildForm(fields) {
  return fields.map((field) => {
    switch (field.name) {
      case 'uuid':
        return {
          ...field,
          type: 'hidden',
        }

      case 'news':
        return {
          ...field,
          type: 'trail-news-editor',
          options: {
            url: `${IMAGES_URL}/image`,
            folder: 'aprenda-investir/trail/images',
            isTrail: true,
          },
          rules: {
            ...field.rules,
            validate: {
              validNews: (news) => {
                const message = validateNews(news)
                return message.length === 0 || message
              },
            },
          },
        }
      case 'icon':
        return {
          ...field,
          type: 'icon-selector',
          options: icons,
        }
      default:
        return {
          ...field,
          type: 'text',
        }
    }
  })
}
