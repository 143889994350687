import React from 'react'

import { useHistory } from 'react-router-dom'

import { Button, Switch } from '@inter/inter-ui'

import Page from '../Page'
import Form from '../Form'
import { request } from '../../helpers'
import { CONTENT_TYPES_URL } from '../../config/urls'
import { VisualizationButton } from './styles'

export const DEFAULT_ERROR_MESSAGE = 'Este campo é obrigatório'

export default function DefaultForm({
  pageTitle,
  breadcrumbs,
  configUrl,
  mainRoute,
  buildForm,
  submitOptions,
  renderVisualization,
}) {
  const [formFields, setFormFields] = React.useState([])
  const [isVisualizating, setIsVisualizating] = React.useState(false)
  const history = useHistory()
  const formRef = React.useRef(null)

  React.useEffect(() => {
    getFormFields(configUrl, history).then((data) => {
      setFormFields(buildForm(data))
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function onSubmit(options) {
    return request(options).then(() => history.push(mainRoute))
  }

  const id = history.location.pathname.split('/').pop()
  const isEdit = id !== 'novo'
  return (
    <Page
      title={
        <>
          {pageTitle}
          {renderVisualization && (
            <VisualizationButton>
              <Switch
                id="visualization"
                label="Visualizar"
                checked={isVisualizating}
                onChange={async (evt) => {
                  if (!evt.target.checked) setIsVisualizating(false)
                  else if (await formRef.current.runValidations()) setIsVisualizating(true)
                }}
              />
            </VisualizationButton>
          )}
        </>
      }
      breadcrumbs={breadcrumbs(id)}
      fixedHeader
    >
      <Form
        ref={formRef}
        formFields={formFields}
        onSubmit={(body) =>
          onSubmit({
            ...(typeof submitOptions === 'function' ? submitOptions(isEdit, id) : submitOptions),
            body,
          })
        }
        submitButtonText="Salvar"
        isVisualizating={isVisualizating}
        renderVisualization={renderVisualization}
        additionalButtons={[
          <Button
            type="submit"
            variant="secondary"
            style={{ minWidth: '150px', marginRight: '20px' }}
            onClick={() => history.push(mainRoute)}
          >
            Cancelar
          </Button>,
        ]}
      />
    </Page>
  )
}

function getFieldsInfo(uuid) {
  return request({ method: 'GET', url: CONTENT_TYPES_URL }).then(
    (response) => response.data.find((plugin) => plugin.uid === uuid).attributes,
  )
}

async function getFormFields(configUrl, history) {
  const splittedConfigUrl = configUrl.split('/')
  const requiredInfo = await getFieldsInfo(splittedConfigUrl[splittedConfigUrl.length - 2])

  return request({ method: 'GET', url: configUrl }).then((response) => {
    const { layouts, metadatas } = response.data.contentType

    return layouts.edit.reduce(
      (accumulator, current) => [
        ...accumulator,
        current.reduce((acc, cur) => {
          const { label, placeholder, description } = metadatas[cur.name].edit

          return {
            ...acc,
            type: 'text',
            name: cur.name,
            label,
            placeholder,
            defaultValue: history.location.state[cur.name],
            infoText: description || undefined,
            rules: { required: requiredInfo[cur.name].required ? DEFAULT_ERROR_MESSAGE : false },
          }
        }, {}),
      ],
      [],
    )
  })
}
