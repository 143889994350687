import React from 'react'

import { Input } from '@inter/inter-ui'

import * as S from '../styles'
import { FormInputLabel } from '../../../../components/Form/styles'

export default function AnchorEditor({ content, changeContent }) {
  const { title, url } = content
  return (
    <S.BlockContainer>
      <S.BlockTitle>Anchor</S.BlockTitle>
      <S.InputContainer>
        <FormInputLabel>Título:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe o título (opcional)"
          value={title}
          onChange={(e) => {
            changeContent({ ...content, title: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.InputContainer>
        <FormInputLabel>URL:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe a URL"
          value={url}
          onChange={(e) => {
            changeContent({
              ...content,
              url: e.target.value,
            })
          }}
          style={{
            width: '100%',
          }}
        />
        <S.BlockInfo>
          Ex:
          &quot;https://anchor.fm/interinvest/embed/episodes/42-Comentrio-de-Mercado-21122020-eo2tej/a-a46bq81&quot;
        </S.BlockInfo>
      </S.InputContainer>
    </S.BlockContainer>
  )
}
