import styled from 'styled-components'

export const VisualizationContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

export const SubcategoryLabel = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 36px;
  width: 100%;
`

export const SubcategoryTitle = styled.div`
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  width: 100%;
`
