import React from 'react'

import { Editor, EditorState, RichUtils, convertToRaw, CompositeDecorator } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { convertFromHTML } from 'draft-convert'

import { FormInputLabel } from '../Form/styles'
import { BlockTypeControl, InlineStyleControl, LinkControl } from './controls'
import { getLinkDecorator } from './decorators/Link'
import * as S from './styles'

const compositeDecorator = new CompositeDecorator([getLinkDecorator()])
const customStyleMap = {
  MARK: {
    backgroundColor: 'yellow',
  },
}
const convertHtmlRules = {
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (nodeName === 'mark') {
      return currentStyle.add('MARK')
    }
    if (nodeName === 'ins') {
      return currentStyle.add('UNDERLINE')
    }
    return currentStyle
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', { url: node.href, target: node.target })
    }
    return false
  },
}
export default function RichTextEditor({ content, onChange, label, error }) {
  const [editorState, setEditorState] = React.useState(() => {
    if (content) {
      const contentState = convertFromHTML(convertHtmlRules)(content)
      return EditorState.createWithContent(contentState, compositeDecorator)
    }

    return EditorState.createEmpty(compositeDecorator)
  })

  const editorRef = React.useRef(null)

  const focusEditor = React.useCallback(() => {
    editorRef.current && editorRef.current.focus()
  }, [editorRef])

  const handleChange = React.useCallback(
    (newEditorState) => {
      const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()))

      onChange(html)
      setEditorState(newEditorState, { decorator: compositeDecorator })
    },
    [onChange],
  )

  const handleKeyCommand = React.useCallback(
    (command) => {
      const updatedState = RichUtils.handleKeyCommand(editorState, command)

      if (updatedState) {
        handleChange(updatedState)
        return 'handled'
      }

      return 'not-handled'
    },
    [editorState, handleChange],
  )

  return (
    <S.Container>
      <FormInputLabel error={error}>{label}</FormInputLabel>
      <S.EditorContainer>
        <S.EditorControls>
          <BlockTypeControl editorState={editorState} handleChange={handleChange} />
          <S.EditorControlsSeparator />
          <InlineStyleControl editorState={editorState} handleChange={handleChange} />
          <LinkControl editorState={editorState} handleChange={handleChange} />
        </S.EditorControls>
        <S.EditorTextArea onClick={focusEditor}>
          <Editor
            ref={editorRef}
            editorState={editorState}
            onChange={handleChange}
            handleKeyCommand={handleKeyCommand}
            customStyleMap={customStyleMap}
            spellCheck
          />
        </S.EditorTextArea>
      </S.EditorContainer>
    </S.Container>
  )
}
