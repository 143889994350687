import styled from 'styled-components'

import { grayscale } from '../../../styles/colors'

export const VisualizationContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding-left: 20px;
`

export const TitleContainer = styled.div`
  align-items: center;
  display: inline-flex;

  svg {
    margin-bottom: 15px;
  }
`

export const Title = styled.p`
  color: ${grayscale[500]};
  font-family: Sora;
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
`

export const Subtitle = styled.p`
  color: ${grayscale[500]};
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 0.1;
`

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 20px;
`

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const PostTitle = styled.div`
  color: ${grayscale[500]};
  font-family: Sora;
  font-size: 14px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 10px;
  border-radius: 2px;
  border-left: 5px solid #ff7a00;
`

export const Post = styled.div`
  border: 1px solid #b6b7bb;
  border-radius: 10px;
  min-height: 240px;
  min-width: 240px;
  margin: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  svg {
    margin-left: 90%;
  }
`

export const PodcastPost = styled.div`
  border: 1px solid #b6b7bb;
  border-radius: 10px;
  min-height: 200px;
  min-width: 200px;
  margin: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  svg {
    margin-top: 50%;
    margin-left: 45%;
  }
`
