import React from 'react'

import { Overlay } from '../Modal/styles'
import { Spinner } from './styles'

export default function LoadingScreen() {
  return (
    <Overlay>
      <Spinner />
    </Overlay>
  )
}
