import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { LIVE_URL } from '../../config/urls'
import { BASE, LIVE } from '../../config/routes'

export default function LiveModeration() {
  return (
    <Moderation
      pageTitle="Live"
      mainUrl={LIVE_URL}
      mainRoute={LIVE}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[{ title: 'Invest CMS', route: BASE }, { title: 'Live' }]}
      renderVisualization={(live) => <Visualization live={live} />}
    />
  )
}
