import React from 'react'

import Moderation from '../../components/Moderation'
import Visualization from './Visualization'
import { QUOTE_URL } from '../../config/urls'
import { BASE, QUOTE } from '../../config/routes'

export default function QuoteModeration() {
  return (
    <Moderation
      pageTitle="Quotes"
      mainUrl={QUOTE_URL}
      mainRoute={QUOTE}
      moderationApproveBody={{ status: 'PUBLISHED' }}
      moderationReproveBody={{ status: 'REPROVED' }}
      breadcrumbs={[
        { title: 'Invest CMS', route: BASE },
        { title: 'Quotes', route: QUOTE },
        { title: 'Moderação' },
      ]}
      renderVisualization={(quote) => <Visualization quote={quote} />}
    />
  )
}
