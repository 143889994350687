import styled from 'styled-components'

import { grayscale, white, orange } from '../../styles/colors'

export const SelectWithInputContainer = styled.div`
  width: 100%;
`

export const InputContainer = styled.div`
  margin-bottom: 30px;
`

export const OptionsContainer = styled.div`
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  max-height: 150px;
  overflow-y: auto;
`

export const InputBox = styled.div`
  background-color: ${white};
  border: 1px solid ${grayscale[200]};
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  height: 55px;
  margin-top: 5px;
  overflow: hidden;
`

export const Input = styled.input`
  border: none;
  cursor: text;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 18px;
  line-height: 1;
  outline: none;
`

export const SelectedOptions = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px;
  padding: 0 10px;
`

export const SelectedOption = styled.div`
  background: ${grayscale[200]};
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  cursor: pointer;
  margin-right: 5px;
  padding: 2px 6px;

  span {
    background: ${grayscale[200]};
    display: none;
    color: ${orange[400]};
  }

  &:hover {
    span {
      display: inline;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`

export const Option = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 8px 20px;

  &:hover {
    background-color: ${grayscale[200]};
  }
`

export const EmptyResult = styled.span`
  display: block;
  font-size: 14px;
  margin: 0.25rem 0.5rem 0.25rem 0.5rem;
`
