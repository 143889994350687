import styled, { css } from 'styled-components'

import { white } from '../../../styles/colors'

export const ControlImage = styled.img`
  ${({ size }) =>
    size
      ? css`
          height: ${size}px;
          width: ${size}px;
        `
      : css`
          height: 20px;
          width: 20px;
        `}
`

export const LinkModalContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const LinkModal = styled.div`
  background: ${white};
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: absolute;
  width: 240px;
`

export const LinkTargetOption = styled.div`
  margin-top: -8px;
`

export const LinkModalButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`
