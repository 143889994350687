import React from 'react'

import ReadingTimeIcon from 'inter-frontend-svgs/lib/orangeds/MD/pending'
import PublishDateIcon from 'inter-frontend-svgs/lib/orangeds/MD/agenda'

import NewsImage from '../../assets/images/news.png'
import * as S from './styles'

export default function AuthorVisualization({ author }) {
  const { name, about, photo, investorCertificate } = author

  return (
    <S.Container>
      <S.AuthorContainer>
        <S.ProfilePicture src={photo.url} alt="Foto de perfil" />
        <div>
          <S.About>{about}</S.About>
          <S.AuthorName>
            {name}, {investorCertificate}
          </S.AuthorName>
        </div>
      </S.AuthorContainer>
      <S.Section />
      <S.News>
        {renderNews(
          '20/abr',
          '3',
          'In hendrerit dolor euismod semper elementum. Nulla bibendum rhoncus nibh, u laoreet lorem pharetra sed.',
        )}
        {renderNews(
          '19/abr',
          '5',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce id ex ac diam hendrerit aliquam.',
        )}
      </S.News>
    </S.Container>
  )
}

function renderNews(date, readingTime, title) {
  return (
    <S.NewsContainer>
      <S.NewsImage src={NewsImage} alt="Imagem da Notícia" />
      <div>
        <S.NewsInfo>
          <PublishDateIcon color="#6A6C72" height={14} width={14} />{' '}
          <S.NewsStatistic>Publicado {date} </S.NewsStatistic>{' '}
          <ReadingTimeIcon color="#6A6C72" height={14} width={14} />{' '}
          <S.NewsStatistic>{readingTime} min de leitura</S.NewsStatistic>{' '}
        </S.NewsInfo>
        <S.NewsTitle>{title}</S.NewsTitle>
      </div>
    </S.NewsContainer>
  )
}
