import React from 'react'

import { Checkbox, Input } from '@inter/inter-ui'

import ImageUploader from '../../../../components/ImageUploader'
import { IMAGES_URL } from '../../../../config/urls'
import { FormInputLabel } from '../../../../components/Form/styles'
import * as S from '../styles'

export default function ImageEditor({
  blockKey,
  content,
  changeContent,
  folder,
  hasDescriptionForm = false,
}) {
  const { url, alt, caption, maxWidth, zoom_enabled } = content

  return (
    <S.BlockContainer>
      <S.BlockTitle>Imagem</S.BlockTitle>
      <S.InputContainer>
        <ImageUploader
          image={{ url, alt }}
          setImage={(img) => {
            changeContent({ ...content, url: img && img.url, alt: img && img.alt })
          }}
          url={`${IMAGES_URL}/image`}
          folder={folder || 'news/images'}
          label=""
          enableAlt
          hasDescriptionForm={hasDescriptionForm}
        />
      </S.InputContainer>
      <S.InputContainer>
        <FormInputLabel>Legenda:</FormInputLabel>
        <Input
          type="text"
          placeholder="Informe uma legenda para a imagem (opcional)"
          value={caption}
          onChange={(e) => {
            changeContent({ ...content, caption: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.InputContainer>
        <FormInputLabel>Largura máxima (em px ou %):</FormInputLabel>
        <Input
          type="text"
          placeholder="Ex: 100% ou 400px (opcional)"
          value={maxWidth}
          onChange={(e) => {
            changeContent({ ...content, maxWidth: e.target.value })
          }}
          style={{
            width: '100%',
          }}
        />
      </S.InputContainer>
      <S.InputContainer>
        <Checkbox
          key={`zoom-enabled-${blockKey}`}
          defaultChecked={zoom_enabled}
          label="Habilitar o zoom na imagem"
          onChange={(e) => {
            changeContent({ ...content, zoom_enabled: e.target.checked })
          }}
        />
      </S.InputContainer>
    </S.BlockContainer>
  )
}
