import React from 'react'

import { createPortal } from 'react-dom'

import * as S from './styles'

let modalArea

const Modal = ({ children, isOpen, onClose, header, footer, width }) => {
  const [isDomReady, setIsDomReady] = React.useState(false)

  React.useEffect(() => {
    setIsDomReady(true)
  }, [])

  modalArea = modalArea || document.getElementById('modal-area')

  if (isOpen && isDomReady) {
    return createPortal(
      <S.Overlay role="button" onClick={onClose}>
        <S.Modal role="dialog" width={width} onClick={(event) => event.stopPropagation()}>
          <S.Close onClick={onClose} />
          {header && (
            <S.Header>
              {typeof header === 'string' ? <S.HeaderTitle>{header}</S.HeaderTitle> : header}
            </S.Header>
          )}
          {children}
          {footer && <S.Footer>{footer}</S.Footer>}
        </S.Modal>
      </S.Overlay>,
      modalArea,
    )
  }

  return null
}

export default Modal
