import React from 'react'

import { EditorOption } from '../components'
import { toggleInlineStyle } from './handlers'
import { ControlImage } from './styles'
import highlight from '../../../assets/images/highlight.svg'

const INLINE_STYLES = [
  {
    title: 'Destaque',
    style: 'MARK',
    children: <ControlImage src={highlight} size={18} />,
  },
  {
    title: 'Negrito',
    style: 'BOLD',
    children: <b>N</b>,
  },
  {
    title: 'Itálico',
    style: 'ITALIC',
    children: <em>I</em>,
  },
  {
    title: 'Sublinhado',
    style: 'UNDERLINE',
    children: <u>S</u>,
  },
]

export default function InlineStyle({ editorState, handleChange }) {
  const currentStyle = editorState.getCurrentInlineStyle()

  return INLINE_STYLES.map(({ title, style, children }, key) => {
    const editorKey = `${key}-${style}`
    return (
      <EditorOption
        key={editorKey}
        title={title}
        active={currentStyle.has(style)}
        onToggle={() => toggleInlineStyle(editorState, handleChange, style)}
      >
        {children}
      </EditorOption>
    )
  })
}
