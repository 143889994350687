import styled from 'styled-components'

import { grayscale, green, orange, white } from '../../styles/colors'

export const VisualizationContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`

export const QuotationContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  color: ${grayscale[500]};
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 10px;
  }
`
export const QuotationImageContainer = styled.div`
  width: 42px;
  height: 42px;
  margin: 16px 8px 16px 16px;
`
export const CardIdentify = styled.div`
  display: flex;
  width: 30%;
  align-items: center;
`
export const CardRecommendation = styled.div`
  width: 20%;
  p {
    color: ${green};
    font-size: 14px;
    font-weight: 700;
  }
`
export const CardUpside = styled.div`
  width: 20%;
  p {
    color: ${green};
    font-size: 14px;
    font-weight: 700;
  }
`
export const CardVariation = styled.div`
  width: 10%;
  p {
    color: ${green};
    font-size: 14px;
    font-weight: 700;
  }
`
export const CardPrice = styled.div`
  width: 10%;
  p {
    color: ${grayscale[500]};
    font-size: 14px;
    font-weight: 700;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  color: ${grayscale[500]};
  font-weight: 700;
  button {
    margin: 0 4px;
  }
  button:nth-child(1) {
    background: ${orange[500]};
    border-radius: 8px;
    padding: 8px 16px;
    color: ${white};
    font-weight: 700;
    font-size: 12px;
  }
  button:nth-child(2) {
    background: ${white};
    border: 1px solid ${orange[400]};
    border-radius: 8px;
    padding: 8px 16px;
    color: ${orange[500]};
    font-weight: 700;
    font-size: 12px;
  }
`
