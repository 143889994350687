import React from 'react'

import { Checkbox } from '@inter/inter-ui'

import { FormInputLabel, FormInputInfoText } from '../Form/styles'
import * as S from './styles'

export default function MultipleSelect({ options, label, error, infoText, items, setItems }) {
  const [checkedItems, setCheckedItems] = React.useState(
    items
      ? options.map((item) => ({
          ...item,
          checked: items.find((it) => it.value === item.value) !== undefined,
        }))
      : [],
  )

  function renderOptions() {
    return checkedItems.map((item, index) => {
      const { checked, ...currentItem } = item
      const key = `key-${index}`
      return (
        <S.Item>
          <Checkbox
            key={key}
            defaultChecked={checked}
            label={currentItem.label}
            onChange={(evt) => {
              const cloneItems = [...items]

              if (evt.target.checked) {
                cloneItems.push(currentItem)
              } else {
                cloneItems.splice(
                  cloneItems.findIndex((cloneItem) => cloneItem.value === currentItem.value),
                  1,
                )
              }

              const cloneCheckedItems = [...checkedItems]
              cloneCheckedItems[index].checked = evt.target.checked

              setCheckedItems(cloneCheckedItems)
              setItems(cloneItems)
            }}
          />
        </S.Item>
      )
    })
  }

  return (
    checkedItems && (
      <S.MultipleSelectContainer>
        <FormInputLabel error={error}>{label}</FormInputLabel>
        <S.ItemsContainer>{renderOptions()}</S.ItemsContainer>
        <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
      </S.MultipleSelectContainer>
    )
  )
}
