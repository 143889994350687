import React from 'react'

import { Button, Input } from '@inter/inter-ui'

import Modal from '../Modal'
import Uploader from './uploader'
import CsvIcon from '../../assets/images/csv.svg'
import EmptyFileIcon from '../../assets/images/file.svg'
import PdfIcon from '../../assets/images/pdf.svg'
import UploadIcon from '../../assets/images/upload.svg'
import ImageUploader from '../ImageUploader'
import { IMAGES_URL } from '../../config/urls'
import { FormInputLabel, FormInputInfoText } from '../Form/styles'
import * as S from './styles'

export default function FileUploader({
  content,
  changeContent,
  folder,
  label = 'Arquivo',
  error,
  infoText,
  type,
  enableCover = false,
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [uploadedFile, setUploadedFile] = React.useState({})

  function renderModal() {
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        width="750px"
        header={<S.UploaderHeader>Upload de arquivo</S.UploaderHeader>}
      >
        <Uploader
          onUploadFile={(file) => {
            setUploadedFile(file)
            setIsOpen(false)
            changeContent({ ...content, file })
          }}
          folder={folder}
          type={type}
        />
      </Modal>
    )
  }

  function getIcon(selectedFile) {
    const fileExtension = selectedFile && selectedFile.name.split('.').pop()

    switch (fileExtension) {
      case 'csv':
        return CsvIcon

      case 'pdf':
        return PdfIcon

      default:
        return EmptyFileIcon
    }
  }

  function renderButton(selectedFile, baseFile = {}) {
    if (Object.keys(selectedFile).length !== 0) {
      return <img alt="" style={{ height: '20x' }} src={getIcon(selectedFile)} />
    }

    if (Object.keys(baseFile).length !== 0) {
      return <img alt="" style={{ height: '20px' }} src={getIcon(baseFile)} />
    }

    return <img alt="" style={{ height: '20px', marginLeft: '0px' }} src={UploadIcon} />
  }

  return (
    <S.PackageContainer>
      <S.InfoContainer>
        <FormInputLabel error={error}>{label}</FormInputLabel>
        <S.AltText>
          <Input
            placeholder="Insira o título do arquivo"
            style={{ width: '95%' }}
            value={content && content.description}
            onChange={(e) => {
              changeContent({ ...content, description: e.target.value })
            }}
          />
          <Button
            variant="link"
            icon={renderButton(uploadedFile, content.file)}
            title="Selecione um arquivo"
            style={{ marginLeft: '10px' }}
            onClick={() => setIsOpen(true)}
          >
            {uploadedFile.name || (content.file && content.file.name)}
          </Button>
        </S.AltText>
        {renderModal()}
        {enableCover && (
          <S.ImageUploaderContainer>
            <FormInputLabel error={error}>Capa</FormInputLabel>
            <ImageUploader
              image={content && content.image}
              setImage={(img) => {
                changeContent({ ...content, image: { url: img && img.url } })
              }}
              url={`${IMAGES_URL}/image`}
              folder="file-package/images"
              label=""
            />
          </S.ImageUploaderContainer>
        )}
      </S.InfoContainer>
      <FormInputInfoText error={error}>{infoText}</FormInputInfoText>
    </S.PackageContainer>
  )
}
