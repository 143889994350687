import { ITEMS_PER_PAGE } from './constants'

function formatQueryParam(filter, editionFilters, moderationFilters) {
  const [key, value] = filter

  switch (key) {
    case 'page':
      return `_limit=${ITEMS_PER_PAGE}&_start=${ITEMS_PER_PAGE * (value - 1)}`

    case 'search': {
      if (!value) return ''

      const [fieldName, fieldValue] = value.split(':')
      return `${fieldName}_contains=${fieldValue}`
    }

    case 'sort':
      return `_sort=${value}`

    case 'tab':
      return value === 'moderation'
        ? formatCustomTabFilters(moderationFilters)
        : formatCustomTabFilters(editionFilters)

    default:
      return `${key}=${value}`
  }
}

export function formatQueryParams(filters, editionFilters, moderationFilters) {
  return `?${Object.entries(filters)
    .map((filter) => formatQueryParam(filter, editionFilters, moderationFilters))
    .join('&')}`
}

function formatCustomTabFilters(filters) {
  return `${Object.entries(filters)
    .map((filter) => {
      const [key, value] = filter
      return `${key}=${value}`
    })
    .join('&')}`
}

export function formatChildrenFilters(values, childrenFilters) {
  return Object.entries(childrenFilters).reduce((acc, cur) => {
    if (cur[0] === 'groupBy') {
      return {
        ...acc,
        ...cur[1].reduce(
          (accumulator, current) => ({
            ...accumulator,
            [current]: values[current],
          }),
          {},
        ),
      }
    }

    return { ...acc, [cur[0]]: cur[1] }
  }, {})
}

export function formatDate(date) {
  const d = new Date(date).toLocaleString()
  return `${d.substring(0, 2)}/${d.substring(3, 5)}/${d.substring(6, 10)} - ${d.substring(12, 17)}`
}
