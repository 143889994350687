import React from 'react'

import { useLocation } from 'react-router-dom'
import InterLogo from 'inter-frontend-svgs/lib/logos/banco-mono'

import { Button } from '@inter/inter-ui'

import { useAuth } from '../../hooks'
import { AZURE_URL } from '../../config/urls'
import * as S from './styles'

export default function LoginSaml() {
  const { auth, signInWithSaml } = useAuth()
  const { pathname, search } = useLocation()

  React.useEffect(() => {
    signInWithSaml?.(`${pathname}${search}`)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <S.LoginBackground />
      <S.LoginArea>
        <InterLogo height={50} color="orange--500" />
        <S.Title>Invest CMS</S.Title>
        {auth?.jwt ? (
          <>
            <S.Description>Efetuando login por usuário de rede</S.Description>
            <S.Loader />
          </>
        ) : (
          <>
            <S.Link href={AZURE_URL}>
              <Button variant="secondary" style={{ width: '100%', margin: '8px 0 0 0' }}>
                Azure Login
              </Button>
            </S.Link>
          </>
        )}
      </S.LoginArea>
    </>
  )
}
